import { OtherAction } from './global';
import Suggester from 'types/Suggester';

enum TypeKeys {
  FETCHED = 'publisher/suggester/FETCHED',
}

interface FetchedAction {
  type: TypeKeys.FETCHED;
  payload: Suggester;
}

type ActionTypes = FetchedAction | OtherAction;

// Reducer
export type State = Suggester | null;

export default (state: State = null, action: ActionTypes) => {
  if (action.type !== TypeKeys.FETCHED) return state;
  return action.payload ? action.payload : null;
};

// Action Creators
export const fetchedSuggester = (suggester: Suggester) => ({
  type: TypeKeys.FETCHED,
  payload: suggester,
});

// Selectors
export const getAvailableLocales = (state: { suggester: State }) => {
  if (!state.suggester || !state.suggester.config) return [];
  return state.suggester.config.locales || [];
};
