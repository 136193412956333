import PartialPublisherRequest from 'types/PartialPublisherRequest';
import React, { useRef } from 'react';
import getMediaDimensionsForContainer from 'helpers/getMediaDimensionsForContainer';
import { PublisherRequestInstructionsBanner, BlurUp } from 'components/shared';
import { SuggestionsBrowserSwiperTextOnly } from '.';
import useSize from '@react-hook/size';
import { useLongPress } from 'hooks';

interface Props {
  publisherRequest: PartialPublisherRequest;
  onLongPress: () => void;
  preventLoading: boolean;
}

export default function SuggestionsBrowserSwiperSlide(props: Props) {
  const { publisherRequest, preventLoading } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSize = useSize(containerRef);
  const onLongPress = useLongPress(props.onLongPress);

  let attachments = publisherRequest.attachments;
  if (publisherRequest.actionableType === 'ContentRequest') {
    const attachmentIds = publisherRequest.contentRequestSubmissions.map(
      (s) => s.attachmentId
    );
    attachments = attachments.filter((a) => attachmentIds.includes(a.id));
  }
  const attachment = attachments[0];
  const isVideo = attachment && attachment.mediaContentType.match(/video/);

  const containerPadding = 16;

  const mediaDimensions = attachment
    ? getMediaDimensionsForContainer(attachment, {
        width: containerSize[0] - containerPadding * 2,
        height: containerSize[1] - containerPadding * 2,
      })
    : { width: 0, height: 0 };

  return (
    <div key={publisherRequest.id} className="swiper-slide h-full">
      <div className="relative w-full h-full flex flex-col items-start justify-start tablet:py-3 tablet:px-10.5 overflow-hidden">
        {publisherRequest && (
          <div className="relative w-full max-w-80 mx-auto px-2">
            <PublisherRequestInstructionsBanner
              theme="grey"
              publisherRequest={publisherRequest}
              logoUrl={publisherRequest.suggester.logoUrl}
              className="mb-1"
            />
            <div className="absolute left-0 top-0 right-0 px-2 z-50">
              <PublisherRequestInstructionsBanner
                theme="grey"
                publisherRequest={publisherRequest}
                logoUrl={publisherRequest.suggester.logoUrl}
              />
            </div>
          </div>
        )}
        <div
          ref={containerRef}
          className="flex flex-1 overflow-hidden items-center justify-center w-full p-2"
        >
          {publisherRequest.actionableType !== 'Suggestion' || !attachment ? (
            <SuggestionsBrowserSwiperTextOnly
              publisherRequest={publisherRequest}
            />
          ) : isVideo ? (
            <video
              controls
              style={{
                width: `${mediaDimensions.width}px`,
                height: `${mediaDimensions.height}px`,
              }}
              poster={attachment.thumbnails.large}
              className="rounded-lg mx-2"
              {...onLongPress}
            >
              <source
                src={attachment.mediaUrl}
                type={attachment.mediaContentType.replace('quicktime', 'mp4')}
              />
            </video>
          ) : (
            <>
              <BlurUp
                srcSet={attachment.thumbnails.large}
                preview={attachment.thumbnails.small}
                style={{
                  width: `${mediaDimensions.width}px`,
                  height: `${mediaDimensions.height}px`,
                }}
                preventLoading={preventLoading}
                imgClassName="rounded-lg"
                className="mx-2"
              />

              <div
                className="absolute top-0 left-0 w-full h-full z-10"
                {...onLongPress}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
