const subdomain = window.location.hostname.split('.')[0];
export const isPWADomain =
  subdomain === 'publish' ||
  /deploy-preview.+socialie-publisher-edge/.test(subdomain);

const mqStandAlone = '(display-mode: standalone)';
export const isPWABrowser =
  navigator['standalone'] ||
  ('matchMedia' in window && window.matchMedia(mqStandAlone).matches);

export const isAndroid = /(android)/i.test(navigator.userAgent);

export const isAndroidApp = isAndroid && isPWABrowser;
