import * as Sentry from '@sentry/react';
import { get, post } from './shared';
import LoggedInPublisher from 'types/LoggedInPublisher';
import UserApiPayload from 'types/UserApiPayload';
import Suggester from '../types/Suggester';
import User from '../types/User';

export const fetchCurrentPublisher = async (publisherCode?: string) => {
  const incomingParams = new URL(window.location.href).searchParams;
  const authToken = incomingParams.get('auth_token');
  const outgoingParams = new URLSearchParams();
  if (publisherCode) outgoingParams.append('code', publisherCode);
  if (authToken) outgoingParams.append('auth_token', authToken);
  const url = `publisher/current?${outgoingParams.toString()}`;
  return get(url);
};

export const updateCurrentPublisher = async (
  id: number,
  publisher: Partial<LoggedInPublisher>,
  extraParams?: { [key: string]: any }
) => {
  const endpoint = `publisher/publishers/${id}`;
  return post(endpoint, { publisher, ...extraParams }, 'PUT');
};

export const createPublisher = async (name: string, inviteCode?: string) => {
  return post<UserApiPayload>('publisher/publishers', { name, inviteCode });
};

export const clearCurrentPublisher = async () =>
  post<UserApiPayload>('publisher/current/clear');

export const identifySentryUser = (
  user: User,
  publisher: LoggedInPublisher,
  suggester: Suggester
) => {
  if (import.meta.env.REACT_APP_ENABLE_SENTRY !== 'true' || !user) return;

  Sentry.setUser({
    id: user.id.toString(),
    email: user.email ?? '',
    phone: user.phone ?? '',
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
  });

  if (publisher?.id) Sentry.setTag('publisher.id', publisher?.id);
  if (publisher?.name) Sentry.setTag('publisher.name', publisher?.name);

  if (suggester?.id) Sentry.setTag('suggester.id', suggester?.id);
  if (suggester?.accountName)
    Sentry.setTag('suggester.name', suggester?.accountName);
  if (suggester?.codeName)
    Sentry.setTag('suggester.brand', suggester?.codeName);
};

/**
 * Server will follow the suggester's twitter dm account, safe to call multiple
 * times b/c the server checks to see if they're not linked/already following
 * @param {number} publisherId
 * @returns {Promise<Response>}
 */
export const followSuggester = async (publisherId: number) => {
  return post(`publisher/publishers/${publisherId}/follow_suggester`);
};

export const fetchPublisherCodeUrl = async (publisherId: number) => {
  return post<{ url: string; code: string; brand: string }>(
    'publisher/publisher_codes',
    {
      publisherId,
    }
  );
};

export const claimLegacyPublisher = async () => {
  return post<UserApiPayload>('publisher/publishers/claim-legacy-publisher');
};

export const copyPublisher = async (params: {
  publisherId: number;
  suggesterId: number;
  inviteCode: string;
}) =>
  post<UserApiPayload | { errors: string }>(
    'publisher/publishers/copy',
    params
  );
