import configureStore from './configureStore';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from './ducks';

const store = configureStore();

export default store;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
