import { clsx } from 'clsx';
import React from 'react';
import { useIntl } from 'hooks';
import { Link } from 'react-router-dom';
import { IconArrowLeft } from 'icons';
import Filters from './components/Filters';
import SubNav from './components/SubNav';

interface Props {
  className?: string;
  backRoute?: string;
  backTitle?: string;
  title: string;
  children?: React.ReactNode;
  renderSubNav?: React.ReactNode;
  renderSubNavPosition?: 'end' | 'center';
  renderSubNavIsStatic?: boolean;
}

export default function MainTopBar(props: Props) {
  const {
    title,
    className,
    renderSubNav,

    backRoute,
    backTitle,
    renderSubNavPosition = 'end',
    renderSubNavIsStatic = false,
  } = props;
  const { t } = useIntl();

  const subNavContainerClassNames =
    'flex w-full overflow-hidden py-1 tablet:py-0';

  return (
    <div className={clsx('container container--medium bg-white', className)}>
      <div
        className={clsx(
          'transition-fast origin-bottom-left pt-3',
          renderSubNav && renderSubNavIsStatic
            ? 'block'
            : 'flex items-center pl-2 tablet:mb-1 tablet:pl-0 tablet:block tablet:last:mb-0'
        )}
      >
        {!!backRoute && (
          <Link
            to={backRoute}
            className="inline-flex items-center gap-1 mt-0.5 mr-1 text-dark group tablet:mb-2 tablet:mr-0"
            title={
              backTitle
                ? t('MainTopBar__BackTo', { backTitle })
                : t('MainTopBar__Back')
            }
          >
            <IconArrowLeft className="w-3 h-3 flex-shrink-0 transition-all pointer-fine:group-hover:-translate-x-0.5" />
            <div className="hidden h7 tablet:block">
              {backTitle || t('MainTopBar__Back')}
            </div>
          </Link>
        )}
        <div
          className={
            renderSubNav && renderSubNavIsStatic
              ? 'flex justify-between items-center px-2 tablet:px-0'
              : 'tablet:flex tablet:items-end tablet:justify-between tablet:gap-2'
          }
        >
          <h1 className="h4 font-semibold mr-2 my-0 tablet:h1 tablet:mr-0 tablet:mb-0.5 tablet:w-2/5">
            {title}
          </h1>

          {renderSubNav && (
            <div
              className={`${
                renderSubNavIsStatic ? '' : 'hidden tablet:block max-w-[60%]'
              } ${
                renderSubNavPosition === 'center' ? 'self-center' : 'self-end'
              }`}
            >
              <div className={subNavContainerClassNames}>{renderSubNav}</div>
            </div>
          )}
        </div>
      </div>

      {renderSubNav && !renderSubNavIsStatic ? (
        <div className="tablet:hidden">
          <div className={subNavContainerClassNames}>{renderSubNav}</div>
        </div>
      ) : null}
    </div>
  );
}

MainTopBar.Filters = Filters;
MainTopBar.SubNav = SubNav;
