import * as api from 'api/user';
import camelize from 'camelize';
import { ForgotPasswordModal } from 'components/shared';
import isValidContactValue from 'helpers/isValidContactValue';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserMfaRequired, logInUser } from 'redux/ducks/user';
import { useAppSelector } from 'redux/store';
import UserApiPayload from 'types/UserApiPayload';
import Form from '../Form';
import MagicLinkModal from '../MagicLinkModal';
import { SignInFormRecoveryCode, SignInFormValidateOtp } from './components';
import Btn from '../Btn';

interface Props {
  onSuccess?: (data: UserApiPayload) => void;
  onBeforeSuccess?: (data: UserApiPayload) => void;
  contactValue?: string;
  autoFocus?: boolean;
  renderSignIn?: (form: React.ReactNode) => React.ReactNode;
  renderMfa?: (form: React.ReactNode) => React.ReactNode;
}

export default function SignInForm(props: Props) {
  const {
    onSuccess,
    onBeforeSuccess,
    contactValue,
    autoFocus,
    renderSignIn,
    renderMfa,
  } = props;
  const { t } = useIntl();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    contactValue: contactValue || '',
    password: '',
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isUsingRecoveryCode, setIsUsingRecoveryCode] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(
    false
  );
  const [isMagicLinkModalOpen, setIsMagicLinkModalOpen] = useState(false);
  const mfaValues = useAppSelector((state) => state.user.mfaValues);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async () => {
    const sanitizedValues = {
      ...values,
      contactValue: values.contactValue.trim(),
    };

    if (!isValidContactValue(sanitizedValues.contactValue)) {
      setErrorMsg(t('Global__InvalidContactValue'));
      return;
    }
    setIsLoggingIn(true);
    try {
      const json = camelize(await api.logIn(sanitizedValues));
      if ('user' in json) {
        onBeforeSuccess && onBeforeSuccess(json);
        dispatch(logInUser(json));
        onSuccess && onSuccess(json);
      } else if ('tempToken' in json) {
        // User needs to MFA login
        setIsLoggingIn(false);
        dispatch(fetchUserMfaRequired(json));
      } else {
        setIsLoggingIn(false);
        setErrorMsg(t('Onboarding__LogInError--noUser'));
      }
    } catch (e) {
      console.error(e);
      setIsLoggingIn(false);
      setErrorMsg(t('Global__UnexpectedError'));
    }
  };

  const signInForm = (
    <>
      <Form onSubmit={handleSubmit}>
        {!!errorMsg && (
          <div className="mb-2.5 text-14 text-error text-center">
            {errorMsg}
          </div>
        )}

        <div className="mb-1.5">
          <Form.TextInput
            value={values.contactValue}
            name="contactValue"
            onChange={handleChange}
            placeholder={t('Onboarding__LogInPlaceholder--contactValue')}
            autoFocus={autoFocus}
            autoCapitalize="off"
            disabled={isMagicLinkModalOpen}
          />
        </div>

        <div className="mb-1.5">
          <div className="mb-1">
            <Form.TextInput
              value={values.password}
              name="password"
              onChange={handleChange}
              type="password"
              placeholder={t('Onboarding__LogInPlaceholder--password')}
              disabled={isMagicLinkModalOpen}
            />
          </div>

          <div className="text-right text-14 leading-tight">
            <button
              type="button"
              onClick={() => setIsForgotPasswordModalOpen(true)}
              className="text-socialiePink hover:underline"
            >
              {t('SignInForm__ForgotPassword')}
            </button>
          </div>
        </div>

        <Btn
          className="mb-1"
          type="submit"
          isLoading={isLoggingIn}
          disabled={isLoggingIn || !values.contactValue || !values.password}
        >
          {t('Button__Continue')}
        </Btn>
      </Form>

      <Btn
        color="outline"
        type="button"
        onClick={() => setIsMagicLinkModalOpen(true)}
      >
        {t('SignInForm__MagicLink')}
      </Btn>

      <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onRequestClose={() => setIsForgotPasswordModalOpen(false)}
      />

      {isMagicLinkModalOpen && (
        <MagicLinkModal
          initialValue={values.contactValue}
          onRequestClose={() => setIsMagicLinkModalOpen(false)}
        />
      )}
    </>
  );

  const mfaForm = isUsingRecoveryCode ? (
    <SignInFormRecoveryCode
      {...mfaValues}
      onSuccess={onSuccess}
      onBeforeSuccess={onBeforeSuccess}
      onSetIsUsingRecoveryCode={(isUsing: boolean) =>
        setIsUsingRecoveryCode(isUsing)
      }
    />
  ) : (
    <SignInFormValidateOtp
      {...mfaValues}
      onSuccess={onSuccess}
      onBeforeSuccess={onBeforeSuccess}
      onSetIsUsingRecoveryCode={(isUsing: boolean) =>
        setIsUsingRecoveryCode(isUsing)
      }
    />
  );

  if (mfaValues.mfaProtocol) {
    return renderMfa ? <>{renderMfa(mfaForm)}</> : mfaForm;
  }

  return renderSignIn ? <>{renderSignIn(signInForm)}</> : signInForm;
}

SignInForm.defaultProps = {
  autoFocus: true,
};
