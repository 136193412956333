import { get, post } from './shared';

export const checkInviteCode = async (code: string) => {
  const endpoint = 'publisher/onboarding/validate';
  return post(endpoint, { code });
};

export const fetchFlags = async () => {
  const endpoint = 'publisher/onboarding';
  return get(endpoint);
};
