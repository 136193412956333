import { get } from 'api/shared';
import routes from 'helpers/routes';
import { identifyFullStoryUser } from 'api/user';

export default async (redirectPath?: string) => {
  const path = redirectPath ? redirectPath : routes.auth.login.root;
  identifyFullStoryUser();
  await get(`publisher/sign_out`);
  window.location.href = `${window.location.origin}${path}`;
};
