import React from 'react';
import Modal from 'components/shared/modals/Modal';
import { updatedUser } from 'redux/ducks/user';
import { useIntl, useCurrentUser } from 'hooks';
import { UpdateUserTwitter } from 'components/shared';
import PlatformIcon from './PlatformIcon';
import * as api from 'api/user';
import flashMessage from 'helpers/flashMessage';
import { useAppDispatch } from 'redux/store';
import { IconRemove } from 'icons';
import Btn from './Btn';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function EditTwitterDmModal(props: Props) {
  const { isOpen, onRequestClose } = props;
  const user = useCurrentUser();

  const action = user?.twitterUserId ? 'Edit' : 'Add';

  const { t } = useIntl();

  const dispatch = useAppDispatch();

  const handleRemove = async () => {
    const updates = {
      twitterUserId: null,
      twitterUsername: null,
    };
    const data = await api.updateUser(updates);
    if ('errors' in data) {
      console.warn(data.errors);
      flashMessage('Global__UnexpectedError', { isError: true });
    } else {
      dispatch(updatedUser(updates));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      theme="bottom"
      contentLabel={t(`EditTwitterDmModal__${action}Heading`)}
      renderHeading={t(`EditTwitterDmModal__${action}Heading`)}
    >
      <div>
        <div className="mb-3">
          <div className="subheading">
            {t(`EditTwitterDmModal__${action}SubHeading`)}
          </div>
        </div>

        {user?.twitterUsername ? (
          <>
            <div className="mb-2 flex items-center bg-white p-1 border-default rounded text-dark">
              <div className="mr-1 flex-shrink-0 w-6 h-6 bump-down-1">
                <PlatformIcon branded platformId="twitter" circled={false} />
              </div>

              <span className="bump-up-1">@{user.twitterUsername}</span>

              <button
                onClick={handleRemove}
                className="ml-auto w-3 h-3 p-0.5 pr-0 text-light hover:text-dark"
              >
                <IconRemove className="block w-full h-full" />
              </button>
            </div>
          </>
        ) : (
          <UpdateUserTwitter onSuccess={onRequestClose} />
        )}

        <div className="text-center">
          <Btn color="grey" onClick={onRequestClose} type="button">
            {t('Button__Cancel')}
          </Btn>
        </div>
      </div>
    </Modal>
  );
}
