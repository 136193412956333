import * as QueryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import PageLoadParams from 'types/PageLoadParams';
import { OtherAction } from './global';

export enum TypeKeys {
  CLEAR_PAGE_LOAD_PARAMS = 'page-load-parameters/CLEAR',
}

export interface PageLoadParamsCleared {
  type: TypeKeys.CLEAR_PAGE_LOAD_PARAMS;
  payload: {
    params: PageLoadParams;
  };
}

type ActionTypes = PageLoadParamsCleared | OtherAction;

export type State = PageLoadParams | null;

export const clearQueryParameters = () => async (dispatch: Function) => {
  return dispatch({
    type: TypeKeys.CLEAR_PAGE_LOAD_PARAMS,
    payload: {},
  });
};

let initialState = {
  ...QueryString.parse(window.location.search, {
    arrayFormat: 'bracket',
  }),
};

export default (
  state = isEmpty(initialState) ? null : initialState,
  action: ActionTypes
): State => {
  switch (action.type) {
    case TypeKeys.CLEAR_PAGE_LOAD_PARAMS:
      // TODO: ideally this would only remove auth related params
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
      return null;
    default:
      return state;
  }
};
