import { post } from './shared';
import NotificationPreferences from 'types/NotificationPreferences';

const endpoint = 'publisher/notification_preferences';

export const addNotificationPreference = (
  type: keyof NotificationPreferences,
  publisherId: number
) => {
  return post(endpoint, { type, publisherId });
};

export const removeNotificationPreference = (
  type: keyof NotificationPreferences,
  publisherId: number
) => {
  return post(endpoint, { type, publisherId }, 'DELETE');
};
