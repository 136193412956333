import { createSlice } from '@reduxjs/toolkit';
import { queryTwitterMention } from 'api/platformMention';
import camelize from 'camelize';
import Form from 'components/shared/Form';
import flashMessage from 'helpers/flashMessage';
import { useIntl } from 'hooks';
import { IconLoader, IconSearch } from 'icons';
import React, { useReducer, useRef } from 'react';
import TwitterSearchResult from 'types/TwitterSearchResult';
import UpdateUserTwitterSearchResults from './UpdateUserTwitterSearchResults';

interface Props {
  onSelect: (result: TwitterSearchResult) => void;
  isSaving?: boolean;
  autoFocus?: boolean;
}

const initialState = {
  searchResults: [] as TwitterSearchResult[],
  isSearching: false,
  noResults: false,
  query: '',
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'UpdateUserTwitterSearchReducer',
  reducers: {
    searchStart: (state) => {
      state.isSearching = true;
      state.searchResults = [];
    },
    searchStop: (state) => {
      state.isSearching = false;
    },
    searchSuccess: (state, action: { payload: TwitterSearchResult[] }) => {
      state.searchResults = action.payload;
      if (!action.payload.length) state.noResults = true;
    },
    searchClear: (state) => {
      state.isSearching = false;
      state.noResults = false;
      state.searchResults = [];
    },
    updateQuery: (state, action: { payload: string }) => {
      state.query = action.payload;
    },
  },
});

export default function UpdateUserTwitterSearch(props: Props) {
  const { onSelect, isSaving, autoFocus } = props;
  const { t } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { searchResults, noResults, isSearching, query } = state;

  const handleSearch = async (value?: string) => {
    let searchTerm = (value || query).trim();
    if (!searchTerm || searchTerm === '@') return;

    dispatch(actions.searchStart());

    try {
      const data = camelize(await queryTwitterMention(searchTerm, 'user'));
      const results = data || [];
      dispatch(actions.searchSuccess(results));
    } catch (e) {
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
    } finally {
      dispatch(actions.searchStop());
    }
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        className="flex items-center gap-1 mb-2"
      >
        <Form.TextInput
          ref={inputRef}
          value={state.query}
          autoFocus={autoFocus}
          onChange={(e) => dispatch(actions.updateQuery(e.target.value))}
          onClick={(e) => e.stopPropagation()}
          placeholder={t('UpdateUserTwitterSearch__Placeholder')}
          skipField
        />

        <button
          type="submit"
          disabled={!state.query || state.query === '@' || isSearching}
          className="h-5 px-2 bg-grey1 text-white rounded-xl cursor-pointer disabled:cursor-auto"
          aria-label={t('Button__Search')}
        >
          <span className="w-3 h-full flex items-center justify-center">
            {isSearching ? (
              <IconLoader className="block w-2 h-2" />
            ) : (
              <IconSearch className="block w-3 h-3" />
            )}
          </span>
        </button>
      </form>

      <UpdateUserTwitterSearchResults
        searchResults={searchResults}
        noResults={noResults}
        onSave={onSelect}
        isSaving={isSaving}
      />
    </div>
  );
}
