import React from 'react';
import { clsx } from 'clsx';
import routes from 'helpers/routes';
import { Link, Route } from 'react-router-dom';
import { useIntl } from 'hooks';
import { FlyoutMenu, PublisherAvatarWithLogo } from 'components/shared';
import Publisher from 'types/Publisher';
import { GlobalNavLinks } from '../GlobalNav';
import {
  IconGearOutline,
  IconHeadsetOutline,
  IconSignOutV2,
  IconUsersV2,
} from 'icons';
import signOut from 'helpers/auth/signOut';

interface Props {
  publisher: Publisher;
  links: GlobalNavLinks;
  isContactModalOpen: boolean;
  onClickContact: () => void;
  showContactLink: boolean;
}

export default function GlobalNavMobile(props: Props) {
  const { links, onClickContact, showContactLink } = props;
  const { t } = useIntl();
  const containerClassNames =
    'flex-1 flex flex-col items-center mx-1 px-1 pt-0.5 border-solid border-t-4 border-transparent h-full bump-up-1';

  return (
    <div
      className="flex justify-center fixed bottom-0 bg-white left-0 z-50 w-full border-t-default"
      style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
    >
      <div className="flex justify-center items-center bg-white px-1 h-9">
        {links.map((link) => (
          <Route
            key={link.href}
            path={link.href}
            exact={link.exact}
            children={({ match }) => {
              const isActive = !!match;
              return (
                <Link to={link.href} className={containerClassNames}>
                  <div className="w-4.5 h-4.5 flex items-center justify-center">
                    {isActive && link.iconActive ? (
                      <link.iconActive className="w-3 h-3 text-dark" />
                    ) : (
                      <link.icon className="w-3 h-3 text-dark" />
                    )}
                  </div>
                  <span
                    className={clsx(
                      'text-11 leading-tight whitespace-nowrap',
                      isActive ? 'text-dark' : 'text-light'
                    )}
                  >
                    {t(`GlobalNav__${link.name}`)}
                  </span>
                </Link>
              );
            }}
          />
        ))}

        <FlyoutMenu
          className="!min-w-22 bg-grey1 flex justify-center"
          placement="top-end"
          renderButton={(onClick) => (
            <button
              type="button"
              onClick={onClick}
              className={containerClassNames}
            >
              <Route
                path={routes.settings.root}
                children={({ match }) => {
                  const isActive = !!match;
                  return (
                    <>
                      <div className="rounded-xl w-4.5 h-4.5 flex items-center justify-center">
                        <div className="w-4 h-4 relative -top-0.25">
                          <PublisherAvatarWithLogo
                            className="border-default"
                            theme={isActive ? 'socialiePink' : 'dark'}
                          />
                        </div>
                      </div>
                      <span
                        className={clsx(
                          'text-11 leading-tight whitespace-nowrap',
                          isActive ? 'text-dark' : 'text-light'
                        )}
                      >
                        {t('GlobalNav__More')}
                      </span>
                    </>
                  );
                }}
              />
            </button>
          )}
        >
          <FlyoutMenu.Item
            icon={IconGearOutline}
            to={routes.settings.root}
            isGlobalNavItem
            showArrow={false}
          >
            {t('GlobalNav__ProfileSettings')}
          </FlyoutMenu.Item>
          <FlyoutMenu.Item
            icon={IconUsersV2}
            to={routes.settings.publisherSwitcher}
            isGlobalNavItem
            showArrow={false}
          >
            {t('GlobalNav__ChangePublisher')}
          </FlyoutMenu.Item>
          {showContactLink && (
            <FlyoutMenu.Item
              icon={IconHeadsetOutline}
              onClick={onClickContact}
              isGlobalNavItem
              showArrow={false}
            >
              {t('GlobalNav__Contact')}
            </FlyoutMenu.Item>
          )}
          <FlyoutMenu.Item
            icon={IconSignOutV2}
            onClick={signOut}
            isGlobalNavItem
            showArrow={false}
          >
            {t('GlobalNav__Logout')}
          </FlyoutMenu.Item>
        </FlyoutMenu>
      </div>
    </div>
  );
}
