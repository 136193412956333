import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import camelize from 'camelize';
import * as api from 'api/publisherRequests';
import PartialPublisherRequest from 'types/PartialPublisherRequest';
import PartialPublisherRequestMetadata from 'types/PartialPublisherRequestMetadata';
import SuggestionsFilter from 'types/SuggestionsFilter';
import omitDeclinedRequests from 'helpers/omitDeclinedRequests';
import { TypeKeys as PRTypeKeys } from './publisherRequests';
import { RootState } from '.';
import rejectThunkApiError from 'helpers/rejectThunkApiError';
import {
  addFavoriteAttachment,
  removeFavoriteAttachment,
} from 'api/favoriteAttachments';
import { queryClient } from 'components/App';

// asyncThunks
export const fetch = createAsyncThunk(
  'partialPublisherRequests/fetch',
  async (
    { page, filter }: { page: number; filter: SuggestionsFilter },
    { rejectWithValue }
  ) => {
    try {
      const json = camelize(await api.fetchAllPublisherRequests(page, filter));
      return json;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e);
    }
  }
);

export const fetchContentRequests = createAsyncThunk(
  'partialPublisherRequests/fetchContentRequests',
  async (
    { page, filter }: { page: number; filter: SuggestionsFilter },
    { rejectWithValue }
  ) => {
    try {
      const json = camelize(await api.fetchAllPublisherRequests(page, filter));
      return json;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e);
    }
  }
);

export const bulkDestroy = createAsyncThunk<string[], string[]>(
  'partialPublisherRequests/bulkDestroy',
  async (requestKeys, { rejectWithValue, dispatch }) => {
    try {
      await api.bulkDestroyPublisherRequests(requestKeys);
      return requestKeys;
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const favorite = createAsyncThunk<string, string>(
  'partialPublisherRequests/favorite',
  async (requestKey, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const request = state.partialPublisherRequests.byKey[requestKey];
      if (!request || !request.attachments[0]) return requestKey;
      await addFavoriteAttachment(request.attachments[0].id, { requestKey });
      return requestKey;
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const unfavorite = createAsyncThunk<string, string>(
  'partialPublisherRequests/unfavorite',
  async (requestKey, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const request = state.partialPublisherRequests.byKey[requestKey];
      if (!request || !request.attachments[0]) return requestKey;
      await removeFavoriteAttachment(request.attachments[0].id);
      return requestKey;
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

// Reducer
export interface State {
  byKey: { [key: string]: PartialPublisherRequest };
  byDate: { [date: string]: string[] };
  keys: string[];
  contentRequests: PartialPublisherRequest[];
  metadata?: PartialPublisherRequestMetadata;
}

const initialState: State = {
  byKey: {},
  byDate: {},
  contentRequests: [],
  keys: [],
};

const { reducer } = createSlice({
  name: 'partialPublisherRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.fulfilled, (state, action) => {
      const { requests, metadata } = action.payload;
      const page = action.meta.arg.page;
      const newKeys = requests.map((request) => request.requestKey);

      return {
        ...state,
        metadata,
        byKey: { ...state.byKey, ...storeRequestsByKey(requests) },
        byDate: requests.reduce(
          (result, request) => {
            const date = new Date(request.sentAt).setHours(0, 0, 0, 0);
            return {
              ...result,
              [date]: [...(result[date] || []), request.requestKey],
            };
          },
          page === 1 ? {} : state.byDate
        ),
        contentRequests: state.contentRequests,
        keys: page === 1 ? newKeys : [...state.keys, ...newKeys],
      };
    });

    builder.addCase(fetchContentRequests.fulfilled, (state, action) => {
      const { requests } = action.payload;

      return {
        ...state,
        contentRequests: requests,
      };
    });

    builder.addCase(bulkDestroy.fulfilled, (state, action) => {
      action.payload.forEach((key) => {
        if (!state.byKey[key]) return;
        state.byKey[key].status = 'declined';
      });
    });

    builder.addCase(PRTypeKeys.UPDATED, (state, action: any) => {
      const { key, updates } = action.payload;
      if (!updates.status || !state.byKey[key]) return state;
      state.byKey[key].status = updates.status;
    });

    // Use pending for optimistic UI update
    builder.addCase(favorite.pending, (state, action) => {
      const requestKey = action.meta.arg;

      const request = state.byKey[action.meta.arg];
      if (!request || !request.attachments[0]) return state;
      state.byKey[requestKey].attachments[0].isFavorite = true;
    });

    builder.addCase(favorite.rejected, (state, action) => {
      const requestKey = action.meta.arg;
      const request = state.byKey[action.meta.arg];
      if (!request || !request.attachments[0]) return state;
      state.byKey[requestKey].attachments[0].isFavorite = false;
    });

    builder.addCase(unfavorite.pending, (state, action) => {
      const requestKey = action.meta.arg;
      const request = state.byKey[action.meta.arg];
      if (!request || !request.attachments[0]) return state;
      state.byKey[requestKey].attachments[0].isFavorite = false;
    });

    builder.addCase(unfavorite.rejected, (state, action) => {
      const requestKey = action.meta.arg;
      const request = state.byKey[action.meta.arg];
      if (!request || !request.attachments[0]) return state;
      state.byKey[requestKey].attachments[0].isFavorite = true;
    });

    builder.addCase(favorite.fulfilled, () => {
      queryClient.refetchQueries(['attachmentAlbum']);
      queryClient.refetchQueries(['suggestionAlbum']);
    });

    builder.addCase(unfavorite.fulfilled, () => {
      queryClient.refetchQueries(['attachmentAlbum']);
      queryClient.refetchQueries(['suggestionAlbum']);
    });
  },
});

export default reducer;

// Selectors
export const getContentRequests = (state: RootState) => {
  return state.partialPublisherRequests.contentRequests;
};

export const getPartialPublisherRequests = (
  state: RootState,
  options = { includeDeclined: false }
) => {
  const partialPublisherRequests = state.partialPublisherRequests.keys.map(
    (key: string) => {
      return state.partialPublisherRequests.byKey[key];
    }
  );

  if (options.includeDeclined) return partialPublisherRequests;
  return omitDeclinedRequests(partialPublisherRequests);
};

export const getPartialPublisherRequestsByDate = (
  state: RootState,
  date: string,
  options = { includeDeclined: false }
) => {
  const keys = state.partialPublisherRequests.byDate[date];
  if (!keys) return [];
  const partialPublisherRequests = keys.map(
    (key) => state.partialPublisherRequests.byKey[key]
  );

  if (options.includeDeclined) return partialPublisherRequests;
  return omitDeclinedRequests(partialPublisherRequests);
};

export const getMetadata = (state: RootState) =>
  state.partialPublisherRequests.metadata ||
  ({} as PartialPublisherRequestMetadata);

// Helpers for the reducer
const storeRequestsByKey = (
  requests: PartialPublisherRequest[]
): { [key: string]: PartialPublisherRequest } => {
  return requests.reduce(
    (result, request) => ({ ...result, [request.requestKey]: request }),
    {}
  );
};
