import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LegacyPublisher from 'types/LegacyPublisher';

const { actions, reducer } = createSlice({
  name: 'publisher/legacyPublisher',
  initialState: null as LegacyPublisher | null,
  reducers: {
    fetchedLegacyPublisher: (state, action: PayloadAction<LegacyPublisher>) => {
      return action.payload || null;
    },
    clearedLegacyPublisher: (state) => {
      return null;
    },
  },
});

export default reducer;

export const { fetchedLegacyPublisher, clearedLegacyPublisher } = actions;
