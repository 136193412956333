import { useIntl } from 'hooks';
import React from 'react';
import Modal from './modals/Modal';

interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  onRequestClose: () => void;
  onConfirm: () => any;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  heading?: string;
  theme?: 'primary' | 'destructive';
}

export default function ConfirmModal(props: Props) {
  const {
    isOpen,
    onRequestClose,
    onConfirm,
    isLoading,
    children,
    heading,
    confirmText,
    cancelText,
    theme,
  } = props;
  const { t } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t(heading || 'Global__AreYouSure')}
      renderHeading={t(heading || 'Global__AreYouSure')}
      onConfirm={onConfirm}
      confirmText={t(confirmText || 'Button__Continue')}
      confirmBtnProps={{ isLoading, color: theme }}
      onCancel={onRequestClose}
      cancelBtnProps={{ disabled: isLoading }}
      cancelText={t(cancelText || 'Button__Cancel')}
    >
      {children || t('Global__AreYouSure')}
    </Modal>
  );
}
