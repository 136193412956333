import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import cookie from 'react-cookies';
import withoutDialect from 'helpers/withoutDialect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import en from 'locales/en.json';
import fr from 'locales/fr.json';
import es from 'locales/es.json';
import pt from 'locales/pt.json';

const availableLanguages = {
  en,
  // this was specifically translated to fr-CA
  fr,
  // tranlated to es-MX
  es,
  // translated to pt-BR
  pt,
};

const browserLanguages = navigator.languages;
const cookieLanguage = cookie.load('locales.lang');
const browserLanguage =
  !cookieLanguage &&
  !isEmpty(browserLanguages) &&
  browserLanguages.find((l) => has(availableLanguages, withoutDialect(l)));

const initialLocale = cookieLanguage || browserLanguage || 'en-US';

const messages =
  availableLanguages[withoutDialect(initialLocale)] || availableLanguages.en;

const { reducer, actions } = createSlice({
  name: 'locales',
  initialState: { messages, lang: initialLocale },
  reducers: {
    languageChanged: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
      state.messages = availableLanguages[action.payload];
    },
  },
});

export default reducer;

export const changeLanguage = (lang: string) => async (dispatch: Function) => {
  cookie.save('locales.lang', lang, { path: '/' });
  return dispatch(actions.languageChanged(lang));
};
