import React from 'react';
import logoSocialie from 'images/logo-socialie.png';

interface Props {
  children?: React.ReactNode;
}

export default function UtilBar({ children }: Props) {
  return (
    <div className="fixed top-0 left-0 w-screen z-10 bg-white">
      <div
        className="flex items-center mx-auto px-2 h-5.5 text-14"
        style={{ maxWidth: '1920px' }}
      >
        <img src={logoSocialie} className="mr-1.5 h-3" alt="Socialie Logo" />

        {children && <div className="ml-auto">{children}</div>}
      </div>
    </div>
  );
}
