import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as flash } from 'redux-flash';
import accounts from './accounts';
import attachments from './attachments';
import batchDownloads from './batchDownloads';
import brand from './brand';
import contactMethods from './contactMethods';
import contentRequestSubmissions from './contentRequestSubmissions';
import drafts from './drafts';
import facebookPages from './facebookPages';
import fileUploads from './fileUploads';
import global from './global';
import identities from './identities';
import inboxFilterData from './inboxFilterData';
import invitations from './invitations';
import legacyPublisher from './legacyPublisher';
import legacyInstagramIdentity from './legacyInstagramIdentity';
import locales from './locales';
import notificationPreferences from './notificationPreferences';
import onboarding from './onboarding';
import pageLoadParams from './pageLoadParams';
import partialPublisherRequests from './partialPublisherRequests';
import platforms from './platforms';
import posts from './posts';
import publisher from './publisher';
import publisherRequests from './publisherRequests';
import publisherUsers from './publisherUsers';
import socket from './socket';
import suggester from './suggester';
import ui from './ui';
import user from './user';
import directUploadMediaSlice from './directUploadMediaSlice';
import features from './features';

export default function createReducer(history: History<any>) {
  return combineReducers({
    accounts,
    attachments,
    batchDownloads,
    brand,
    contactMethods,
    contentRequestSubmissions,
    directUploadMediaSlice,
    drafts,
    facebookPages,
    fileUploads,
    flash,
    global,
    identities,
    invitations,
    inboxFilterData,
    legacyInstagramIdentity,
    legacyPublisher,
    locales,
    notificationPreferences,
    onboarding,
    pageLoadParams,
    partialPublisherRequests,
    platforms,
    posts,
    publisher,
    publisherRequests,
    publisherUsers,
    socket,
    suggester,
    ui,
    user,
    features,
    router: connectRouter(history),
  });
}

export type RootState = ReturnType<ReturnType<typeof createReducer>>;
