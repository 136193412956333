import React from 'react';
import { clsx } from 'clsx';

interface Props {
  size?: 'small' | 'large';
  isChecked: boolean;
  isDisabled?: boolean;
  onCheck: () => void;
}

function Radio(props: Props) {
  const { size = 'small', isChecked, isDisabled, onCheck } = props;

  return (
    <div
      onClick={() => {
        if (!isDisabled) onCheck();
      }}
      className={clsx(
        'inline-flex items-center justify-center border border-solid rounded-full',
        {
          'h-2 w-2': size === 'small',
          'h-3 w-3': size === 'large',
          'bg-socialiePink border-socialiePink': !!isChecked,
          'bg-grey6 border-grey5': !!isDisabled,
          'bg-white border-grey5': !isChecked && !isDisabled,
        }
      )}
    >
      <div
        className={clsx('bg-white rounded-full', {
          'h-1 w-1': size === 'small',
          'h-1.5 w-1.5': size === 'large',
          'opacity-0': !isChecked || isDisabled,
          'opacity-100': !!isChecked,
        })}
      />
    </div>
  );
}

export default Radio;
