import { post } from './shared';
import LogInApiPayload from 'types/LogInApiPayload';

export async function createMagicLink(contactValue: string) {
  return post<void>('publisher/magic-links', { contactValue });
}

export async function validateMagicToken(token: string) {
  return post<LogInApiPayload>('publisher/magic-links/validate', { token });
}
