export default (path: string, ...vars: (string | number)[]) => {
  let pathVar = 0;
  return path
    .replace(/:\w+\??/g, (match, i) => {
      if (!vars[pathVar]) return '';
      const retVal = `${vars[pathVar]}`;
      pathVar = pathVar + 1;
      return retVal;
    })
    .replace(/\/+$/, '');
};
