import { post } from './shared';
import UserApiPayload from 'types/UserApiPayload';
import User from 'types/User';
import ChangePasswordParams from 'types/ChangePasswordParams';

export function isResetPasswordTokenValid(token: string) {
  return post<boolean>('publisher/users/password/status', {
    token,
  });
}

export function resetPassword(contactValue: string) {
  return post('publisher/users/password', {
    contactValue,
  });
}

export function updatePassword(password: string, token: string) {
  return post<
    | { errors?: any }
    | UserApiPayload
    | { tempToken: string; mfaProtocol: string; mfaPhone: string }
  >(
    'publisher/users/password',
    {
      user: {
        password,
        passwordConfirmation: password,
        resetPasswordToken: token,
      },
    },
    'PUT'
  );
}

export function changePassword(params: ChangePasswordParams) {
  return post<{ user?: User; errors?: ChangePasswordParams }>(
    'publisher/users/change-password',
    params,
    'POST'
  );
}
