import PhoneNumber from 'awesome-phonenumber';
import isValidEmailAddress from './isValidEmailAddress';

export default function (contactValue: string, type?: 'email' | 'phone') {
  const isValidEmail = isValidEmailAddress(contactValue);
  const isValidPhone = isValidPhoneNumber(contactValue);
  if (!type) return isValidEmail || isValidPhone;
  if (type === 'email') return isValidEmail;
  if (type === 'phone') return isValidPhone;
}

function isValidPhoneNumber(phoneNumber: string) {
  if (new PhoneNumber(phoneNumber).isValid()) return true;

  // Try adding the + for them
  if (new PhoneNumber(`+${phoneNumber}`).isValid()) return true;

  // 10-digit North American number support
  return new PhoneNumber(`+1${phoneNumber}`).isValid();
}
