import React from 'react';
import Modal from 'components/shared/modals/Modal';
import { useIntl } from 'hooks';
import Suggester from 'types/Suggester';
import { FormattedMessage } from 'react-intl';
import formatPhoneNumber from 'helpers/formatPhoneNumber';
import Btn from 'components/shared/Btn';
import { IconPhonePortrait } from 'icons';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  suggester?: Suggester | null;
}

export default function GlobalNavContactModal(props: Props) {
  const { isOpen, suggester, onRequestClose } = props;
  const { t } = useIntl();

  if (!suggester) return null;

  return (
    <Modal
      isOpen={isOpen}
      theme="bottom"
      onRequestClose={onRequestClose}
      renderHeading={t('GlobalNavContactModal__Heading')}
      contentLabel={t('GlobalNavContactModal__Heading')}
    >
      {suggester.parent && <ContactCard suggester={suggester.parent} />}

      <ContactCard suggester={suggester} />
    </Modal>
  );
}

interface ContactCardProps {
  suggester: Pick<Suggester, 'accountName' | 'twilioNumbers' | 'logoUrl'>;
}
function ContactCard({ suggester }: ContactCardProps) {
  if (!suggester.twilioNumbers[0]) return null;

  return (
    <div className="account-card mb-2 last:mb-0">
      <div className="flex items-center">
        <div className="flex-shrink-0 w-8 h-8 mr-1.5">
          <img
            src={suggester.logoUrl}
            alt={suggester.accountName}
            className="w-full h-full object-cover rounded-full"
          />
        </div>

        <div className="flex-1">
          <div className="mb-1">
            <FormattedMessage
              id="GlobalNavContactModal__Description"
              values={{
                accountName: suggester.accountName,
                phoneLink: (
                  <a
                    href={`sms:${suggester.twilioNumbers[0].phone}`}
                    className="text-primary underline whitespace-nowrap"
                  >
                    {formatPhoneNumber(
                      suggester.twilioNumbers[0].phone,
                      'national'
                    )}
                  </a>
                ),
              }}
            />
          </div>

          <Btn
            href={`sms:${suggester.twilioNumbers[0].phone}`}
            leftIcon={IconPhonePortrait}
            size="sm"
            variant="inlineBlock"
          >
            <FormattedMessage id="GlobalNavContactModal__SendMessage" />
          </Btn>
        </div>
      </div>
    </div>
  );
}
