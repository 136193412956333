import React from 'react';

export default (props: React.ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.19 1.5C6.497 1.5 1.881 6.08 1.881 11.73c0 1.932.54 3.74 1.48 5.282L1.5 22.5l5.708-1.813a10.33 10.33 0 0 0 4.982 1.272c5.694 0 10.31-4.58 10.31-10.23S17.884 1.5 12.19 1.5zm5.127 14.115c-.243.6-1.34 1.15-1.824 1.175-.483.025-.497.375-3.133-.77-2.635-1.146-4.221-3.93-4.346-4.11-.125-.179-1.02-1.455-.973-2.74.05-1.285.753-1.891 1.004-2.144a1.01 1.01 0 0 1 .714-.3c.207-.004.342-.006.495 0 .154.005.384-.033.584.498.2.53.677 1.835.738 1.968.061.133.099.287.005.458-.095.172-.143.278-.28.426-.137.148-.289.33-.412.443-.137.125-.28.261-.136.528.144.267.64 1.142 1.396 1.863.972.927 1.81 1.234 2.067 1.374.259.14.413.125.573-.043.16-.17.687-.739.872-.993.185-.255.36-.205.597-.109.238.096 1.508.776 1.766.917.259.14.431.212.493.323.062.112.042.634-.2 1.236z"
    />
  </svg>
);
