import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'api/facebook';
import camelize from 'camelize';
import { useIntl } from 'hooks';
import { IconCaretRight, IconFacebook, IconLoader } from 'icons';
import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { createFacebookPage, deleteFacebookPage } from 'redux/ducks/identities';
import FacebookApiPage from 'types/FacebookApiPage';
import Btn from '../Btn';
import Modal from '../modals/Modal';

interface Props {
  onRequestClose: () => void;
}

const initialState = {
  isLoading: false,
  facebookPages: [] as FacebookApiPage[],
  isError: false,
  selectingId: '',
};

const { reducer, actions } = createSlice({
  name: 'FacebookPageSelectorModalReducer',
  initialState: initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action: PayloadAction<FacebookApiPage[]>) => {
      state.isLoading = false;
      state.facebookPages = action.payload;
    },
    fetchError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    selectStart: (state, action: PayloadAction<string>) => {
      state.selectingId = action.payload;
    },
  },
});

export default function FacebookPageSelectorModal(props: Props) {
  const { onRequestClose } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, facebookPages, isError, selectingId } = state;
  const { t } = useIntl();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const fetchPages = async () => {
      dispatch(actions.fetchStart());
      try {
        const data = camelize(await api.getPages());
        if (data.pages) {
          dispatch(actions.fetchSuccess(data.pages));
        } else {
          console.error(data);
          dispatch(actions.fetchError());
        }
      } catch (e) {
        console.error(e);
        dispatch(actions.fetchError());
      }
    };
    fetchPages();
  }, []);

  const selectPage = async (facebookPage: FacebookApiPage) => {
    dispatch(actions.selectStart(facebookPage.id));
    await reduxDispatch(deleteFacebookPage());
    await reduxDispatch(createFacebookPage(facebookPage));
    onRequestClose();
  };

  return (
    <Modal
      isOpen={true}
      theme="bottom"
      onRequestClose={onRequestClose}
      contentsClassName="tablet:max-w-52"
      contentLabel={t('FacebookPageSelector__Header')}
    >
      {isLoading ? (
        <IconLoader />
      ) : isError ? (
        <>
          <div className="mb-3 text-light">{t('Global__UnexpectedError')}</div>
          <Btn color="darkGrey" onClick={onRequestClose}>
            {t('Button__Close')}
          </Btn>
        </>
      ) : (
        <>
          <div className="flex items-center gap-2 mb-2 pb-4 border-b-default">
            <IconFacebook className="w-5 h-5 text-facebook" />
            <div className="flex-1 text-dark text-14">
              {t('FacebookPageSelector__Description')}
            </div>
          </div>
          {facebookPages.map((facebookPage) => (
            <button
              key={facebookPage.id}
              className="block w-full mb-2 rounded-xl bg-greyBg last:mb-0"
              onClick={() => selectPage(facebookPage)}
              disabled={!!selectingId}
            >
              <span className="flex items-center overflow-hidden p-2">
                <img
                  src={facebookPage.picture.data.url}
                  className="rounded-full mr-2 w-5 h-5"
                  alt="Avatar"
                />

                <span className="text-dark flex-1 truncate text-left">
                  {facebookPage.name}
                </span>

                <span className="ml-auto bump-down-1 text-dark">
                  {selectingId === facebookPage.id ? (
                    <IconLoader className="w-2 h-2" />
                  ) : (
                    <IconCaretRight className="w-2 h-2" />
                  )}
                </span>
              </span>
            </button>
          ))}
        </>
      )}
    </Modal>
  );
}
