import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as api from 'api/contactMethods';
import camelize from 'camelize';
import { logInUser } from 'redux/ducks/user';
import { useIntl } from 'hooks';
import { AccountConversionPage } from '../components';
import { AddSecondaryContactForm, LoadingSpinner } from 'components/shared';

interface Props {
  onSuccess: () => void;
}

export default function AccountConversionSecondaryContact(props: Props) {
  const { onSuccess } = props;
  const { t } = useIntl();
  const [isPurging, setIsPurging] = useState(false);
  const [isPurgeError, setIsPurgeError] = useState(false);
  const reduxDispatch = useDispatch();

  const handlePurgeContactMethods = async () => {
    if (isPurging) return;

    setIsPurging(true);
    setIsPurgeError(false);
    try {
      const data = camelize(await api.purgeContactMethods());
      setIsPurging(false);
      reduxDispatch(logInUser(data));
      onSuccess();
    } catch (e) {
      setIsPurging(false);
      setIsPurgeError(true);
      console.error(e);
    }
  };

  if (isPurging) return <LoadingSpinner />;

  return (
    <AccountConversionPage>
      <AddSecondaryContactForm onSuccess={handlePurgeContactMethods} />
      {isPurgeError && (
        <div className="text-14 text-error">{t('Global__UnexpectedError')}</div>
      )}
    </AccountConversionPage>
  );
}
