import * as api from 'api/mfa';
import camelize from 'camelize';
import Form from 'components/shared/Form';
import flashMessage from 'helpers/flashMessage';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logInUser } from 'redux/ducks/user';
import UserApiPayload from 'types/UserApiPayload';
import Btn from 'components/shared/Btn';

interface Props {
  tempToken: string;
  onSetIsUsingRecoveryCode: (isUsingCode: boolean) => void;
  onSuccess?: (data: UserApiPayload) => void;
  onBeforeSuccess?: (data: UserApiPayload) => void;
}

export default function SignInFormRecoveryCode(props: Props) {
  const {
    tempToken,
    onSetIsUsingRecoveryCode,
    onSuccess,
    onBeforeSuccess,
  } = props;
  const { t } = useIntl();
  const [error, setError] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const json = camelize(
        await api.recoveryCodeLogIn(recoveryCode, tempToken)
      );
      setIsSubmitting(false);
      if ('user' in json) {
        onBeforeSuccess?.(json);
        dispatch(logInUser(json));
        onSuccess?.(json);
      } else if ('errors' in json && json.errors) {
        console.warn(json.errors);
        setError(t('Validation__InvalidSecurityCode'));
        setIsInvalid(true);
      } else {
        flashMessage('Global__UnexpectedError', { isError: true });
      }
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
    }
  };

  return (
    <>
      <div className="subheading mb-4">
        <div>{t('Security__MfaLogin--UseRecovery-Desc')}</div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-1.5">
          <Form.TextInput
            value={recoveryCode}
            isInvalid={!!isInvalid}
            onChange={(e) => setRecoveryCode(e.target.value)}
            placeholder={t('Security__MfaLogin--UseRecovery-Placeholder')}
            autoFocus
            skipField
          />
          {!!error && (
            <div className="text-left text-error text-sm">{t(error)}</div>
          )}
        </div>

        <div>
          <Btn
            type="submit"
            isLoading={isSubmitting}
            disabled={!recoveryCode || isSubmitting}
          >
            {t('Button__Continue')}
          </Btn>
        </div>
      </form>
      <div className="mt-4">
        <div className="flex items-center justify-center">
          <div
            className="text-socialiePink cursor-pointer hover:text-socialiePinkHover"
            onClick={() => onSetIsUsingRecoveryCode(false)}
          >
            <span className="text-14">
              {t('Security__MfaLogin--UseRecovery-GoBack')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
