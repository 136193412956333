import { useState, useEffect } from 'react';

const getIsScrolled = (threshold: number) => {
  const scrolledFromTop =
    typeof window !== 'undefined' && document.body !== undefined
      ? document.body.scrollTop
      : 0;
  const scrolledDown =
    typeof window !== 'undefined' &&
    (window.pageYOffset || scrolledFromTop) > threshold;
  return scrolledDown;
};

export default function useIsScrolled(threshold = 0) {
  const [isScrolled, setIsScrolled] = useState(() => getIsScrolled(threshold));

  useEffect(() => {
    const onScroll = () => {
      const newIsScrolled = getIsScrolled(threshold);
      setIsScrolled(newIsScrolled);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [threshold]);

  return isScrolled;
}
