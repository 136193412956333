import { get, post } from './shared';
import Invitation from 'types/Invitation';
import UserApiPayload from 'types/UserApiPayload';

export function fetchInvitation(id: string) {
  return get<Invitation | { redirectBrand: string }>(
    `publisher/invitations/${id}`
  );
}

export function acceptInvitation(id: string) {
  return post<UserApiPayload>(`publisher/invitations/${id}/accept`);
}
