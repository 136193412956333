import React from 'react';
import { connect } from 'react-redux';
import PhoneInput from './PhoneInput';
import { splitPhoneNumber } from './utils';
import TwilioNumber from 'types/TwilioNumber';
import Suggester from 'types/Suggester';
import { RootState } from 'redux/ducks';

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  isInvalid?: boolean;
  name?: string;
  forceWorldwide?: boolean;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
}

interface State {
  phoneNumber: string;
  countryCode: string;
}

interface ConnectedState {
  suggester: Suggester | null;
}

type Props = OwnProps & ConnectedState;

class PhoneInputContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { value } = props;

    this.state = value
      ? splitPhoneNumber(value)
      : { countryCode: '', phoneNumber: '' };
  }

  onChangeCountryCode = (countryCode: string) => {
    this.setState({ countryCode }, this.onChange);
  };

  onChangePhoneNumber = (phoneNumber: string) => {
    this.setState({ phoneNumber }, this.onChange);
  };

  onChange = () => {
    const { countryCode, phoneNumber } = this.state;
    if (phoneNumber) {
      this.props.onChange(`${countryCode}${phoneNumber}`);
    } else {
      this.props.onChange('');
    }
  };

  render() {
    if (!this.props.suggester) return null;

    const {
      onFocus,
      onBlur,
      name,
      placeholder,
      required,
      forceWorldwide,
      isClearable,
    } = this.props;

    const {
      twilioNumbers,
      config: { allowWorldwideSms, countryCodeWhitelist },
    } = this.props.suggester;
    const countryCodes = twilioNumbers.map((t: TwilioNumber) => t.countryCode);

    return (
      <PhoneInput
        value={this.state.phoneNumber}
        countryCode={this.state.countryCode}
        onChange={this.onChangePhoneNumber}
        onChangeCountryCode={this.onChangeCountryCode}
        countryCodes={countryCodes}
        allowWorldwideSms={allowWorldwideSms || forceWorldwide}
        countryCodeWhitelist={forceWorldwide ? [] : countryCodeWhitelist || []}
        isInvalid={this.props.isInvalid}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        required={required}
        isClearable={isClearable}
      />
    );
  }
}
const mapStateToProps = (state: RootState) => ({ suggester: state.suggester });

export default connect(mapStateToProps)(PhoneInputContainer);
