import { IconWhistle } from 'icons';
import React, { createElement } from 'react';

type Props = {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  renderButton?: React.ReactNode;
  skeletonStyle?: 'none' | 'grid' | 'content';
} & (
  | { renderIcon?: React.ReactNode; icon?: undefined }
  | { icon?: typeof IconWhistle; renderIcon?: undefined }
);

export default function NoContentCTA(props: Props) {
  const {
    renderIcon,
    icon,
    heading,
    subheading,
    renderButton,
    skeletonStyle = 'none',
  } = props;
  return (
    <div className="relative pt-5 h-full w-full">
      <div className="relative z-10 flex flex-col items-center justify-center text-center">
        {renderIcon
          ? renderIcon
          : icon
          ? createElement(icon, { className: 'w-7 h-7 text-primary' })
          : null}
        <div className="mb-4 pt-2 last:mb-0 first:pt-0">
          <h2 className="h3 mb-3 last:mb-0">{heading}</h2>
          {subheading && <div className="text-dark">{subheading}</div>}
        </div>
        {renderButton}
      </div>

      {skeletonStyle === 'grid' && (
        <div className="absolute inset-0 opacity-50">
          <div className="grid grid-cols-3 gap-px tablet:grid-cols-5">
            {[...Array(30)].map((i) => (
              <div key={i} className="aspect-w-1 aspect-h-1 bg-greyBg" />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
