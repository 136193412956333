import React, { useContext } from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';

export const IntlContext = React.createContext<InjectedIntl>(
  {} as InjectedIntl
);

// turn the old context into the new context
export const InjectIntlContext = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
});

// the format message hook
export default function useIntl() {
  const intl = useContext(IntlContext);

  function t(id: string, values?: any) {
    return intl.formatMessage({ id }, values);
  }

  return { ...intl, t };
}
