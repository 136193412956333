import { post } from './shared';
import Post from 'types/Post';

interface PostData extends Post {
  socketId: string;
}

export const createPost = async (postData: PostData) => {
  const json = await post('publisher/posts', {
    post: postData,
  });
  return json;
};

export const updatePost = async (
  id: number,
  postAt: any,
  text: string,
  socketId: string
) => {
  const endpoint = `publisher/posts/${id}`;
  return post(endpoint, { post: { text, postAt, socketId } }, 'PUT');
};

export const repost = async (data: Post, socketId: string) => {
  const endpoint = `publisher/posts/${data.id}/repost`;
  return post(endpoint, {
    post: { socketId, text: data.text, mentions: data.mentions },
  });
};

export const cancelPost = async (id: number) => {
  const endpoint = `publisher/posts/${id}/cancel`;
  return post(endpoint, {});
};
