import React, { useEffect, useState, useRef, useContext } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';

const CHEAT_CODE = 'imacoolkid';

interface Props {
  children: React.ReactNode;
}

const GodModeContext = React.createContext(false);

export function GodModeProvider({ children }: Props) {
  const [isGodMode, setIsGodMode] = useState(
    process.env.NODE_ENV === 'development'
  );
  const input = useRef('');

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      const key = e.key && e.key.toLowerCase();
      if (!key) return;

      input.current = `${input.current}${key}`;

      if (input.current === CHEAT_CODE) {
        alert(`GOD MODE: ${isGodMode ? 'OFF' : 'ON'}`);
        setIsGodMode(!isGodMode);
        input.current = '';
        return;
      }

      const escapedInput = escapeRegExp(input.current);
      if (!CHEAT_CODE.match(new RegExp(`^${escapedInput}`))) {
        input.current = key;
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <GodModeContext.Provider value={isGodMode}>
      {children}
    </GodModeContext.Provider>
  );
}

export default function useGodMode() {
  const isGodMode = useContext(GodModeContext);
  if (isGodMode === undefined) {
    throw new Error('useGodMode must be used within a GodModeProvider');
  }
  return isGodMode;
}
