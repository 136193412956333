import * as React from 'react';
import Brand from 'types/Brand';
import Color from 'color';

interface Props {
  brand: Brand;
}

export default ({ brand }: Props) => {
  return (
    <style type="text/css">{`
      :root {
        --color-brand: ${brand.primaryColor};
      }

      .--brand-color {
        color: ${brand.primaryColor};
      }

      .--brand-border-color {
        border-color: ${brand.primaryColor};
      }

      .--brand-bg-color {
        background: ${brand.primaryColor};
      }

      .rdtPicker .rdtCounter .rdtBtn {
        color: ${brand.primaryColor};
      }

      .rdtPicker td.rdtActive,
      .rdtPicker td.rdtActive:hover {
        background-color: ${brand.primaryColor};
      }

      .rdtPicker td.rdtToday:before {
        border-bottom-color: ${brand.primaryColor};
      }

      ::placeholder {
        color: ${new Color(brand.primaryColor).alpha(0.2).darken(0.4)};
      }

      .TalkingPoint::before {
        background-color: var(--color-publisher-request);
      }

      .HomeTopBar__NavItem a.active {
        border-bottom: 2px solid ${brand.primaryColor};
        color: ${brand.primaryColor};
      }
    `}</style>
  );
};
