import React from 'react';
import { clsx } from 'clsx';
import { removeMessage, getFlashMessages } from 'redux-flash';
import { AnimatePresence, motion } from 'framer-motion';
import { IconClose } from 'icons';
import { useAppSelector, useAppDispatch } from 'redux/store';
import { useIntl } from 'hooks';

export default function FlashMessages() {
  const flashMessages: any[] = useAppSelector(getFlashMessages);
  const dispatch = useAppDispatch();
  const handleRemoveMessage = (id: string) => {
    dispatch(removeMessage(id));
  };
  const { t } = useIntl();

  return (
    <div
      className={clsx('fixed bottom-0 left-0 max-w-full p-2 z-flash', {
        'pointer-events-none': !flashMessages.length,
      })}
    >
      <AnimatePresence>
        {flashMessages.map((msg) => {
          const removeMsg = () => handleRemoveMessage(msg.id);

          return (
            <motion.div
              key={msg.id}
              layout
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.2 }}
              className={clsx(
                'relative p-2 pr-6 text-white shadow-xl w-48 max-w-full rounded mb-1 last:mb-0',
                {
                  'bg-error': msg.isError,
                  'bg-teal': !msg.isError,
                }
              )}
            >
              <span className="font-bold">{t(msg.message)}</span>

              <button onClick={removeMsg} className="absolute top-2.75 right-2">
                <IconClose className="block w-2 h-2" />
              </button>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}
