import { post, get } from './shared';

export function download(
  requestKeys?: string | string[],
  attachmentIds?: number | number[],
  opts: { size?: string; socketId?: string } = {}
) {
  return post('/publisher/downloads', { requestKeys, attachmentIds, ...opts });
}

export function getDownloadUrl(uuid: string) {
  return get<{ url: string }>(`publisher/downloads/${uuid}`);
}
