import PhoneNumber from 'awesome-phonenumber';

export default (input: string) => {
  const phoneNumber = new PhoneNumber(input);
  const regionCode = phoneNumber.getRegionCode();
  const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);

  return {
    countryCode: `+${countryCode}`,
    phoneNumber: input.replace(new RegExp(`^\\+${countryCode}`), ''),
  };
};
