import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import UtilBar from './UtilBar';

interface Props {
  headingId?: string;
  subHeadingId?: string;
}

export default function UnknownError(props: Props) {
  const headingId = props.headingId || 'UnknownError__Heading';
  const errorId = props.subHeadingId || 'UnknownError__SubHeading';
  return (
    <div className="flex flex-col items-center justify-center min-w-full h-screen text-center">
      <UtilBar />
      <div className="pb-24 tablet:pb-8">
        <div className="text-22">
          <FormattedMessage id={headingId} />
        </div>
        <p>
          <FormattedMessage id={errorId} />
        </p>
      </div>
    </div>
  );
}
