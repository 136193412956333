import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, EditContactMethodModal } from 'components/shared';
import NotificationPreferencesType from 'types/NotificationPreferences';
import {
  removeNotificationPreference,
  addNotificationPreference,
} from 'redux/ducks/notificationPreferences';
import { useIntl } from 'hooks';
import { RootState } from 'redux/ducks';
import { OnboardingNotificationIcon } from 'components/Onboarding/components';
import formatPhoneNumber from 'helpers/formatPhoneNumber';
import { FormattedMessage } from 'react-intl';
import EditTwitterDmModal from 'components/shared/EditTwitterDmModal';

interface Props {
  type: keyof NotificationPreferencesType;
  canSendSms?: boolean;
  accountName?: string;
  socialie?: boolean;
}

export default function ManageNotificationsRow({
  type,
  socialie,
  canSendSms,
  accountName,
}: Props) {
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { user, isOn } = useSelector((state: RootState) => ({
    user: state.user.current!,
    isOn: state.notificationPreferences[type],
  }));

  const isPhoneType = ['sms', 'whats_app'].some((t) => t === type);

  let field;
  if (isPhoneType) {
    field = 'phone';
  } else if (type === 'twitter_dm') {
    field = 'twitterUsername';
  } else {
    field = type;
  }

  const fieldValue = user[field];

  let formattedValue = fieldValue;
  if (isPhoneType) {
    formattedValue = formatPhoneNumber(fieldValue || '', 'national');
  } else if (type === 'twitter_dm') {
    formattedValue = `@${fieldValue}`;
  }

  const handleToggle = () => {
    if (fieldValue) {
      if (type === 'sms' && !canSendSms) {
        return;
      } else {
        dispatch(
          isOn
            ? removeNotificationPreference(type)
            : addNotificationPreference(type)
        );
      }
    } else {
      setIsAddContactModalOpen(true);
    }
  };

  return (
    <>
      <div className="flex items-center mb-1.5 rounded border-default bg-white p-2 overflow-hidden">
        <div className="mr-2.5 w-3 h-3 text-lightIcon bump-down-1 flex-shrink-0">
          <OnboardingNotificationIcon type={type} className="w-full h-full" />
        </div>

        <div className="flex-1 pr-2 overflow-hidden">
          <div className="text-18 text-dark">
            {t(`ManageNotifications__Type--${type}`)}
          </div>

          <div className="text-light text-14">
            {fieldValue && type === 'sms' && !canSendSms ? (
              <div className="leading-tight">
                <FormattedMessage
                  id="ManageNotifications__Type--Disabled"
                  values={{
                    accountName: accountName,
                    phone: (
                      <span className="whitespace-nowrap">
                        {formatPhoneNumber(user.phone!, 'international')}
                      </span>
                    ),
                  }}
                />
              </div>
            ) : fieldValue ? (
              <div className="truncate">{formattedValue}</div>
            ) : (
              <button
                onClick={() => setIsAddContactModalOpen(true)}
                className="hover:underline"
              >
                {t(`ManageNotifications__Add--${field}`)}
              </button>
            )}
          </div>
        </div>

        {type === 'sms' && !canSendSms ? null : (
          <div className="flex-shrink-0">
            <Switch isOn={!!fieldValue && !!isOn} onToggle={handleToggle} />
          </div>
        )}
      </div>
      {type === 'twitter_dm' ? (
        <EditTwitterDmModal
          isOpen={isAddContactModalOpen}
          onRequestClose={() => setIsAddContactModalOpen(false)}
        />
      ) : (
        <EditContactMethodModal
          notificationType={type}
          field={field as 'phone' | 'email'}
          isOpen={isAddContactModalOpen}
          onRequestClose={() => setIsAddContactModalOpen(false)}
        />
      )}
    </>
  );
}
