import React, { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

interface Props {
  children: React.ReactNode;
  renderOverflowButton: React.ReactNode;
}

export default function MainTopBarScrollingSubGroup(props: Props) {
  const { children, renderOverflowButton } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const container = containerRef.current;
      const scroller = scrollerRef.current;
      if (!container || !scroller) return;
      const containerWidth = container.clientWidth;
      const scrollerWidth = scroller.scrollWidth;
      if (containerWidth < scrollerWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div ref={containerRef} className="flex-1 overflow-x-auto">
        <ScrollContainer
          innerRef={scrollerRef}
          className="flex items-center gap-1 h-6 pl-2 tablet:pl-0"
        >
          {children}
          {isOverflowing && <div className="w-1 h-px">&nbsp;</div>}
        </ScrollContainer>
      </div>

      {isOverflowing && (
        <div className="border-l-default pl-1.5 pr-2 h-5 py-1">
          {renderOverflowButton}
        </div>
      )}
    </>
  );
}
