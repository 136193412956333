// TODO: Remove after removing social sign in
import Identity from 'types/Identity';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
  name: 'legacyInstagramIdentity',
  initialState: null as Identity | null,
  reducers: {
    fetchedLegacyInstagramIdentity: (state, action: PayloadAction<Identity>) =>
      action.payload,
  },
});

export default reducer;
export const { fetchedLegacyInstagramIdentity } = actions;
