import * as React from 'react';
import { connect } from 'react-redux';
import withoutDialect from 'helpers/withoutDialect';
import { RootState } from 'redux/ducks';
import { changeLanguage } from 'redux/ducks/locales';
import { getAvailableLocales } from 'redux/ducks/suggester';
import { IconCaretFill } from 'icons';
import FlyoutMenu from './FlyoutMenu';

interface ConnectedState {
  currentLocale: string;
  availableLocales: string[];
}

interface ConnectedActions {
  onChangeLocale: (language: string) => any;
}

interface State {
  isMenuOpen: boolean;
}

type Props = ConnectedState & ConnectedActions;

const localeNames = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  pt: 'Português',
};

class LocaleSwitcher extends React.Component<Props, State> {
  state = { isMenuOpen: false };

  onToggleMenu = () => {
    this.setState((state) => ({ isMenuOpen: !state.isMenuOpen }));
  };

  onCloseMenu = () => this.setState({ isMenuOpen: false });

  onSelectLocale = (locale: string) => {
    this.props.onChangeLocale(locale);
    this.onCloseMenu();
  };

  render() {
    const { currentLocale, availableLocales } = this.props;

    if (!availableLocales.length || availableLocales.length < 2) return null;

    return (
      <FlyoutMenu
        placement="bottom-end"
        renderButton={(onClick, isOpen) => {
          const btnClass = isOpen ? 'bg-grey7' : '';
          const caretClass = isOpen ? 'rotate-180' : 'rotate-0';
          return (
            <button
              onClick={onClick}
              className={`rounded-lg text-dark text-14 p-1 pl-1.5 py-0.75 ${btnClass}`}
            >
              <span className="flex items-center">
                {localeNames[withoutDialect(currentLocale)]}
                <IconCaretFill
                  className={`w-1 h-1 ml-1 bump-down-1 transform origin-center ${caretClass}`}
                />
              </span>
            </button>
          );
        }}
      >
        {availableLocales.map((locale) => (
          <FlyoutMenu.Item
            key={locale}
            onClick={() => this.onSelectLocale(locale)}
            isActive={currentLocale === locale}
          >
            {localeNames[locale]}
          </FlyoutMenu.Item>
        ))}
      </FlyoutMenu>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  currentLocale: state.locales.lang,
  availableLocales: getAvailableLocales(state),
});

const mapDispatchToProps = {
  onChangeLocale: changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocaleSwitcher);
