import { AthleteOptIn } from 'components/shared';
import routes from 'helpers/routes';
import { useCurrentPublisher } from 'hooks';
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import useIsMobile from 'hooks/useIsMobile';
import confetti from 'images/confetti.png';

export default function AthleteOptInModal() {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const publisher = useCurrentPublisher();
  const { isMobile } = useIsMobile();
  const lastWeek = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
  const isEligible =
    !!publisher && !publisher.athlete && !!publisher.athleteEligible;
  const isInitialOpen =
    isEligible &&
    (!publisher?.athleteDismissedAt || lastWeek > publisher.athleteDismissedAt);
  const [isOpen, setIsOpen] = useState(isInitialOpen);
  const history = useHistory();

  if (!publisher) return null;

  const variants = {
    hidden: isMobile ? { y: '100vh' } : { opacity: 0 },
    show: { y: '0', opacity: 1 },
    exit: {
      ...(isMobile ? { y: '100vh' } : { opacity: 0 }),
      transition: { delay: 0 },
    },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={overlayVariants}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="fixed inset-0 z-50 pt-4 bg-black bg-opacity-80 tablet:p-4"
        >
          <motion.div
            ref={contentRef}
            variants={variants}
            initial="hidden"
            exit="exit"
            animate="show"
            transition={{
              type: 'tween',
              ease: 'easeOut',
              duration: 0.25,
              delay: isMobile ? 0.5 : 0,
            }}
            className="relative w-full h-full bg-white rounded-t-xl p-4 pt-20 flex flex-col tablet:rounded-xl overflow-hidden"
          >
            <div
              className="absolute pointer-events-none top-0 transform -translate-x-5 -translate-y-3 w-screen
              phablet:-translate-y-10
              tablet:left-1/2 tablet:-translate-y-23 tablet:-translate-x-50 tablet:w-full tablet:max-w-100"
            >
              <img src={confetti} alt="Confetti" className="w-full" />
            </div>

            <div className="tablet:max-w-90 tablet:mx-auto tablet:pr-30">
              <AthleteOptIn
                publisher={publisher}
                onOptIn={() => {
                  setIsOpen(false);
                  history.push(routes.athlete.root);
                }}
                allowDismiss
                onDismiss={() => setIsOpen(false)}
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
