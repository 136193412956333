import React, { useState, useEffect } from 'react';
import { clsx } from 'clsx';
import { IconLoader } from 'icons';

interface Props {
  delay?: number;
  sizeClassNames?: string;
}

export default function LoadingSpinner({ delay, sizeClassNames }: Props) {
  const [isVisible, setIsVisible] = useState(!delay);

  useEffect(() => {
    if (!delay) return;
    const timeout = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <IconLoader
      className={clsx(
        'block mx-auto transition duration-500 text-light',
        sizeClassNames,
        {
          'opacity-0': !isVisible,
        }
      )}
    />
  );
}

LoadingSpinner.defaultProps = {
  sizeClassNames: 'w-6 h-6',
};
