import { Modal, WithIntl } from 'components/shared';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'redux/ducks';
import { acceptEula } from 'redux/ducks/user';
import User from 'types/User';

interface ConnectedState {
  user: User | null;
  isAccountConversionOpen: boolean;
}

interface ConnectedActions {
  acceptEula: () => void;
}

type Props = ConnectedState & ConnectedActions;

interface State {
  isOpen: boolean;
  isSaving: boolean;
}

class EULAModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      isOpen: this.getOpenState(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.user && !!this.props.user) {
      this.setState({ isOpen: this.getOpenState() });
    }
  }

  getOpenState = () => {
    const { user } = this.props;
    return !!user && !user.acceptedLatestEula;
  };

  acceptEula = async () => {
    this.setState({ isSaving: true });
    await this.props.acceptEula();
    this.setState({ isSaving: false });
    if (this.props.user?.acceptedLatestEula) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { isOpen, isSaving } = this.state;
    const { isAccountConversionOpen } = this.props;

    return (
      <WithIntl>
        {(_, t) => (
          <Modal
            isOpen={isOpen && !isAccountConversionOpen}
            theme="bottom"
            contentLabel={t('EULAModal__Heading')}
            renderHeading={t('EULAModal__Heading')}
            onConfirm={this.acceptEula}
            confirmText={t('EULAModal__Accept')}
            isConfirmLoading={isSaving}
          >
            <div data-testid="eula-modal">
              <div className="modal__message">
                <FormattedMessage
                  id="EULAModal__Description"
                  values={{
                    eulaLink: (
                      <a
                        href="http://socialie.com/terms-and-conditions/"
                        className="text-socialiePink"
                        style={{ textDecoration: 'underline' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="EULA__EULA" />
                      </a>
                    ),
                    privacyPolicyLink: (
                      <a
                        href="http://socialie.com/privacy-policy/"
                        className="text-socialiePink"
                        style={{ textDecoration: 'underline' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="Global__PrivacyPolicy" />
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </WithIntl>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user.current,
  isAccountConversionOpen: state.ui.accountConversion.isOpen,
});

const mapDispatchToProps = { acceptEula };

export default connect(mapStateToProps, mapDispatchToProps)(EULAModal);
