import * as api from 'api/brands';
import { useIntl } from 'hooks';
import { IconAdd } from 'icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/ducks';
import Btn from './Btn';
import Form from './Form';
import Modal from './modals/Modal';

interface Props {
  renderButton?: (onClick: () => void) => React.ReactNode;
}

export default function AddAccountModal(props: Props) {
  const { renderButton } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useIntl();
  const currentUrl = useSelector(
    (state: RootState) => state.brand && state.brand.current
  );
  const [brand, setBrand] = useState(currentUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [is404, setIs404] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setIs404(false);
    setBrand(e.target.value);
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!brand) return;
    setIsLoading(true);
    setIsError(false);
    setIs404(false);

    try {
      const result = await api.checkBrand(brand);
      if (result.exists) {
        const { host, protocol } = window.location;
        const newHost = host.replace(/^[A-Za-z0-9]+\./, `${brand}.`);
        window.location.href = `${protocol}//${newHost}/get-started`;
      } else {
        setIs404(true);
        setIsLoading(false);
      }
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      {renderButton ? (
        renderButton(() => setIsOpen(true))
      ) : (
        <Btn leftIcon={IconAdd} onClick={() => setIsOpen(true)}>
          {t('AddAccountModal__Button')}
        </Btn>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t('AddAccountModal__Heading')}
        contentLabel={t('AddAccountModal__Heading')}
        onConfirm={() => handleSubmit()}
        confirmBtnProps={{ isLoading, disabled: !brand }}
        onCancel={() => setIsOpen(false)}
        theme="bottom"
      >
        <div className="mb-3.5">{t('AddAccountModal__Subheading')}</div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <div className="max-w-25">
                <Form.TextInput
                  type="text"
                  isInvalid={is404}
                  value={brand}
                  onChange={handleChange}
                  className="text-center"
                  placeholder={t('AddAccountModal__Placeholder')}
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <span className="text-dark">.socialie.com</span>
            </div>

            {(isError || is404) && (
              <div className="mb-4 min-h-4 text-error">
                {t(isError ? 'AddAccountModal__Error' : 'AddAccountModal__404')}
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
}
