import { combineReducers } from '@reduxjs/toolkit';
import routes from 'helpers/routes';
import flagsReducer, { fetchFlags, setFlags } from './flags';
import inviteCodeReducer, {
  updateInviteCode,
  checkInviteCode,
} from './inviteCode';

// Reducer
export interface State {
  flags: ReturnType<typeof flagsReducer>;
  inviteCode: ReturnType<typeof inviteCodeReducer>;
}

export default combineReducers({
  inviteCode: inviteCodeReducer,
  flags: flagsReducer,
});

// Action Creators
export { updateInviteCode, checkInviteCode, setFlags, fetchFlags };

// Selectors
const getPages = (state: { onboarding: State }) => {
  const { flags } = state.onboarding;
  const pages = [routes.onboarding.getStarted];
  pages.push(routes.onboarding.user);
  pages.push(routes.onboarding.secondaryContact);
  if (flags.didSignUp) pages.push(routes.onboarding.newUserSplash);
  if (flags.message) pages.push(routes.onboarding.message);
  pages.push(routes.onboarding.publisherList);
  pages.push(routes.onboarding.linkAccounts);
  pages.push(routes.onboarding.notifications);
  pages.push(routes.onboarding.complete);
  return pages;
};

export const getNextPage = (
  state: { onboarding: State },
  currentPage: string
) => {
  const pages = getPages(state);
  const currentPageIdx = pages.indexOf(currentPage);
  return pages[currentPageIdx + 1];
};

export const getPrevPage = (
  state: { onboarding: State },
  currentPage: string
) => {
  const pages = getPages(state);
  const currentPageIdx = pages.indexOf(currentPage);
  return pages[currentPageIdx - 1];
};
