import NoContentCTA from './NoContentCTA';
import { useIntl } from 'hooks';
import { IconErrorPage, IconRefreshAlt } from 'icons';
import Btn from './Btn';
import { useEffect } from 'react';
import trackCustomEvent from 'helpers/trackCustomEvent';

export default function ErrorPage() {
  const { t } = useIntl();
  useEffect(() => {
    trackCustomEvent('sawErrorPage');
  }, []);
  return (
    <div className="w-60 max-w-[90%] mx-auto pt-8">
      <NoContentCTA
        heading={t('UnknownError__Heading')}
        subheading={t('UnknownError__SubHeading')}
        icon={IconErrorPage}
        renderButton={
          <Btn
            onClick={() => window.location.reload()}
            leftIcon={IconRefreshAlt}
            variant="inlineBlock"
          >
            {t('Button__Reload')}
          </Btn>
        }
      />
    </div>
  );
}
