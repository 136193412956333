import * as React from 'react';
import { connect } from 'react-redux';
import Suggester from 'types/Suggester';
import Identity from 'types/Identity';
import Publisher from 'types/Publisher';
import { RootState } from 'redux/ducks';
import { fetchCurrentPublisher } from 'redux/ducks/publisher';
import { LoadingPage } from 'components/shared';

interface RenderFuncValues {
  publisher: Publisher;
  suggester: Suggester;
  identities: { [key: string]: Identity };
}

interface OwnProps {
  render: (values: RenderFuncValues) => React.ReactNode;
}

interface ConnectedState {
  publisher: Publisher | null;
  identities: { [key: string]: Identity };
  suggester: Suggester | null;
}

interface ConnectedActions {
  onFetchCurrentPublisher: Function;
}

type Props = OwnProps & ConnectedState & ConnectedActions;

class AuthPage extends React.Component<Props> {
  /**
   * Need to fetch publisher data if the object is undefined or if the
   * loginRequired property is missing (as in the PageSelector use case
   * @returns {boolean}
   */
  needsPublisher(): boolean {
    const { publisher } = this.props;
    return !publisher || publisher.loginRequired === undefined;
  }

  componentDidMount() {
    if (this.needsPublisher()) {
      this.props.onFetchCurrentPublisher();
    }
  }

  render() {
    const { publisher, render, identities, suggester } = this.props;
    if (!suggester || !publisher || this.needsPublisher()) {
      return <LoadingPage />;
    }

    return render({ publisher, identities, suggester });
  }
}

const mapStateToProps = (state: RootState) => ({
  publisher: state.publisher,
  identities: state.identities,
  suggester: state.suggester,
});

const mapDispatchToProps = {
  onFetchCurrentPublisher: fetchCurrentPublisher,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
