import React from 'react';

export const ScrollToTop = () => {
  if (typeof window.scrollTo === 'function') window.scrollTo(0, 0);
};

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    ScrollToTop();
  }

  render() {
    return null;
  }
}

export default ScrollToTopOnMount;
