import { ReactComponent as IconFacebookFull } from './facebook-full.svg';
import { ReactComponent as IconInstagram } from './instagram.svg';
import { ReactComponent as IconLinkedIn } from './linkedin.svg';
import { ReactComponent as IconTwitter } from './twitter.svg';

export default {
  twitter: IconTwitter,
  facebook: IconFacebookFull,
  instagram: IconInstagram,
  instagram_basic: IconInstagram,
  instagram_graph: IconInstagram,
  linkedin: IconLinkedIn,
};
