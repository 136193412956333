// Action Types
export enum TypeKeys {
  OTHER = 'publisher/global/OTHER',
}

export interface OtherAction {
  type: TypeKeys.OTHER;
}

export type ActionTypes = OtherAction;

// Reducer
export interface State {
  error?: string;
}

export default (state: State = {}, action: ActionTypes) => {
  return state;
};
