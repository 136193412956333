import castArray from 'lodash/castArray';
import React from 'react';

interface Props {
  errors: string[] | string;
}

export default function Errors({ errors }: Props) {
  return (
    <div className="text-12 pt-0.5 text-error">
      {castArray(errors).map((error, i) => (
        <div key={i}>{error}</div>
      ))}
    </div>
  );
}
