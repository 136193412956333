import { signedS3Request, SignedS3RequestInit } from 'api/attachments';
import {
  s3FilePartSize,
  S3MultipartUploader,
  STATUS,
} from 'helpers/upload/S3MultipartUploader';
import * as SparkMD5 from 'spark-md5';
import { FilePartUploader } from 'helpers/upload/FilePartUploader';

export const maxUploadAttempts = 20;
const maxRetryWaitSeconds = 300;
const retryBackOffPower = 2;

export class FilePart {
  resolve: (result: any) => void = () => {};
  reject: (reason: any) => void = () => {};
  /**
   * Starting position of this file part
   */
  start: number;
  /**
   * Ending position of this file part
   */
  end: number;
  eTag: string | null = null;
  partUploader: FilePartUploader | null = null;
  uploadAttempts = 0;

  constructor(
    public uploader: S3MultipartUploader,
    public partNumber: number,
    public status: string
  ) {
    this.start = (partNumber - 1) * s3FilePartSize;
    this.end = Math.min(partNumber * s3FilePartSize, uploader.file.size);
  }

  upload(): Promise<any> {
    if (this.status === STATUS.CANCELED) {
      return Promise.reject(STATUS.CANCELED);
    }
    this.status = STATUS.UPLOADING;

    return new Promise(async (resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      this.startUpload();
    });
  }

  async startUpload() {
    const { partNumber, uploader } = this;
    const { uploadId, file, mediaId } = uploader;
    if (!mediaId) throw new Error('uploader.mediaId cannot be null');

    this.uploadAttempts += 1;
    const payload = await this.getPayloadFromBlob();
    const md5Digest = btoa(SparkMD5.ArrayBuffer.hash(payload, true));
    const options: SignedS3RequestInit = {
      mediaId,
      md5Digest,
      requestType: 'upload_part',
      contentType: file.type,
      queryParams: `partNumber=${partNumber}&uploadId=${uploadId}`,
    };

    let json;

    try {
      json = await signedS3Request(options);
    } catch (error) {
      this.onUploadError(error);
      return;
    }

    // xhr's refuse to set unsafe header 'host'
    delete json.headers['host'];
    json.headers['Content-Type'] = file.type;
    json.headers['Content-MD5'] = md5Digest;

    if (!this.partUploader) {
      this.partUploader = new FilePartUploader(this);
    }

    this.partUploader
      .upload(json.url, json.headers, payload)
      .then(result => this.onPartUploaded(result))
      .catch(reason => this.onUploadError(reason));
  }

  onPartUploaded(eTag: string) {
    this.status = STATUS.COMPLETE;
    this.eTag = eTag;
    this.resolve(eTag);
  }

  onUploadError(reason: any) {
    const { status, uploadAttempts } = this;
    let reject = false;
    if (status === STATUS.CANCELED) {
      reject = true;
    } else if (uploadAttempts > maxUploadAttempts) {
      this.status = STATUS.ERROR;
      // console.log(`FilePart: part ${partNumber} upload error: ${reason}`);
      reject = true;
    }

    reject ? this.reject(reason) : this.retryUpload();
  }

  cancelUpload() {
    if (this.status === STATUS.UPLOADING && this.partUploader) {
      this.partUploader.xhr.abort();
    }
    this.status = STATUS.CANCELED;
  }

  async getPayloadFromBlob(): Promise<any> {
    // browsers' implementation of the Blob.slice function has been renamed a couple of times, and the meaning of the
    // 2nd parameter changed. For example Gecko went from slice(start,length) -> mozSlice(start, end) -> slice(start, end).
    // As of 12/12/12, it seems that the unified 'slice' is the best bet, hence it being first in the list. See
    // https://developer.mozilla.org/en-US/docs/DOM/Blob for more info.
    const file = this.uploader.file;
    const slicerFn = file.slice
      ? 'slice'
      : file['mozSlice']
      ? 'mozSlice'
      : 'webkitSlice';
    const blob = file[slicerFn](this.start, this.end);
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = function() {
        const isBuffer = typeof this.result !== 'string';
        const result = isBuffer
          ? new Uint8Array(this.result as ArrayBuffer)
          : this.result;
        resolve(result);
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  retryUpload() {
    const { uploader, partUploader } = this;
    if (!partUploader) return;
    const wait = this.retryWaitTime();
    // console.log(`FilePart: part ${partNumber} retrying in ${wait} ms`);
    uploader.updateBytesLoaded(-partUploader.bytesUploaded);
    setTimeout(() => {
      this.startUpload();
    }, wait);
  }

  retryWaitTime() {
    const { uploadAttempts } = this;
    if (uploadAttempts === 1) {
      return 0;
    }

    return (
      1000 *
      Math.min(
        maxRetryWaitSeconds,
        Math.pow(retryBackOffPower, uploadAttempts - 2)
      )
    );
  }
}
