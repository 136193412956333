import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'api/user';
import camelize from 'camelize';
import { Btn, LoadingPage } from 'components/shared';
import isValidContactValue from 'helpers/isValidContactValue';
import routes from 'helpers/routes';
import trackCustomEvent from 'helpers/trackCustomEvent';
import { useIntl } from 'hooks';
import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from 'redux/ducks';
import { updatedUser } from 'redux/ducks/user';
import Form from './Form';
import PhoneInput from './PhoneInput';

interface Props {
  onSuccess: () => void;
}

const initialState = {
  value: '',
  validationMsg: '',
  isLoading: false,
  isError: false,
};

const { reducer, actions } = createSlice({
  name: 'AddContactMethodReducer',
  initialState,
  reducers: {
    saveStart: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.validationMsg = '';
    },
    saveInvalid: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.validationMsg = action.payload;
    },
    saveError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    saveSuccess: (state) => {
      state.value = '';
      state.isLoading = false;
    },
    setValue: (state, action: PayloadAction<string>) => {
      state.validationMsg = '';
      state.value = action.payload;
    },
  },
});

export default function AddSecondaryContactForm(props: Props) {
  const { onSuccess } = props;
  const { t } = useIntl();
  const { user, suggester } = useSelector((state: RootState) => ({
    user: state.user.current,
    suggester: state.suggester,
  }));

  if (!suggester) {
    throw new Error('AddSecondaryContactForm: Suggester must be present');
  }

  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, initialState);

  const phone = user?.phone;
  const email = user?.email;

  useEffect(
    () => {
      if (phone && email) {
        onSuccess();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone, email]
  );

  if (!user) return <Redirect to={routes.accountConversion.root} />;

  if (phone && email) return <LoadingPage />;

  const field = user?.email ? 'phone' : 'email';

  const isRequired =
    (field === 'phone' && suggester.config.requirePublisherPhone) ||
    (field === 'email' && suggester.config.requirePublisherEmail);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(actions.saveStart());
    const sanitizedValue = state.value.trim();
    if (!isValidContactValue(sanitizedValue, field as 'email' | 'phone')) {
      dispatch(actions.saveInvalid(t(`Validation__InvalidFormat--${field}`)));
      return;
    }

    try {
      const params = { [field]: sanitizedValue };
      const data = camelize(await api.updateUser(params));
      if ('user' in data) {
        reduxDispatch(updatedUser(params));
        onSuccess();
      } else if (data.errors && data.errors[field]) {
        if (data.errors[field]?.[0]?.match(/AlreadyExists/)) {
          trackCustomEvent('contactValueExists', {
            context: 'SecondaryContact',
            value: sanitizedValue,
          });
        }
        dispatch(actions.saveInvalid(t(data.errors[field]!)));
      } else {
        // something weird happened
        dispatch(actions.saveError());
      }
    } catch (e) {
      console.error(e);
      dispatch(actions.saveError());
    }
  };

  const handleChange = (value: string) => dispatch(actions.setValue(value));

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <h3 className="h3">
          {t(`AddSecondaryContactForm__Heading--${field}`, {
            accountName: suggester.accountName,
          })}
        </h3>

        <div className="subheading">
          {t(`AddSecondaryContactForm__SubHeading--${field}`)}
        </div>
      </div>
      <div className="mb-1.5">
        {field === 'email' ? (
          <Form.TextInput
            value={state.value}
            type={field === 'email' ? 'email' : 'text'}
            onChange={(e) => handleChange(e.target.value)}
            autoFocus
            isInvalid={!!state.validationMsg}
            placeholder={t(`AddSecondaryContactForm__Placeholder--${field}`)}
            skipField
          />
        ) : (
          <PhoneInput
            value={state.value}
            onChange={handleChange}
            forceWorldwide
            isInvalid={!!state.validationMsg}
          />
        )}

        {!!state.validationMsg && (
          <div className="text-error text-14 text-left">
            {state.validationMsg}
          </div>
        )}
      </div>

      <Btn
        className="mb-2"
        type="submit"
        disabled={!state.value}
        isLoading={state.isLoading}
      >
        {t('Button__Continue')}
      </Btn>

      {!isRequired && (
        <div className="text-center">
          <button
            type="button"
            disabled={state.isLoading}
            onClick={onSuccess}
            className="inline-block text-light"
          >
            {t('Button__Skip')}
          </button>
        </div>
      )}

      {state.isError && (
        <div className="text-error text-14 text-center">
          {t('Global__UnexpectedError')}
        </div>
      )}
    </form>
  );
}
