import './sentry';
import './polyfills';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'focus-visible';
import 'polyfill-array-includes';
import App from './components/App';
import { unregister } from './registerServiceWorker';
import 'react-datepicker/dist/react-datepicker.css';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/tailwind-build.css';
import queryString from 'query-string';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';

// Hard redirect to new publisher code URL
const parsed = queryString.parse(window.location.search);
if (parsed.code) {
  window.location.href = routeFor(routes.publisherCode, parsed.code);
} else if (parsed.pages === 'multiple') {
  window.location.replace(routes.auth.facebookPageLogin);
}

const rootEl = document.getElementById('root') as HTMLElement;
ReactDOM.render(<App />, rootEl);

const FS = window['FS'];

if (FS) {
  window['_fs_ready'] = function () {
    // const sessionUrl = FS.getCurrentSessionURL();
    // TODO: figure out how to pass the FS url to Sentry here
  };
}

// This was added on Mar 13 and can be removed after we think most of the users
// have unregistered the service worker
unregister();
