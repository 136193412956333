import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IconClose, IconCaretRight } from 'icons';
import { FormattedMessage } from 'react-intl';
import camelize from 'camelize';
import PublisherAccount from 'types/PublisherAccount';
import routes from 'helpers/routes';
import { LoadingPage, PublisherListRow } from 'components/shared';
import { linkedPublishers as getLinkedPublishers } from 'api/facebook';
import changeAccount from 'helpers/auth/changeAccount';

type Props = {} & RouteComponentProps<any>;

interface State {
  linkedAccounts: PublisherAccount[];
}

export class FacebookPageLogin extends React.Component<Props, State> {
  state: State = { linkedAccounts: [] };

  componentDidMount() {
    this.getAccounts();
  }

  async getAccounts() {
    const json = await getLinkedPublishers();
    const accounts = camelize(json.publishers);

    if (accounts) {
      this.setState({ linkedAccounts: accounts });
    }
  }

  async selectAccount(publisher: PublisherAccount) {
    const account = { publisherId: publisher.id, brand: publisher.brand };
    changeAccount(account);
  }

  onCompleted = () => {
    this.props.history.push(routes.feeds.inbox);
  };

  render() {
    const { linkedAccounts } = this.state;
    if (linkedAccounts.length === 0) return <LoadingPage />;

    return (
      <div className="container h-full text-center pt-5.5 tablet:pt-10.5">
        <div className="fixed top-0 left-0 right-0 z-[1000] border-b-default bg-white h-5.5 text-center text-dark tablet:h-7.5">
          <div className="flex items-center justify-center relative mx-auto px-2 max-w-120 h-full">
            <button
              className="flex items-center justify-center absolute top-2 left-2 w-2 h-2 text-light tablet:top-3"
              onClick={this.onCompleted}
            >
              <IconClose className="block w-full h-full" />
            </button>
            <FormattedMessage id="FacebookPageLogin__Header" />
          </div>
        </div>

        <div className="overflow-hidden rounded mb-1 bg-white pb-4">
          <div className="section section--cta">
            <div>
              {linkedAccounts.map((account, index) => (
                <PublisherListRow
                  key={account.id}
                  onClick={() => this.selectAccount(account)}
                  isCurrentPublisher={false}
                  publisher={account}
                  renderActions={
                    <IconCaretRight className="w-2 h-2 text-lightIcon" />
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
