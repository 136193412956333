import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import * as api from 'api/invitations';
import { RootState } from '.';
import camelize from 'camelize';
import Invitation from 'types/Invitation';
import { logInUser } from './user';
import rejectThunkApiError from 'helpers/rejectThunkApiError';
import urlFor from 'helpers/routeFor/urlFor';
import routes from 'helpers/routes';

// asyncThunks
export const fetchInvitation = createAsyncThunk(
  'invitations/fetch',
  async (code: string, { rejectWithValue }) => {
    try {
      const data = camelize(await api.fetchInvitation(code));

      // It's a valid code but not for this subdomain, redirect to the
      // same path on the correct domain
      if ('redirectBrand' in data) {
        window.location.href = urlFor(
          data.redirectBrand,
          routes.invitation.root,
          code
        );
        return rejectWithValue('wrong_suggester');
      }
      return data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e);
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  'invitations/accept',
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const data = camelize(await api.acceptInvitation(code));
      dispatch(logInUser(data));
      return code;
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

// Reducer
const adapter = createEntityAdapter<Invitation>();

const { reducer } = createSlice({
  name: 'invitations',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchInvitation.fulfilled, adapter.upsertOne);
    builder.addCase(acceptInvitation.fulfilled, (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.code,
        changes: { pending: false },
      });
    });
  },
});

export default reducer;

// Selectors
const selectors = adapter.getSelectors();

export const getInvitation = (state: RootState, id: string) =>
  selectors.selectById(state.invitations, id);
