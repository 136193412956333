import React from 'react';
import PublisherAccount from 'types/PublisherAccount';
import { useIntl } from 'hooks';
import clsx from 'clsx';
import Avatar from '../Avatar';

interface Props {
  publisher: PublisherAccount;
  renderActions: React.ReactNode;
  isCurrentPublisher?: boolean;
  onClick?: React.ComponentProps<'button'>['onClick'];
  disabled?: boolean;
}

export default function PublisherListRow(props: Props) {
  const { t } = useIntl();
  const {
    publisher,
    renderActions,
    isCurrentPublisher,
    onClick,
    disabled,
  } = props;

  return React.createElement(
    onClick ? 'button' : 'div',
    {
      className: clsx(
        'account-card w-full mb-2',
        !isCurrentPublisher && 'account-card--inactive'
      ),
      onClick,
      disabled,
    },
    <span className="flex items-center">
      <div className="flex-shrink-0 rounded-full mr-3 bg-greyBg">
        <Avatar
          imageUrl={publisher.image}
          name={publisher.name}
          logoUrl={publisher.logoUrl}
        />
      </div>

      <div className="flex-1 mr-2 overflow-hidden">
        <div className="text-14 text-dark truncate">{publisher.name}</div>
        <div className="flex items-center">
          <div className="text-light text-14 bump-up-1 truncate">
            {publisher.suggesterName}
          </div>
        </div>
      </div>

      <div className="ml-auto flex items-center flex-shrink-0">
        {isCurrentPublisher && (
          <div className="pill bg-grey7">{t('PublisherSelect__Current')}</div>
        )}

        {renderActions}
      </div>
    </span>
  );
}
