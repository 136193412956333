import { useEffect, useRef } from 'react';

export default function useBodyClassName(className: string) {
  const classNames = className.split(' ');
  const prevClassNames = useRef(classNames);

  useEffect(() => {
    prevClassNames.current.forEach((className) => {
      document.body.classList.remove(className);
    });

    classNames.forEach((className) => {
      document.body.classList.add(className);
    });

    prevClassNames.current = classNames;
    return () => {
      classNames.forEach((className) => {
        if (document.body.classList.contains(className)) {
          document.body.classList.remove(className);
        }
      });
    };
  }, [classNames]);
}
