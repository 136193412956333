import * as React from 'react';
import Loader from './Loader';

interface Props {
  message?: string;
  theme?: 'modal';
}

export default function LoadingPage({ message, theme }: Props) {
  return <Loader initialLoad />;
}
