export default function getMediaDimensionsForContainer(
  mediaDimensions: { width: number; height: number },
  containerDimensions: { width: number; height: number }
) {
  const containerRatio = getRatio(containerDimensions);
  const mediaRatio = getRatio(mediaDimensions);
  if (mediaRatio > containerRatio) {
    return {
      width: containerDimensions.width,
      height: containerDimensions.width / mediaRatio,
    };
  }
  return {
    width: mediaRatio * containerDimensions.height,
    height: containerDimensions.height,
  };
}

function getRatio(dimensions: { width: number; height: number }) {
  return dimensions.width / dimensions.height;
}
