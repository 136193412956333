import * as React from 'react';

export default () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icon-asterisk</title>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M3.571 5.572L0 4.57l.642-1.997L4.143 4 3.93 0h2.215l-.215 4.001 3.43-1.359L10 4.715l-3.571 1 2.357 2.999L7.001 10 4.927 6.713 2.93 9.858 1.144 8.644z"
    />
  </svg>
);
