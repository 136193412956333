import allCountryCodes from './allCountryCodes';

export interface Option {
  countryCode?: string;
  value: string;
  label: string;
}

export interface GroupedOption {
  label: string;
  options: Option[];
}

export default (
  defaultCountryCodes: Option[],
  countryCodeWhitelist: string[],
  allowWorldwideSms?: boolean
) => {
  if (!allowWorldwideSms) return defaultCountryCodes;

  if (!!countryCodeWhitelist.length) {
    return countryCodeWhitelist
      .map(countryCode => allCountryCodes.find(c => c.value === countryCode))
      .filter(opt => !!opt) as Option[];
  }

  return [
    { label: 'Default', options: defaultCountryCodes },
    { label: 'All Countries', options: allCountryCodes },
  ] as GroupedOption[];
};
