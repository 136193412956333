import { injectIntl, InjectedIntlProps, InjectedIntl } from 'react-intl';

type TranslateFunction = (id: string, values?: any) => string;

interface OwnProps {
  render?: (intl: InjectedIntl, t: TranslateFunction) => any;
  children?: (intl: InjectedIntl, t: TranslateFunction) => any;
}

type Props = OwnProps & InjectedIntlProps;

const WithIntl = (props: Props) => {
  const renderFunc = props.render || props.children;
  if (!renderFunc) return null;

  const t = (id: string, values?: any) =>
    props.intl.formatMessage({ id }, values);

  return renderFunc(props.intl, t);
};

export default injectIntl<OwnProps>(WithIntl);
