import { post, get } from './shared';
import ContentRequestSubmission from 'types/ContentRequestSubmission';

export const fetchContentRequestSubmissions = async (
  page: number,
  requestId?: number
) => {
  let endpoint = `publisher/content_request_submissions?page=${page}`;
  if (requestId) endpoint = endpoint + `&request_id=${requestId}`;
  return await get(endpoint);
};

export const create = async (requestKey: string, comments?: string) => {
  const endpoint = 'publisher/content_request_submissions';
  return post(endpoint, {
    requestKey,
    comments: comments || null,
  });
};

export const createBulk = async (count: number, requestKey: string) => {
  const endpoint = 'publisher/content_request_submissions/create_bulk';
  return post(endpoint, { count, requestKey });
};

export const update = (
  id: number,
  requestKey: string,
  updates: Partial<ContentRequestSubmission>
) => {
  const endpoint = `publisher/content_request_submissions/update/${id}`;
  return post(endpoint, { requestKey, ...updates }, 'PUT');
};

export const remove = (id: number, requestKey: string) => {
  const endpoint = `publisher/content_request_submissions/${id}`;
  return post(endpoint, { requestKey }, 'DELETE');
};

export const submitAll = (requestKey: string) => {
  const endpoint = `publisher/content_request_submissions/submit_all`;
  return post(endpoint, { requestKey });
};
