import React from 'react';
import store from 'redux/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { captureException } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { InjectIntlContext } from 'hooks/useIntl';
import { connect as connectSocket } from 'redux/ducks/socket';
import ConnectedIntlProvider from 'helpers/ConnectedIntlProvider';
import Layout from './Layout';
import { IsMobileProvider } from 'hooks/useIsMobile';
import { GodModeProvider } from 'hooks/useGodMode';

store.dispatch<any>(connectSocket());

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: (e) => {
        console.error(e);
        captureException(e);
      },
    },
    mutations: {
      onError: (e) => {
        captureException(e);
        console.error(e);
      },
    },
  },
});

export default class App extends React.Component {
  render() {
    return (
      <GodModeProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store as any}>
            <HelmetProvider>
              <ConnectedIntlProvider>
                <InjectIntlContext>
                  <IsMobileProvider>
                    <Layout />
                  </IsMobileProvider>
                </InjectIntlContext>
              </ConnectedIntlProvider>
            </HelmetProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GodModeProvider>
    );
  }
}
