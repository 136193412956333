// Take an array of objects and return them as a single object keyed by the
// platform id.
//
// eg. [{ id: 1, platformId: 'twitter'}, { id: 2, platformId: 'facebook'}]
// becomes
//  {
//    twitter: { id: 1, platformId: 'twitter' },
//    facebook: { id: 2, platformId: 'facebook' },
//  }
export default function byPlatformId<T>(
  objects: T[],
  platformIdKey: keyof T,
  overwriteFunc: (incoming: T, existing: T) => boolean = () => true
) {
  const byPlatformId: { [key: string]: T } = {};
  if (objects) {
    objects.forEach((obj) => {
      let platformId = obj[platformIdKey];

      if (!platformId) return;
      if (typeof platformId !== 'string') {
        throw new Error(`obj[${platformIdKey}] must return a string`);
      }

      const existing = byPlatformId[platformId];
      if (existing && !overwriteFunc(obj, existing)) return;
      byPlatformId[platformId] = obj;
    });

    // If there's an instagram_basic account, make it supersede the instagram
    // account.  If there's an instagram_graph account, make it supersede both
    if (byPlatformId['instagram_basic']) {
      byPlatformId['instagram'] = byPlatformId['instagram_basic'];
      delete byPlatformId['instagram_basic'];
    }

    if (byPlatformId['instagram_graph']) {
      byPlatformId['instagram'] = byPlatformId['instagram_graph'];
      delete byPlatformId['instagram_graph'];
    }
  }
  return byPlatformId;
}
