import remove from 'lodash/remove';
import { S3MultipartUploader } from 'helpers/upload/S3MultipartUploader';
import { TypeKeys, startUpload } from 'redux/ducks/fileUploads';

interface UploadingItem {
  uploadId: string;
  uploader: S3MultipartUploader;
}

const MAX_UPLOADS = 2;
const uploadQueue: UploadingItem[] = [];
const uploadsInProgress: UploadingItem[] = [];

export const fileUploadMiddleware = (store: any) => {
  const startOrQueueUpload = (item: UploadingItem) => {
    if (uploadsInProgress.length < MAX_UPLOADS) {
      uploadsInProgress.push(item);
      store.dispatch(startUpload(item.uploadId));
      item.uploader.upload();
    } else {
      uploadQueue.push(item);
    }
  };

  const startNextUpload = () => {
    const nextItem = uploadQueue.shift();
    if (nextItem) {
      uploadsInProgress.push(nextItem);
      store.dispatch(startUpload(nextItem.uploadId));
      nextItem.uploader.upload();
    }
  };

  const finishUpload = (uploadId: string) => {
    remove(uploadsInProgress, item => item.uploadId === uploadId);
    startNextUpload();
  };

  const cancelUpload = (uploadId: string) => {
    const removed = remove(
      uploadsInProgress,
      item => item.uploadId === uploadId
    );
    removed.forEach(item => {
      item.uploader.cancelUpload();
      startNextUpload();
    });
  };

  return (next: any) => {
    return (action: any) => {
      switch (action.type) {
        case TypeKeys.ADDED:
          startOrQueueUpload(action.payload);
          break;
        case TypeKeys.UPLOADED:
          finishUpload(action.payload.uploadId);
          break;
        case TypeKeys.CANCELED:
          cancelUpload(action.payload.uploadId);
          break;
      }
      return next(action);
    };
  };
};
