import { schema, normalize } from 'normalizr';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import camelize from 'camelize';
import { OtherAction } from './global';
import ContactMethod from 'types/ContactMethod';
import * as api from 'api/contactMethods';

enum TypeKeys {
  FETCHED = 'publisher/contactMethods/FETCHED',
  UPDATED = 'publisher/contactMethods/UPDATED',
  CREATED = 'publisher/contactMethods/CREATED`',
  REMOVED = 'publisher/contactMethods/REMOVED`',
}

interface FetchedAction {
  type: TypeKeys.FETCHED;
  payload: ContactMethod[];
}

interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: ContactMethod;
}

interface CreatedAction {
  type: TypeKeys.CREATED;
  payload: ContactMethod;
}

interface RemovedAction {
  type: TypeKeys.REMOVED;
  payload: number;
}

type ActionTypes =
  | FetchedAction
  | UpdatedAction
  | CreatedAction
  | RemovedAction
  | OtherAction;

// Schema
const contactMethodSchema = new schema.Entity('contactMethods');

// Reducer
export type State = { byId: { [key: string]: ContactMethod }; ids: number[] };

export default (state: State = { byId: {}, ids: [] }, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.FETCHED: {
      if (!action.payload) return state;

      const { entities, result } = normalize(action.payload, [
        contactMethodSchema,
      ]);

      return {
        ...state,
        byId: { ...state.byId, ...entities.contactMethods },
        ids: uniq([...state.ids, ...result]),
      };
    }

    case TypeKeys.UPDATED: {
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id!]: action.payload },
      };
    }

    case TypeKeys.CREATED: {
      const id = action.payload.id!;

      return {
        ...state,
        byId: { ...state.byId, [id]: action.payload },
        ids: [...state.ids, id],
      };
    }

    case TypeKeys.REMOVED: {
      const id = action.payload;
      return { ...state, ids: without(state.ids, id) };
    }

    default:
      return state;
  }
};

// Action Creators
export const fetchedContactMethods = (contactMethods: ContactMethod[]) => ({
  type: TypeKeys.FETCHED,
  payload: contactMethods,
});

const updatedContactMethod = (contactMethod: ContactMethod) => ({
  type: TypeKeys.UPDATED,
  payload: contactMethod,
});

export const updateContactMethod = (
  id: number,
  updates: Partial<ContactMethod>
) => async (dispatch: any) => {
  const json = await api.updateContactMethod(id, updates);
  dispatch(updatedContactMethod(camelize(json)));
};

const createdContactMethod = (contactMethod: ContactMethod) => ({
  type: TypeKeys.CREATED,
  payload: contactMethod,
});

export const createContactMethod = (contactMethod: ContactMethod) => async (
  dispatch: any
) => {
  const json = await api.createContactMethod(contactMethod);
  dispatch(createdContactMethod(camelize(json)));
};

const removedContactMethod = (id: number) => ({
  type: TypeKeys.REMOVED,
  payload: id,
});

export const removeContactMethod = (id: number) => async (dispatch: any) => {
  await api.removeContactMethod(id);
  dispatch(removedContactMethod(id));
};

// Selectors
export const getContactMethods = (state: { contactMethods: State }) =>
  state.contactMethods.ids.map((id) => state.contactMethods.byId[id]);
