import React, { useState } from 'react';
import { Form } from 'components/shared';
import { useIntl } from 'hooks';
import * as api from 'api/mfa';
import camelize from 'camelize';
import flashMessage from 'helpers/flashMessage';
import { useDispatch } from 'react-redux';
import { logInUser } from 'redux/ducks/user';
import { FormattedMessage } from 'react-intl';
import { SignInFormResendSms } from './components';
import UserApiPayload from 'types/UserApiPayload';
import Btn from 'components/shared/Btn';

interface Props {
  tempToken: string;
  mfaProtocol: string;
  mfaPhone: string;
  onSuccess?: (data: UserApiPayload) => void;
  onBeforeSuccess?: (data: UserApiPayload) => void;
  onSetIsUsingRecoveryCode: (isUsingCode: boolean) => void;
}

export default function SignInFormValidateOtp(props: Props) {
  const {
    tempToken,
    mfaProtocol,
    mfaPhone,
    onSetIsUsingRecoveryCode,
    onBeforeSuccess,
    onSuccess,
  } = props;
  const { t } = useIntl();
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const lastTwoDigits = mfaPhone.slice(-2);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const json = camelize(
        await api.validateOtp(mfaProtocol, token, tempToken)
      );
      setIsSubmitting(false);
      if ('errors' in json && json.errors) {
        setIsInvalid(true);
        setError(t('Validation__InvalidSecurityCode'));
      } else if ('user' in json) {
        onBeforeSuccess?.(json);
        dispatch(logInUser(json));
        onSuccess?.(json);
      } else {
        flashMessage('Global__UnexpectedError', { isError: true });
      }
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
    }
  };

  return (
    <>
      <div className="subheading mb-4">
        {mfaProtocol === 'sms' ? (
          <FormattedMessage
            id="Security__MfaLogin--VerifySms-Desc"
            values={{
              phoneId: (
                <span className="font-bold text-dark">{lastTwoDigits}</span>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="Security__MfaLogin--VerifyApp-Desc"
            values={{
              secondary: (
                <span className="font-bold">
                  {t('Security__MfaLogin--VerifyApp-Desc-secondary')}
                </span>
              ),
            }}
          />
        )}
      </div>
      <div className="mb-3">
        <form onSubmit={handleSubmit}>
          <div className="mb-1.5">
            <Form.TextInput
              value={token}
              isInvalid={!!isInvalid}
              onChange={(e) => setToken(e.target.value)}
              placeholder={t('Security__MfaLogin--Verify-Placeholder')}
              autoFocus
              skipField
            />
            {!!error && (
              <div className="text-left text-error text-sm">{t(error)}</div>
            )}
          </div>

          <div>
            <Btn
              type="submit"
              isLoading={isSubmitting}
              disabled={!token || isSubmitting}
            >
              {t('Button__Continue')}
            </Btn>
          </div>
        </form>
      </div>

      {mfaProtocol === 'sms' && (
        <SignInFormResendSms tempToken={tempToken} mfaProtocol={mfaProtocol} />
      )}
      <div className="flex items-center justify-center">
        <div
          className="text-socialiePink cursor-pointer hover:text-socialiePinkHover"
          onClick={() => onSetIsUsingRecoveryCode(true)}
        >
          <span className="text-14">
            {t('Security__MfaLogin--UseRecovery')}
          </span>
        </div>
      </div>
    </>
  );
}
