import {
  S3MultipartUploader,
  UploaderCallbacks,
} from 'helpers/upload/S3MultipartUploader';
import uuid from 'uuid4';
import { createAttachment } from './attachments';
import fileProcessingStatus from 'helpers/upload/fileProcessingStatus';
import { RootState } from 'redux/ducks';
import { State as SocketState } from './socket';
import Attachment from 'types/Attachment';
import FileUpload from 'types/FileUpload';
import StartUploadParams from 'types/StartUploadParams';
import { updateContentRequestSubmission } from './contentRequestSubmissions';
import { OtherAction } from './global';
import { AppDispatch } from 'redux/store';

export enum TypeKeys {
  ADDED = 'publisher/fileUploads/ADDED',
  STARTED = 'publisher/fileUploads/STARTED',
  PROGRESS = 'publisher/fileUploads/PROGRESS',
  UPLOADED = 'publisher/fileUploads/UPLOADED',
  UPLOAD_ERROR = 'publisher/fileUploads/UPLOAD_ERROR',
  CREATING_THUMBNAILS = 'publisher/filesUploads/CREATING_THUMBNAILS',
  TRANSCODING_VIDEO = 'publisher/fileUploads/TRANSCODING_VIDEO',
  FILE_READY = 'publisher/fileUploads/FILE_READY',
  PROCESSING_ERROR = 'publisher/fileUploads/PROCESSING_ERROR',
  CANCELED = 'publisher/fileUploads/CANCELED',
}

interface AddedAction {
  type: TypeKeys.ADDED;
  payload: {
    uploadId: string;
    uploader: S3MultipartUploader;
    aspectRatio: number;
    previewUrl?: string;
    requestKey?: string;
    contentRequestSubmissionId?: number;
  };
}

interface StartedAction {
  type: TypeKeys.STARTED;
  payload: {
    uploadId: string;
  };
}

interface ProgressAction {
  type: TypeKeys.PROGRESS;
  payload: {
    uploadId: string;
    progress: number;
  };
}

interface CompleteAction {
  type: TypeKeys.UPLOADED;
  payload: {
    uploadId: string;
    requestKey: string;
  };
}

interface FileReadyAction {
  type: TypeKeys.FILE_READY;
  payload: {
    uploadId: string;
    attachment: Attachment;
  };
}

interface ErrorAction {
  type: TypeKeys.UPLOAD_ERROR | TypeKeys.PROCESSING_ERROR;
  payload: {
    uploadId: string;
    error: string;
  };
}

interface FileProcessingAction {
  type:
    | TypeKeys.CREATING_THUMBNAILS
    | TypeKeys.TRANSCODING_VIDEO
    | TypeKeys.CANCELED;
  payload: {
    uploadId: string;
  };
}

type ActionTypes =
  | AddedAction
  | StartedAction
  | ProgressAction
  | CompleteAction
  | FileReadyAction
  | ErrorAction
  | FileProcessingAction;

// Reducer
export interface State {
  [uploadId: string]: FileUpload;
}

export default (
  state: State = {},
  action: ActionTypes | OtherAction
): State => {
  switch (action.type) {
    case TypeKeys.ADDED: {
      const {
        uploader,
        previewUrl,
        requestKey,
        contentRequestSubmissionId,
        uploadId: id,
      } = action.payload;
      const { filename, contentType, status } = uploader;
      return {
        ...state,
        [id]: {
          filename,
          contentType,
          id,
          previewUrl,
          requestKey,
          contentRequestSubmissionId,
          status: status || '',
          statusMessage: '',
          aspectRatio: 0,
          progress: 0,
        },
      };
    }

    case TypeKeys.STARTED: {
      return {
        ...state,
        [action.payload.uploadId]: {
          ...state[action.payload.uploadId],
          status: 'uploading',
        },
      };
    }

    case TypeKeys.PROGRESS: {
      const { progress, uploadId: id } = action.payload;
      return {
        ...state,
        [id]: { ...state[id], progress },
      };
    }

    case TypeKeys.CREATING_THUMBNAILS:
    case TypeKeys.TRANSCODING_VIDEO:
    case TypeKeys.CANCELED: {
      const status = fileProcessingStatus(action.type);
      const { uploadId: id } = action.payload;
      return {
        ...state,
        [id]: { ...state[id], status },
      };
    }

    case TypeKeys.FILE_READY: {
      const status = fileProcessingStatus(action.type);
      const attachmentId = action.payload.attachment.id;
      const { uploadId: id } = action.payload;
      return {
        ...state,
        [id]: { ...state[id], status, attachmentId },
      };
    }

    case TypeKeys.PROCESSING_ERROR:
    case TypeKeys.UPLOAD_ERROR: {
      const { error, uploadId: id } = action.payload;
      return {
        ...state,
        [id]: { ...state[id], status: 'error', statusMessage: error },
      };
    }

    default:
      return state;
  }
};

const uploadCompleted = (uploadId: string) => ({
  type: TypeKeys.UPLOADED,
  payload: { uploadId },
});

const uploadError = (uploadId: string, error: string) => ({
  type: TypeKeys.UPLOAD_ERROR,
  payload: { uploadId, error },
});

const uploadProgress = (uploadId: string, progress: number) => ({
  type: TypeKeys.PROGRESS,
  payload: { uploadId, progress },
});

export const creatingThumbnails = (uploadId: string) => ({
  type: TypeKeys.CREATING_THUMBNAILS,
  payload: { uploadId },
});

export const transcodingVideo = (uploadId: string) => ({
  type: TypeKeys.TRANSCODING_VIDEO,
  payload: { uploadId },
});

export const fileReady = (uploadId: string, attachment: Attachment) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  dispatch({
    type: TypeKeys.FILE_READY,
    payload: { uploadId, attachment },
  });

  // After the file finishes processing, if the upload is for a content request
  // submissions, save the attachment id
  const fileUpload = getState().fileUploads[uploadId];

  if (fileUpload && fileUpload.contentRequestSubmissionId) {
    dispatch(
      updateContentRequestSubmission({
        id: fileUpload.contentRequestSubmissionId,
        updates: {
          attachmentId: attachment.id,
          submitted: true,
        },
      })
    );
  }
};

export const processingError = (uploadId: string, error: string) => ({
  type: TypeKeys.PROCESSING_ERROR,
  payload: { uploadId, error },
});

export const startUpload = (uploadId: string) => ({
  type: TypeKeys.STARTED,
  payload: { uploadId },
});

export const addUpload = (params: StartUploadParams) => async (
  dispatch: Function,
  getState: () => { socket: SocketState }
) => {
  const socketId = getState().socket.id as string;

  const uploadId = uuid();
  const {
    file,
    requestKey,
    contentRequestSubmissionId,
    previewUrl,
    flipMedia,
  } = params;

  const callbacks: UploaderCallbacks = {
    onComplete: async () => {
      dispatch(uploadCompleted(uploadId));
      dispatch(
        createAttachment(requestKey, uploader, socketId, uploadId, flipMedia)
      );
    },
    onError: (error: string) => {
      dispatch(uploadError(uploadId, error));
    },
    onProgress: (progress: number) => {
      dispatch(uploadProgress(uploadId, progress));
    },
  };

  const uploader = new S3MultipartUploader(file, callbacks);

  dispatch({
    type: TypeKeys.ADDED,
    payload: {
      uploader,
      uploadId,
      previewUrl,
      requestKey,
      contentRequestSubmissionId,
    },
  });
};

export const cancelUpload = (uploadId: string) => ({
  type: TypeKeys.CANCELED,
  payload: { uploadId },
});

// Selectors
export const getFileUploadsByRequestKey = (
  state: RootState,
  requestKey: string
) =>
  Object.values(state.fileUploads).filter((u) => u.requestKey === requestKey);

export const getFileUploads = (state: RootState) => state.fileUploads;

export const getActiveFileUploadsByRequestKey = (
  state: RootState,
  requestKey: string
) =>
  getFileUploadsByRequestKey(state, requestKey).filter(
    (u) => u.status !== 'done' && u.status !== 'canceled'
  );

export const getFileUploadByContentRequestSubmissionId = (
  state: RootState,
  contentRequestSubmissionId: number
) =>
  Object.values(state.fileUploads).find(
    (fileUpload) =>
      fileUpload.contentRequestSubmissionId === contentRequestSubmissionId
  );
