import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  useInstagramLoginApi?: boolean;
};

const initialState: State = {};

const { reducer, actions } = createSlice({
  initialState,
  name: 'publisher/features',
  reducers: {
    fetchedFeatures: (state, action: PayloadAction<State>) => {
      return action.payload;
    },
  },
});

export default reducer;

export const { fetchedFeatures } = actions;
