import { Btn, Modal } from 'components/shared';
import { useIntl } from 'hooks';
import { IconFilter } from 'icons';
import React, { useState } from 'react';
import MainTopBarScrollingSubGroup from './MainTopBarScrollingSubGroup';

interface Props {
  children: React.ReactNode;
  isFiltered?: boolean;
  onResetFilters?: () => void;
}

export const FiltersContext = React.createContext<'modal' | 'row'>(null as any);

export default function Filters(props: Props) {
  const { children, isFiltered, onResetFilters } = props;
  const { t } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <MainTopBarScrollingSubGroup
        renderOverflowButton={
          <button
            className="w-3 h-3 text-dark"
            onClick={() => setIsModalOpen(true)}
            aria-label={t('Filters__ModalButton')}
          >
            <IconFilter className="w-full h-full" />
          </button>
        }
      >
        {children}
      </MainTopBarScrollingSubGroup>

      <Modal
        isOpen={isModalOpen}
        theme="bottom"
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={t('Filters__ModalLabel')}
        renderHeading={t('Filters__ModalLabel')}
        renderFooter={
          <>
            <Btn
              size="lg"
              variant="inlineBlock"
              onClick={() => setIsModalOpen(false)}
            >
              {t('Filters__ModalClose')}
            </Btn>

            {isFiltered && onResetFilters && (
              <Btn
                size="lg"
                color="grey"
                variant="inlineBlock"
                onClick={onResetFilters}
              >
                {t('Filters__ModalReset')}
              </Btn>
            )}
          </>
        }
      >
        <FiltersContext.Provider value="modal">
          {children}
        </FiltersContext.Provider>
      </Modal>
    </>
  );
}
