import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'hooks';

interface Props {
  title?: string;
  values?: { [key: string]: string | number };
}

const prefix = process.env.NODE_ENV === 'development' ? '[D] ' : '';
export default function PageTitle({ title, values }: Props) {
  const { t } = useIntl();

  return (
    <Helmet
      title={prefix + (title ? `${t(title, values)} | Socialie` : 'Socialie')}
    />
  );
}
