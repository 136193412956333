import { clsx } from 'clsx';
import { IconCaretDown } from 'icons';
import { useState, useRef, useLayoutEffect } from 'react';

type Props = {
  publisherRequest: { instructions?: string; subject?: string };
  logoUrl?: string;
  theme?: 'grey' | 'black';
  className?: string;
};

export default function PublisherRequestInstructionsBanner(props: Props) {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const { publisherRequest, theme = 'black', className, logoUrl } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [needsTruncation, setNeedsTruncation] = useState(false);

  useLayoutEffect(() => {
    const checkHeights = () => {
      if (!textRef.current || !textContainerRef.current || isExpanded) return;
      const { height: textHeight } = textRef.current.getBoundingClientRect();
      const {
        height: textContainerHeight,
      } = textContainerRef.current.getBoundingClientRect();

      setNeedsTruncation(textHeight > textContainerHeight);
    };

    window.addEventListener('resize', checkHeights);
    checkHeights();

    return () => window.removeEventListener('resize', checkHeights);
  }, [isExpanded]);

  if (!publisherRequest.instructions) return null;

  return (
    <div
      role="button"
      className={clsx(
        `px-2 py-1 rounded-[24px] rounded-bl-none`,
        theme === 'black' ? 'bg-grey1' : 'bg-grey2',
        className
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className={clsx('flex items-start')}>
        {logoUrl && (
          <img
            src={logoUrl}
            className={clsx(
              `w-3 h-3 mr-2 rounded-full object-cover`,
              needsTruncation && 'mt-0.75'
            )}
            alt="Logo"
          />
        )}
        <div
          ref={textContainerRef}
          className={clsx(
            'flex-1 overflow-hidden relative pt-px',
            !isExpanded && 'max-h-5'
          )}
        >
          <div ref={textRef} className={clsx(`text-14 text-white leading-20`)}>
            {publisherRequest.subject && (
              <div className="font-bold">{publisherRequest.subject}</div>
            )}
            {publisherRequest.instructions}
          </div>

          {!isExpanded && needsTruncation && (
            <div
              className={clsx(
                'absolute bottom-0 left-0 right-0 bg-gradient-to-t to-transparent h-1.5',
                theme === 'black' ? 'from-grey1' : 'from-grey2'
              )}
            />
          )}
        </div>

        {needsTruncation && (
          <div
            className={clsx(
              'w-2.5 h-2.5 ml-2 mt-1.25 transition-all',
              isExpanded && 'rotate-180'
            )}
          >
            <IconCaretDown className="w-full h-full text-white" />
          </div>
        )}
      </div>
    </div>
  );
}
