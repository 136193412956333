import * as api from 'api/downloads';
import camelize from 'camelize';
import flashMessage from 'helpers/flashMessage';
import { useState, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/store';
import castArray from 'lodash/castArray';
import { addBatchDownload } from 'redux/ducks/batchDownloads';

interface PublisherRequest {
  requestKey: string;
}

export default function useMediaDownload(opts?: {
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const socketId = useAppSelector((state) => state.socket.id);

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const successCallback = useRef(opts?.onSuccess);
  const errorCallback = useRef(opts?.onError);

  const handleDownload = async (
    publisherRequests?: PublisherRequest | PublisherRequest[],
    attachmentIds?: number | number[],
    size?: string
  ) => {
    const requestKeys = publisherRequests
      ? castArray(publisherRequests).map((p) => p.requestKey)
      : undefined;

    const attachmentIdsToDownload =
      attachmentIds || (Array.isArray(attachmentIds) && attachmentIds.length)
        ? attachmentIds
        : undefined;
    setIsLoading(true);

    try {
      const json = camelize(
        await api.download(requestKeys, attachmentIdsToDownload, {
          socketId,
          size,
        })
      ) as any;

      if (json.errors) {
        flashMessage('Global__UnexpectedError', { isError: true });
        errorCallback.current?.();
      } else if (json.url) {
        window.location.href = json.url;
        successCallback.current?.();
      } else if (json.batch) {
        dispatch(addBatchDownload(json.batch));
        successCallback.current?.();
      } else {
        flashMessage('Global__UnexpectedError', { isError: true });
        console.error('[useMediaDownload] Unexpected response:', json);
        errorCallback.current?.();
      }
    } catch (e) {
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
      errorCallback.current?.();
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, handleDownload };
}
