import React from 'react';
import { clsx } from 'clsx';

interface Props {
  disabled?: boolean;
  isOn: boolean;
  onToggle: () => void;
  theme?: 'socialie' | 'brand' | 'socialieDark' | 'purple';
}

export default function Switch(props: Props) {
  const { isOn, onToggle, disabled, theme = 'socialie' } = props;

  const bgClassNameOn = clsx({
    'bg-brand': theme === 'brand',
    'bg-purple': theme === 'purple',
    'bg-socialiePink': ['socialie', 'socialieDark'].includes(theme),
  });

  const bgClassNameOff = ['socialieDark', 'purple'].includes(theme)
    ? 'bg-grey1'
    : 'bg-darkGreyBg';

  return (
    <button
      onClick={onToggle}
      disabled={disabled}
      className={clsx(
        `block transition-all p-0.25 w-6.5 h-3.5 rounded-full disabled:opacity-25`,
        isOn ? bgClassNameOn : bgClassNameOff
      )}
      type="button"
    >
      <span
        className="block transition-all rounded-full w-3 h-3 bg-white"
        style={{ transform: isOn ? 'translateX(100%)' : undefined }}
      />
    </button>
  );
}
