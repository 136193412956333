import React, { useState } from 'react';
import formatPhoneNumber from 'helpers/formatPhoneNumber';
import { useIntl } from 'hooks';
import signOut from 'helpers/auth/signOut';
import { Link } from 'react-router-dom';
import routes from 'helpers/routes';
import FlyoutMenu from './FlyoutMenu';
import { IconGear } from 'icons';
import PublisherSelectModal from './PublisherSelectModal';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/ducks';
import UtilBar from './UtilBar';
import getFullName from 'helpers/getFullName';

interface Props {
  hideControls?: boolean;
  hidePublisherSelect?: boolean;
  hideSettings?: boolean;
}

export default function UserUtilBar(props: Props) {
  const { hideControls, hideSettings, hidePublisherSelect } = props;
  const [isPublisherSelectModalOpen, setIsPublisherSelectModalOpen] = useState(
    false
  );
  const { user, publisher } = useSelector((state: RootState) => ({
    user: state.user.current,
    publisher: state.publisher,
  }));
  const { t } = useIntl();

  const handleSignOut = () => {
    signOut();
  };

  return (
    <UtilBar>
      {!hideControls && (
        <div className="text-15">
          {user ? (
            <>
              <FlyoutMenu
                placement="bottom-end"
                renderButton={(onClick, isOpen) => {
                  const gearClassName = isOpen ? 'bg-grey7' : '';
                  return (
                    <div
                      className="flex items-center cursor-pointer"
                      role="button"
                      onClick={onClick}
                    >
                      <div className="mr-0.5 text-dark">
                        {getFullName(user) ||
                          user.email ||
                          formatPhoneNumber(user.phone || '')}
                      </div>

                      <div
                        className={`rounded-lg flex items-center justify-center w-4 h-4 ${gearClassName}`}
                      >
                        <IconGear className="block w-2.75 h-2.75 text-light bump-down-1" />
                      </div>
                    </div>
                  );
                }}
              >
                {!hidePublisherSelect && !!user.publishers.length && (
                  <FlyoutMenu.Item
                    onClick={() => setIsPublisherSelectModalOpen(true)}
                  >
                    {t('UserUtilBar__ViewPublishers')}
                  </FlyoutMenu.Item>
                )}

                {!hideSettings && !!publisher && (
                  <FlyoutMenu.Item to={routes.settings.root}>
                    {t('UserUtilBar__Settings')}
                  </FlyoutMenu.Item>
                )}

                <FlyoutMenu.Item
                  onClick={handleSignOut}
                  className="block w-full disabled:opacity-50"
                >
                  {t('Button__SignOut')}
                </FlyoutMenu.Item>
              </FlyoutMenu>

              <PublisherSelectModal
                isOpen={isPublisherSelectModalOpen}
                onRequestClose={() => setIsPublisherSelectModalOpen(false)}
              />
            </>
          ) : (
            <>
              <div className="text-light hidden tablet:inline">
                {t('Global__AlreadyHaveAccount')}{' '}
              </div>

              <Link
                to={routes.auth.login.root}
                className="text-socialiePink hover:underline"
              >
                {t('Button__SignIn')}
              </Link>
            </>
          )}
        </div>
      )}
    </UtilBar>
  );
}

UserUtilBar.defaultProps = {
  hideSettings: true,
};
