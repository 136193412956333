import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import * as api from 'api/publisherUser';
import PublisherUser from 'types/PublisherUser';
import camelize from 'camelize';
import { RootState } from '.';
import UpdatePublisherUserParams from 'types/UpdatePublisherUserParams';
import CreatePublisherUserParams from 'types/CreatePublisherUserParams';
import rejectThunkApiError from 'helpers/rejectThunkApiError';

// asyncThunks
export const fetchPublisherUsers = createAsyncThunk(
  'publisherUsers/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return camelize(await api.listPublisherUsers());
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const removePublisherUser = createAsyncThunk(
  'publisherUsers/remove',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await api.removePublisherUser(id);
      return id;
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const updatePublisherUser = createAsyncThunk(
  'publisherUsers/update',
  async (
    { id, changes }: { id: number; changes: UpdatePublisherUserParams },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await api.updatePublisherUser(id, changes);
      return { id, changes };
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const createPublisherUser = createAsyncThunk(
  'publisherUsers/create',
  async (values: CreatePublisherUserParams, { rejectWithValue, dispatch }) => {
    try {
      return camelize(await api.createPublisherUser(values));
    } catch (e) {
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

// Reducer
const adapter = createEntityAdapter<PublisherUser>();

const { reducer } = createSlice({
  name: 'publisherUsers',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPublisherUsers.fulfilled, adapter.upsertMany);
    builder.addCase(removePublisherUser.fulfilled, adapter.removeOne);
    builder.addCase(updatePublisherUser.fulfilled, adapter.updateOne);
    builder.addCase(createPublisherUser.fulfilled, (state, action) => {
      if ('errors' in action.payload) return state;
      adapter.upsertOne(state, action.payload);
    });
  },
});

export default reducer;

// Selectors
const selectors = adapter.getSelectors();

export const getPublisherUsers = (state: RootState) =>
  selectors.selectAll(state.publisherUsers);
