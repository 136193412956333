import { useCallback, useRef, useState } from 'react';

// Shamelessly taken from https://stackoverflow.com/a/48057286/131208
const useLongPress = (
  onLongPress: () => void,
  onClick?: (e?: any) => void,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<number | null>(null);
  const target = useRef<any>(null);
  const touches = useRef<any>(null);

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick && onClick(event);
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
      target.current?.removeEventListener('touchmove', clear);
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  const start = useCallback(
    (event) => {
      target.current = event.target;
      touches.current = event.touches;

      if (shouldPreventDefault && event.target) {
        target.current?.addEventListener('touchend', preventDefault, {
          passive: false,
        });
      }

      timeout.current = window.setTimeout(() => {
        // only trigger longpress if one finger is pressing the screen
        if (!touches.current?.length || touches.current.length < 2) {
          onLongPress();
          setLongPressTriggered(true);
        }
      }, delay);

      target.current?.addEventListener('touchmove', clear);
    },
    [clear, delay, onLongPress, shouldPreventDefault]
  );

  return {
    onTouchStart: (e: any) => start(e),
    onTouchEnd: (e: any) => clear(e),
    onMouseDown: (e: any) => start(e),
    onMouseUp: (e: any) => clear(e),
    onMouseLeave: (e: any) => clear(e, false),
  };
};

const preventDefault = (event: any) => {
  if ('touches' in event) {
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  }
};

export default useLongPress;
