import React, { useReducer, createContext, useContext } from 'react';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import without from 'lodash/without';
import intersection from 'lodash/intersection';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ID = number | string;

interface State {
  selectedIds: ID[];
  lastSelectedId?: ID;
  isSelectMode: boolean;
}

const initialState: State = {
  selectedIds: [] as ID[],
  isSelectMode: false,
};

const { reducer, actions } = createSlice({
  name: 'useSelectable',
  initialState,
  reducers: {
    toggleSelected: (state, action: PayloadAction<{ id: ID }>) => {
      let selectedIds = state.selectedIds;
      if (includes(state.selectedIds, action.payload.id)) {
        selectedIds = without(selectedIds, action.payload.id);
      } else {
        selectedIds = [...selectedIds, action.payload.id];
      }
      return { ...state, selectedIds, lastSelectedId: action.payload.id };
    },
    selectOnly: (state, action: PayloadAction<{ id: ID }>) => {
      return {
        ...state,
        selectedIds: [action.payload.id],
        lastSelectedId: action.payload.id,
      };
    },
    selectMultiple: (state, action: PayloadAction<{ ids: ID[] }>) => {
      return { ...state, selectedIds: action.payload.ids };
    },
    deselectMultiple: (state, action: PayloadAction<{ ids: ID[] }>) => {
      state.selectedIds = without(state.selectedIds, ...action.payload.ids);
    },
    selectRange: (state, action: PayloadAction<{ allIds: ID[]; id: ID }>) => {
      const { id, allIds } = action.payload;
      const loadedSelectedIds = intersection(allIds, state.selectedIds);
      const lastSelected = state.lastSelectedId || id;
      const lastSelectedIdx = allIds.indexOf(lastSelected);
      const nextIdx = allIds.indexOf(id);
      const range = allIds.slice(
        Math.min(lastSelectedIdx, nextIdx),
        Math.max(lastSelectedIdx, nextIdx) + 1
      );

      const isAlreadySelected = includes(loadedSelectedIds, id);

      let selectedIds;
      if (isAlreadySelected) {
        selectedIds = without(loadedSelectedIds, ...range);
      } else {
        selectedIds = uniq([...loadedSelectedIds, ...range]);
      }

      return { ...state, selectedIds, lastSelectedId: id };
    },
    setSelectMode: (state, action: PayloadAction<boolean>) => {
      state.isSelectMode = action.payload;
    },
  },
});

export default function useSelectable(allIds: ID[] = []) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadedSelectedIds = intersection(allIds, state.selectedIds);

  return {
    toggleSelected: (id: ID) => dispatch(actions.toggleSelected({ id })),
    selectOnly: (id: ID) => dispatch(actions.selectOnly({ id })),
    selectMultiple: (ids: ID[]) => dispatch(actions.selectMultiple({ ids })),
    deselectMultiple: (ids: ID[]) =>
      dispatch(actions.deselectMultiple({ ids })),
    selectAll: () => dispatch(actions.selectMultiple({ ids: allIds })),
    selectNone: () => dispatch(actions.selectMultiple({ ids: [] })),
    toggleSelectMode: () =>
      dispatch(actions.setSelectMode(!state.isSelectMode)),
    setSelectMode: (val: boolean) => dispatch(actions.setSelectMode(val)),
    isSelected: (id: ID) => includes(state.selectedIds, id),
    isSelectMode: state.isSelectMode,
    selectedIds: loadedSelectedIds,
  };
}

// Optional Context
type ContextValue = ReturnType<typeof useSelectable>;
const SelectableContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactNode;
  value: ContextValue;
}

export function SelectableProvider({ children, value }: ProviderProps) {
  return (
    <SelectableContext.Provider value={value}>
      {children}
    </SelectableContext.Provider>
  );
}

export function useSelectableContext() {
  return useContext(SelectableContext);
}
