import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'ui/isReturningUser',
  initialState: false,
  reducers: {
    detectedReturningUser: () => true,
  },
});

export default reducer;

export const { detectedReturningUser } = actions;
