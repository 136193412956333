import React from 'react';
import socialieLogo from 'icons/socialie.svg';
import { PublisherAvatar } from 'components/shared';
import { IconCaretLeft, IconLink } from 'icons';
import { useAppSelector } from 'redux/store';
import { getCurrentBrand } from 'redux/ducks/brand';

interface Props {
  children: React.ReactNode;
  renderBeforeHeading?: React.ReactNode;
  heading?: string;
  subheading?: React.ReactNode;
  allowSignOut?: boolean;
  onClickBack?: () => void;
}

export default function AccountConversionPage(props: Props) {
  const {
    heading,
    subheading,
    renderBeforeHeading,
    children,
    onClickBack,
  } = props;

  const { brand, legacyPublisher } = useAppSelector((state) => ({
    brand: getCurrentBrand(state),
    legacyPublisher: state.legacyPublisher,
  }));

  const imgClassNames = `w-7.5 h-7.5`;

  return (
    <div className="pt-3 phablet:px-1 phablet:pb-2 text-center">
      {onClickBack && (
        <button
          className="block absolute top-0 left-0 p-1.5"
          onClick={onClickBack}
        >
          <IconCaretLeft className="w-2 h-2 text-lightIcon" />
        </button>
      )}

      {renderBeforeHeading || (
        <div className="flex justify-center">
          <div className="flex items-center mb-5">
            <div className={`${imgClassNames} relative mr-2`}>
              <PublisherAvatar
                src={legacyPublisher?.image}
                name={legacyPublisher?.name}
              />

              <img
                className="rounded-logo block absolute -bottom-1 -right-1 w-4 h-4 bg-white"
                src={brand.logoUrl}
                alt=""
              />
            </div>

            <IconLink className="block mr-1.5 text-light w-2.5 h-2.5" />

            <img
              src={socialieLogo}
              className={`${imgClassNames} rounded-logo p-1`}
              alt="Socialie"
            />
          </div>
        </div>
      )}

      {(!!heading || !!subheading) && (
        <div className="mb-3">
          <h3 className="h3">{heading}</h3>

          {!!subheading && <div className="subheading">{subheading}</div>}
        </div>
      )}

      {children}
    </div>
  );
}
