import React, { forwardRef } from 'react';
import { clsx } from 'clsx';

interface Props extends React.ComponentProps<'button'> {
  isActive: boolean;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const SuggestionsBrowserButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { isActive, icon, ...buttonProps } = props;

    return (
      <button
        {...buttonProps}
        ref={ref}
        className={clsx(
          'ml-1.5 rounded-full w-5.5 h-5.5 p-1.25 transition-default first:ml-0',
          {
            'bg-white text-socialiePink': isActive,
            'text-white': !isActive,
          }
        )}
      >
        {React.createElement(icon, { className: 'block w-full h-full' })}
      </button>
    );
  }
);

export default SuggestionsBrowserButton;
