import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api/publisherRequests';
import camelize from 'camelize';
import SuggestionsFilterData from 'types/SuggestionsFilterData';

// asyncThunks
export const fetchSuggestionsFilterData = createAsyncThunk(
  'inboxFilterData/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return camelize(await api.fetchSuggestionsFilterData());
    } catch (e) {
      console.error(e);
      return rejectWithValue({ error: e.message || e });
    }
  }
);

// Reducer
const { reducer } = createSlice({
  name: 'inboxFilterData',
  initialState: {
    dates: [],
    suggestionAlbums: [],
    suggesters: [],
    hasMonetizableContent: false,
  } as SuggestionsFilterData,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuggestionsFilterData.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default reducer;
