import { get, post } from './shared';

export const changeAccount = async (publisherId: number | string) => {
  const endpoint = 'publisher/change_account';
  return post(endpoint, { publisherId });
};

export const getAccounts = async () => {
  const endpoint = 'publisher/accounts';
  return get(endpoint);
};
