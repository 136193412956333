import React from 'react';

interface Props {
  name?: string;
  children: React.ReactNode;
}

export default function Label({ children, name }: Props) {
  return (
    <label
      className="block text-dark text-14 font-semibold mb-0.25"
      htmlFor={name}
    >
      {children}
    </label>
  );
}
