import React from 'react';
import {
  Checkbox,
  Field,
  FileInput,
  Label,
  Radio,
  TextArea,
  TextInput,
  Description,
} from './components';

export default function Form(props: React.ComponentProps<'form'>) {
  const { onSubmit, ...formProps } = props;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(e);
      }}
      {...formProps}
    >
      {props.children}
    </form>
  );
}

Form.Checkbox = Checkbox;
Form.Field = Field;
Form.Label = Label;
Form.Radio = Radio;
Form.TextArea = TextArea;
Form.TextInput = TextInput;
Form.Description = Description;
Form.FileInput = FileInput;
