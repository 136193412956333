import routeFor from './routeFor';

export default function urlFor(
  brand: string,
  path: string,
  ...vars: (string | number)[]
) {
  const route = routeFor(path, ...vars);

  let protocol: string;
  let port: string;

  if (process.env.NODE_ENV === 'development') {
    protocol = 'http://';
    port = ':3000';
  } else {
    protocol = 'https://';
    port = '';
  }

  const domain = import.meta.env.REACT_APP_DOMAIN;

  return `${protocol}${brand}.${domain}${port}${route}`;
}
