import PlatformId from 'types/PlatformId';
import { getDraftsByRequestKey } from 'redux/ducks/drafts';
import { getPlatformsByRequestKey } from 'redux/ducks/platforms';
import { getPublisherRequestByKey } from 'redux/ducks/publisherRequests';

export default (state: any, requestKey: string, platformId: PlatformId) => {
  const draft = getDraftsByRequestKey(state, requestKey)[platformId];
  const platform = getPlatformsByRequestKey(state, requestKey)[platformId];
  const publisherRequest = getPublisherRequestByKey(state, requestKey)!;

  const attachmentIds = platform.attachmentIds;

  return {
    text: draft.text,
    postAt: draft.postAt,
    attachmentIds: attachmentIds as number[],
    platform: platformId,
    requestId: publisherRequest.id,
    mentions: draft.mentions,
  };
};
