import { combineReducers } from '@reduxjs/toolkit';
import lastIndexPath, { State as LastIndexPathState } from './lastIndexPath';
import accountConversion from './accountConversion';
import isReturningUser from './isReturningUser';
import loading from './loading';
import errors from './errors';

export interface State {
  lastIndexPath: LastIndexPathState;
  accountConversion: ReturnType<typeof accountConversion>;
  isReturningUser: ReturnType<typeof isReturningUser>;
  loading: ReturnType<typeof loading>;
  errors: ReturnType<typeof errors>;
}

export default combineReducers({
  lastIndexPath,
  accountConversion,
  isReturningUser,
  loading,
  errors,
});
