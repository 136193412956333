import React, { useState } from 'react';
import { AccountConversionPage } from '../components';
import { useIntl } from 'hooks';
import { IconCaretRight, IconLoader } from 'icons';
import { useAppSelector } from 'redux/store';
import { PublisherListRow } from 'components/shared';
import PublisherAccount from 'types/PublisherAccount';
import camelize from 'camelize';
import { fetchPublisherCodeUrl } from 'api/publisher';
import urlFor from 'helpers/routeFor/urlFor';
import routes from 'helpers/routes';
import flashMessage from 'helpers/flashMessage';

interface Props {
  onContinue: () => void;
}

export default function AccountConversionPossiblePublishers({
  onContinue,
}: Props) {
  const { user } = useAppSelector(state => ({
    user: state.user.current,
  }));
  const { t } = useIntl();

  return (
    <AccountConversionPage
      heading={t('AccountConversionPossiblePublishers__Heading')}
      subheading={t('AccountConversionPossiblePublishers__SubHeading')}
      renderBeforeHeading={<div />}
    >
      <div className="mb-3">
        {user?.possiblePublishers.map(publisher => (
          <PublisherRow key={publisher.id} publisher={publisher} />
        ))}
      </div>

      <button onClick={onContinue} className="text-light hover:underline">
        {t('Button__Close')}
      </button>
    </AccountConversionPage>
  );
}

interface PublisherRowProps {
  publisher: PublisherAccount;
}

function PublisherRow({ publisher }: PublisherRowProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = async () => {
    setIsLoading(true);
    try {
      const data = camelize(await fetchPublisherCodeUrl(publisher.id));
      window.location.href = urlFor(
        data.brand,
        routes.publisherCode,
        data.code
      );
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      flashMessage('Global__UnexpectedError', { isError: true });
    }
  };

  return (
    <PublisherListRow
      onClick={handleSelect}
      disabled={isLoading}
      publisher={publisher}
      renderActions={
        isLoading ? (
          <IconLoader className="w-2 h-2" />
        ) : (
          <IconCaretRight className="w-2 h-2 text-lightIcon" />
        )
      }
    />
  );
}
