import { post } from './shared';
import PlatformId from 'types/PlatformId';
import UserApiPayload from 'types/UserApiPayload';

export function unlinkIdentity(platform: PlatformId) {
  return post(`publisher/unlink/${platform}`);
}

export function copyIdentity(id: number | string, facebookPageId?: number) {
  return post<UserApiPayload | { errors: string }>(
    `publisher/copy-identity/${id}`,
    { facebookPageId }
  );
}
