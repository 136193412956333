import useKey from '@rooks/use-key';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { clsx } from 'clsx';
import Form from 'components/shared/Form';
import { useIntl } from 'hooks';
import { IconCaretLeft, IconCheck, IconSearch } from 'icons';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { GroupedOption, Option } from '../utils/buildCountryCodeOptions';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedOption: Option;
  options: Option[] | GroupedOption[];
  onChange: (value: string) => void;
}

const TIMEOUT = 200;

export default function CountryCodeFullscreen(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOpen, options, selectedOption, onChange } = props;
  const { t } = useIntl();
  const [query, setQuery] = useState('');
  const [isVisible, setIsVisible] = useState(isOpen);

  const handleChange = (option: Option) => {
    onChange(option.value);
    setTimeout(handleClose, 200);
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(props.onRequestClose, TIMEOUT);
  };

  useKey(['Escape'], handleClose);

  useEffect(() => {
    const currentEl = containerRef.current;
    if (currentEl) disableBodyScroll(currentEl);

    return () => {
      if (currentEl) enableBodyScroll(currentEl);
    };
  }, []);

  // Set isVisible back to true and clear the query when opening
  useEffect(
    () => {
      if (isOpen && !isVisible) {
        setIsVisible(true);
        setQuery('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  if (!isOpen) return null;

  return createPortal(
    <div
      className={clsx(
        'transition-default transform fixed top-0 left-0 right-0 bottom-0 overflow-hidden bg-greyBg pt-2 flex flex-col',
        {
          'translate-x-0 opacity-100': isVisible,
          'translate-x-full opacity-0': !isVisible,
        }
      )}
      style={{ zIndex: 3000 }}
    >
      <div className="mb-2 px-2 relative flex justify-between items-center">
        <button className="w-2.5 h-2.5 text-dark" onClick={handleClose}>
          <IconCaretLeft className="w-full h-full" />
        </button>

        <div className="text-dark font-semibold text-18">
          {t('PhoneInput__CountryCode')}
        </div>

        <div className="w-2.5">&nbsp;</div>
      </div>

      <div
        ref={containerRef}
        className="px-2 pt-0.5 pb-2 flex-1 overflow-y-auto scrolling-touch body-scroll-lock-ignore"
      >
        <div className="mb-3">
          <Form.TextInput
            leftIcon={IconSearch}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={t('PhoneInput__CountryCodeSearchPlaceholder')}
            onClear={() => setQuery('')}
            skipField
          />
        </div>

        {'options' in options[0] ? (
          <>
            {(options as GroupedOption[]).map((group) => (
              <Options
                key={group.label}
                selectedOption={selectedOption}
                label={group.label}
                options={group.options}
                onChange={handleChange}
                query={query}
              />
            ))}
          </>
        ) : (
          <Options
            options={options as Option[]}
            selectedOption={selectedOption}
            onChange={handleChange}
            query={query}
          />
        )}
      </div>
    </div>,
    document.body
  );
}

interface OptionsProps {
  options: Option[];
  selectedOption: Props['selectedOption'];
  onChange: (value: Option) => void;
  label?: string;
  query: string;
}

function Options(props: OptionsProps) {
  const { selectedOption, onChange, label, query } = props;

  const options = props.options.filter((option) => {
    return option.label.toLowerCase().includes(query.toLowerCase());
  });

  if (!options.length) return null;

  return (
    <div className="mb-3 last:mb-0">
      {!!label && <div className="text-14 text-dark mb-1">{label}</div>}
      <div className="border-default rounded bg-white p-1">
        {options.map((option) => (
          <button
            key={option.label}
            onClick={() => onChange(option)}
            className={clsx('block p-1 w-full text-dark text-18', {
              'font-semibold': option === selectedOption,
            })}
          >
            <span className="flex items-center">
              <span className="flex-1">{option.label}</span>
              {option === selectedOption && (
                <IconCheck className="block w-2.5 h-2.5" />
              )}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}
