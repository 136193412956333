import React from 'react';
import 'tippy.js/dist/tippy.css';
import Tippy, { TippyProps } from '@tippyjs/react';

const Tooltip = ({ children, ...tooltipProps }: TippyProps) => {
  return (
    <Tippy
      theme="socialie"
      appendTo={document.body}
      duration={[150, 150]}
      maxWidth="none"
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
        ],
      }}
      arrow={false}
      {...tooltipProps}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
