import React from 'react';
import { clsx } from 'clsx';

interface Props {
  value?: React.ComponentProps<'input'>['value'];
  maxLength: number;
}

export default function SuggestionCaptionRemainingChars(props: Props) {
  const { maxLength } = props;
  const value = `${props.value || ''}`;
  const remainingChars = maxLength - value.length;

  let style = 'normal';
  if (remainingChars < maxLength - maxLength * 0.8) style = 'warning';
  if (remainingChars < 1) style = 'error';

  return (
    <div
      className={clsx('inline-block transition-default pl-0.75 text-12', {
        'text-grey600 hidden': style === 'normal',
        'text-error': style === 'error',
        'text-yellow': style === 'warning',
      })}
    >
      <span className="bump-up-0.5">{remainingChars}</span>
    </div>
  );
}
