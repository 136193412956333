import { clsx } from 'clsx';
import React from 'react';
import { IconCheck } from 'icons';

interface Props {
  variant?: 'large';
  checkedStyle?: 'checkmark' | 'line';
  uncheckedStyle?: 'filled' | 'border' | 'mono-check';
  checked?: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export default function Checkbox(props: Props) {
  const {
    variant,
    checked,
    onChange,
    disabled,
    checkedStyle = 'checkmark',
    uncheckedStyle = 'filled',
  } = props;

  return (
    <button
      onClick={onChange}
      className={clsx('block rounded border-solid', {
        'w-2 h-2 p-px': variant !== 'large',
        'w-3 h-3 p-0.25': variant === 'large',
        'border-grey6': !checked && uncheckedStyle !== 'mono-check',
        'border-white': !checked && uncheckedStyle === 'mono-check',
        'border-2': !checked && uncheckedStyle === 'border',
        border: checked || uncheckedStyle !== 'border',
        'bg-white':
          !checked &&
          !disabled &&
          ['filled', 'mono-check'].includes(uncheckedStyle),
        'bg-socialiePink border-socialiePink border-solid': checked,
        'bg-greyBg': disabled,
      })}
    >
      {checkedStyle === 'line' ? (
        <span className="flex items-center justify-center p-px">
          <span className="h-0.25 w-full rounded-full bg-white" />
        </span>
      ) : (
        <IconCheck
          className={clsx('block w-full h-full', {
            hidden: !checked && uncheckedStyle !== 'mono-check',
            'text-white': checked,
            'text-dark': !checked,
          })}
        />
      )}
    </button>
  );
}
