import { useIntl } from 'hooks';
import { useAppSelector } from 'redux/store';
import NotificationPreferencesType from 'types/NotificationPreferences';
import { ManageNotificationsRow } from './components';

interface Props {
  socialie?: boolean;
}

export default function ManageNotifications({ socialie }: Props) {
  const { t } = useIntl();
  const { suggester, userPhone } = useAppSelector((state) => ({
    suggester: state.suggester,
    userPhone: state.user.current?.phone,
  }));

  const canSendSms = !userPhone || suggester?.config.canSendSms;

  const types: (keyof NotificationPreferencesType)[] = ['email', 'twitter_dm'];
  if (suggester?.config.enableWhatsApp) types.push('whats_app');

  // Push SMS to the bottom of the list if the suggester can't send SMS
  // to the user's phone number
  if (canSendSms) {
    types.splice(1, 0, 'sms');
  } else {
    types.push('sms');
  }

  return (
    <>
      {types.map((type) => (
        <ManageNotificationsRow
          key={type}
          type={type}
          socialie={socialie}
          canSendSms={canSendSms}
          accountName={suggester?.accountName}
        />
      ))}

      <div className="py-1 px-2 bg-greyBg rounded">
        {t('ManageNotifications__SMSOptIn')}
      </div>
    </>
  );
}
