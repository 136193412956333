import React, { useRef } from 'react';
import { View, Image, Text } from './nativewind';
import { clsx } from 'clsx';

type Props = {
  imageUrl?: string | null;
  name?: string | null;
  size?: 'sm' | 'md';
  theme?: 'dark' | 'white' | 'rainbow';
} & (
  | { logoUrl?: string | null; renderLogo?: never }
  | { renderLogo: React.ReactNode; logoUrl?: never }
) &
  (
    | { imageUrl?: string | null; renderImage?: never }
    | { imageUrl?: never; renderImage: React.ReactNode }
  );

const rainbowColors = [
  'rgba(252, 35, 97, 1)',
  'rgba(134, 118, 255, 1)',
  'rgba(255, 186, 105, 1)',
  'rgba(0, 139, 248, 1)',
  'rgba(78, 198, 176, 1)',
  'rgba(145, 152, 161, 1)',
];

export default function Avatar(props: Props) {
  const {
    imageUrl,
    name,
    logoUrl,
    theme,
    renderLogo,
    renderImage,
    size = 'md',
  } = props;

  const rainbowIdx = useRef(Math.floor(Math.random() * rainbowColors.length));

  const initials = name
    ?.split(' ')
    .slice(0, 2)
    .map((s) => s.replace(/^[^a-zA-z\\?]+/, '').substring(0, 1))
    .join('')
    .toUpperCase();

  return (
    <View
      className={clsx(
        `rounded-full`,
        {
          'w-3 h-3': size === 'sm',
          'w-5 h-5': size === 'md',
        },
        theme === 'dark'
          ? 'bg-darkGreyBg'
          : theme === 'white'
          ? 'bg-white'
          : 'bg-greyBg'
      )}
      style={{
        backgroundColor:
          theme === 'rainbow' ? rainbowColors[rainbowIdx.current] : undefined,
      }}
    >
      {imageUrl ? (
        <Image
          source={{ uri: imageUrl }}
          className="z-10 w-full h-full rounded-full"
        />
      ) : (
        renderImage
      )}

      <View className="absolute top-0 left-0 w-full h-full p-0.5 flex-col justify-center items-center">
        <Text
          numberOfLines={1}
          className={clsx(
            'font-sansSemibold',
            theme === 'rainbow' ? 'text-white' : 'text-dark',
            { 'text-12': size === 'sm', 'text-14': size === 'md' }
          )}
        >
          {initials}
        </Text>
      </View>

      {(logoUrl || renderLogo) && (
        <View
          className={clsx(
            `absolute z-20 rounded-full overflow-hidden border border-solid border-grey8`,
            {
              'w-3 h-3 -bottom-1 -right-1': size === 'md',
              'w-2 h-2 -bottom-0.5 -right-0.5': size === 'sm',
            }
          )}
        >
          {logoUrl ? (
            <Image
              className="w-full h-full bg-white"
              source={{ uri: logoUrl }}
            />
          ) : (
            renderLogo
          )}
        </View>
      )}
    </View>
  );
}
