import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'redux/store';
import NotificationPreferences from 'types/NotificationPreferences';
import { flashMessage } from 'redux-flash';
import * as api from 'api/notificationPreferences';
import { RootState } from '.';

const initialState: NotificationPreferences = {
  email: false,
  sms: false,
  twitter_dm: false,
  whats_app: false,
};

export type State = typeof initialState;

const { reducer, actions } = createSlice({
  initialState,
  name: 'publisher/notificationPreferences',
  reducers: {
    fetchedNotificationPreferences: (state, action: PayloadAction<State>) => {
      // de-camelize the results
      Object.keys(action.payload).forEach(key => {
        state[camelToUnderscore(key)] = action.payload[key];
      });
    },
    addedNotificationPreference: (
      state,
      action: PayloadAction<keyof State>
    ) => {
      state[action.payload] = true;
    },
    removedNotificationPreference: (
      state,
      action: PayloadAction<keyof State>
    ) => {
      state[action.payload] = false;
    },
  },
});

export default reducer;

export const { fetchedNotificationPreferences } = actions;

export const addNotificationPreference = (type: keyof State) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const publisherId = getState().publisher?.id;
  if (!publisherId) return;
  dispatch(actions.addedNotificationPreference(type));
  try {
    await api.addNotificationPreference(type, publisherId);
  } catch (e) {
    dispatch(flashMessage('Global__UnexpectedError', { isError: true }));
    dispatch(actions.removedNotificationPreference(type));
  }
};

export const removeNotificationPreference = (type: keyof State) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const publisherId = getState().publisher?.id;
  if (!publisherId) return;
  dispatch(actions.removedNotificationPreference(type));
  try {
    await api.removeNotificationPreference(type, publisherId);
  } catch (e) {
    dispatch(flashMessage('Global__UnexpectedError', { isError: true }));
    dispatch(actions.addedNotificationPreference(type));
  }
};

function camelToUnderscore(key: string) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}
