import { schema, normalize } from 'normalizr';
import camelize from 'camelize';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import values from 'lodash/values';
import { OtherAction } from './global';
import {
  fetchPublisherRequest,
  updatePublisherRequest,
} from 'api/publisherRequests';
import { identifySentryUser } from 'api/publisher';
import { identifyFullStoryUser } from 'api/user';
import sortPlatforms from 'helpers/sortPlatforms';
import { fetchedIdentities } from './identities';
import {
  fetchedAttachments,
  CreatedAction as AttachmentCreated,
  DeletedAction as AttachmentDeleted,
  TypeKeys as AttachmentKeys,
} from './attachments';
import { fetchedPublisher } from './publisher';
import Actionable from 'types/Actionable';
import Brand from 'types/Brand';
import Identity from 'types/Identity';
import Attachment from 'types/Attachment';
import Publisher from 'types/Publisher';
import PublisherRequest from 'types/PublisherRequest';
import ContentRequest from 'types/ContentRequest';
import LoggedInPublisher from 'types/LoggedInPublisher';
import Post from 'types/Post';
import Suggester from 'types/Suggester';
import { fetchedContactMethods } from './contactMethods';
import { RootState } from '.';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'redux/store';
import { fetchedSuggester } from './suggester';
import { fetchedBrand } from './brand';
import { fetchedLegacyInstagramIdentity } from './legacyInstagramIdentity';

export interface UpdateRequestFields {
  declineReason?: string;
  status?: string;
}

// Action Types
export enum TypeKeys {
  FETCHED = 'publisher/request/fetch/fulfilled',
  UPDATE = 'publisher/request/UPDATE',
  UPDATED = 'publisher/request/UPDATED',
  UPDATE_FAILED = 'publisher/request/UPDATE_FAILED',
  ACCOUNTS_FETCHED = 'accounts/request/FETCH',
}

interface FetchedPayload extends PublisherRequest {
  posts: Post[];
  brand: Brand;
  identities: Identity[];
  attachments?: Attachment[];
  actionable: Actionable;
  publisher: Publisher | LoggedInPublisher;
  publisherId: number;
  instructions: string;
  suggester: Suggester;
}

export interface FetchedAction {
  type: TypeKeys.FETCHED;
  payload: FetchedPayload;
  source: string;
}

export interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: { key: string; updates: UpdateRequestFields };
}

type ActionTypes =
  | FetchedAction
  | UpdatedAction
  | AttachmentCreated
  | AttachmentDeleted
  | OtherAction;

export const REQUEST_STATUS = {
  PENDING: 'pending',
  OPENED: 'opened',
  DECLINED: 'declined',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled',
  SUBMITTED: 'submitted',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
};

// Schema
const publisherRequestSchema = new schema.Entity(
  'publisherRequests',
  {},
  { idAttribute: 'requestKey' }
);

// Reducer
export interface State {
  keys: string[];
  byKey: { [key: string]: PublisherRequest };
}

const initialState = { byKey: {}, keys: [] };

export default (state: State = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.FETCHED: {
      const { payload } = action;
      if (!isEmpty(payload)) {
        const requestFields = [
          'id',
          'requestKey',
          'sentAt',
          'status',
          'actionable',
          'actionableType',
          'declinerReason',
          'loginRequired',
          'publisher',
          'subject',
          'instructions',
          'mediaType',
          'talkingPoints',
          'exampleContentUrl',
          'exampleContentContentType',
          'exampleContentTitle',
          'exampleContentDescription',
          'orientation',
          'videoLength',
          'contentRequestSubmissions',
          'expiresAt',
        ];
        const data = pick(payload, requestFields);
        const { result, entities } = normalize(data, publisherRequestSchema);
        const publisherRequest = entities.publisherRequests[result];
        publisherRequest.codeName = payload.suggester.codeName;

        if (payload.posts)
          publisherRequest.postIds = payload.posts.map((p) => p.id);

        if (payload.actionable.platforms)
          publisherRequest.actionable.platforms = sortPlatforms(
            publisherRequest.actionable.platforms
          );

        if (payload.actionable.platforms)
          publisherRequest.platformIds = payload.actionable.platforms.map(
            (p) => p.id
          );

        if (payload.attachments)
          publisherRequest.attachmentIds = payload.attachments.map((a) => a.id);

        return {
          byKey: { ...state.byKey, [result]: publisherRequest },
          keys: [...state.keys, result],
        };
      }

      return {};
    }

    case TypeKeys.UPDATED:
      const { key, updates } = action.payload;

      // Don't create new entries for updates
      if (!state.byKey[key]) return state;

      const updated = {
        [key]: { ...state.byKey[key], ...updates },
      };

      return { ...state, byKey: { ...state.byKey, ...updated } };

    case AttachmentKeys.CREATED: {
      const { requestKey, attachment } = action.payload;
      const request = { ...state.byKey[requestKey] } as ContentRequest;
      request.attachmentIds = [...request.attachmentIds, attachment.id];
      const updated = { [requestKey]: request };
      return { ...state, byKey: { ...state.byKey, ...updated } };
    }

    case AttachmentKeys.DELETED: {
      const { requestKey, ids } = action.payload;
      if (!requestKey) return state;

      const request = { ...state.byKey[requestKey] } as ContentRequest;
      request.attachmentIds = difference(request.attachmentIds, ids);
      const updated = { [requestKey]: request };
      return { ...state, byKey: { ...state.byKey, ...updated } };
    }

    default:
      return state;
  }
};

// Action Creators
export const fetch = createAsyncThunk<
  FetchedPayload,
  string,
  { state: RootState; dispatch: AppDispatch }
>(
  'publisher/request/fetch',
  async (requestKey: string, { rejectWithValue, dispatch, getState }) => {
    let data;
    try {
      data = camelize(await fetchPublisherRequest(requestKey));
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.message || e);
    }
    const { user, suggester } = getState();
    identifyFullStoryUser(user.current, data.publisher, suggester);
    identifySentryUser(data.user, data.publisher, data.suggester);
    dispatch(fetchedIdentities(data.identities));
    dispatch(fetchedPublisher(data.publisher));
    dispatch(fetchedContactMethods(data.publisher.contactMethods));
    dispatch(fetchedAttachments(data.attachments || []));
    if (!suggester) {
      dispatch(fetchedSuggester(data.suggester));
      dispatch(fetchedBrand(data.brand, data.suggester.codeName));
    }

    if (data.legacyInstagramIdentity) {
      dispatch(fetchedLegacyInstagramIdentity(data.legacyInstagramIdentity));
    }

    // Mark request as opened
    // get isUnopened(): boolean {
    //   const request = this.props.publisherRequest;
    //   return !!request && isUnread(request.status);
    // }
    // const { publisher } = this.props;
    // if (this.isUnopened && isLoggedIn(publisher as Publisher)) {
    //   this.props.setPublisherRequestOpened();
    // }

    //setPublisherRequestOpened: () =>
    // dispatch(
    //   updatePublisherRequest(requestKey, { status: REQUEST_STATUS.OPENED })
    // ),

    return data;
  }
);

export const update = (key: string, updates: UpdateRequestFields) => async (
  dispatch: Function
) => {
  dispatch({ type: TypeKeys.UPDATE, payload: { key, updates } });
  try {
    await updatePublisherRequest(key, updates);
    dispatch({ type: TypeKeys.UPDATED, payload: { key, updates } });
  } catch (error) {
    dispatch({ type: TypeKeys.UPDATE_FAILED, payload: { key, updates } });
  }
};

// Selectors
export const getPublisherRequestByKey = (
  state: { publisherRequests: State },
  key: string
): PublisherRequest | undefined => state.publisherRequests.byKey[key];

export const getPublisherRequestById = (
  state: { publisherRequests: State },
  id: number
) => {
  if (!state.publisherRequests.byKey) return;
  return values(state.publisherRequests.byKey).find((pr) => pr.id === id);
};
