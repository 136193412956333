import { get, post } from './shared';
import FacebookApiPage from 'types/FacebookApiPage';
import FacebookPageResult from 'types/FacebookPageResult';

export const getPages = async () => {
  const endpoint = 'publisher/facebook_pages/api_pages';
  return get<FacebookPageResult>(endpoint);
};

export const linkedPublishers = async () => {
  return get('publisher/facebook_pages/linked_publishers');
};

export const deletePage = async () => {
  const endpoint = 'publisher/facebook_pages/delete';
  return post(endpoint);
};

export const createPage = async (page: FacebookApiPage) => {
  const facebookPage = {
    name: page.name,
    image: page.picture.data.url,
    token: page.accessToken,
    platformUid: page.id,
  };
  const endpoint = 'publisher/facebook_pages';

  return post(endpoint, {
    facebookPage,
  });
};
