import React, { useState } from 'react';
import { useIntl } from 'hooks';
import * as api from 'api/mfa';
import camelize from 'camelize';
import flashMessage from 'helpers/flashMessage';
import { IconRefresh, IconCheck, IconClose, IconLoader } from 'icons';
import { clsx } from 'clsx';

interface Props {
  tempToken: string;
  mfaProtocol: string;
}

export default function SignInFormResendSms(props: Props) {
  const { tempToken, mfaProtocol } = props;
  const { t } = useIntl();

  const initialResult = { error: false, success: false, message: '' };
  const [result, setResult] = useState(initialResult);
  const [isLoading, setIsLoading] = useState(false);

  const handleResendSms = async () => {
    setIsLoading(true);

    try {
      const json = camelize(await api.resendSmsCode(mfaProtocol, tempToken));
      setIsLoading(false);
      if ('errors' in json) {
        setResult({
          error: true,
          success: false,
          message: t('Security__MfaLogin--ResendCode-Failure'),
        });
        setTimeout(() => setResult(initialResult), 3000);
      } else if ('success' in json) {
        setResult({
          error: false,
          success: true,
          message: t('Security__MfaLogin--ResendCode-Success'),
        });
        setTimeout(() => setResult(initialResult), 3000);
      } else {
        flashMessage('Global__UnexpectedError', { isError: true });
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
    }
  };

  return (
    <div
      className="mb-1.5 flex items-center justify-center"
      onClick={() => handleResendSms()}
    >
      <div className="flex items-center cursor-pointer">
        {result.success ? (
          <IconCheck className="w-1.5 h-1.5 mr-0.5 text-teal" />
        ) : result.error ? (
          <IconClose className="w-1.5 h-1.5 mr-0.5 text-error" />
        ) : isLoading ? (
          <IconLoader className="w-1.5 h-1.5 mr-0.5" />
        ) : (
          <IconRefresh className="w-1.5 h-1.5 mr-0.5 text-socialiePink" />
        )}
        <span
          className={clsx('text-14 leading-tight ', {
            'text-socialiePink hover:text-socialiePinkHover':
              !result.success && !result.error,
            'text-teal': result.success,
            'text-error': result.error,
          })}
        >
          {result.success || result.error
            ? result.message
            : t('Security__MfaLogin--ResendCode')}
        </span>
      </div>
    </div>
  );
}
