import React from 'react';
import { AccountConversionPage } from '../components';
import { useIntl } from 'hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/ducks';
import { Redirect } from 'react-router-dom';
import socialieLogo from 'icons/socialie.svg';
import routes from 'helpers/routes';
import { IconCheck } from 'icons';
import { FormattedHTMLMessage } from 'react-intl';
import { Btn } from 'components/shared';

interface Props {
  onContinue: () => void;
}

export default function AccountConversionSuccess({ onContinue }: Props) {
  const { user, currentPublisher, notificationPreferences } = useSelector(
    (state: RootState) => ({
      user: state.user.current,
      currentPublisher: state.publisher,
      notificationPreferences: state.notificationPreferences,
    })
  );
  const { t } = useIntl();

  if (!user || !currentPublisher) {
    return <Redirect to={routes.accountConversion.root} />;
  }

  if (!Object.values(notificationPreferences).some(Boolean)) {
    return <Redirect to={routes.accountConversion.notifications} />;
  }

  const contactMethod =
    user.email && user.phone ? 'both' : user.phone ? 'phone' : 'email';

  return (
    <AccountConversionPage
      renderBeforeHeading={
        <div className="mx-auto relative mb-4 w-11 h-11 p-0.5 se:w-10 se:h-10 tablet:w-14.5 tablet:h-14.5">
          <img
            src={socialieLogo}
            className="rounded-logo p-2 w-full h-full"
            alt="Socialie Logo"
          />

          <div className="absolute bottom-0 right-0 rounded-full bg-socialiePink p-0.5 w-3.5 h-3.5 shadow-logo tablet:w-4.5 tablet:h-4.5 ">
            <IconCheck className="block text-white w-full h-full bump-down-1" />
          </div>
        </div>
      }
    >
      <div className="mb-3">
        <h1 className="h1">
          <FormattedHTMLMessage
            id="AccountConversionSuccess__Heading"
            values={{ name: user.firstName }}
          />
        </h1>

        <div className="mt-2 text-18 text-dark">
          <div className="mb-1.5">
            {t('AccountConversionSuccess__SubHeading')}
          </div>

          <span className="font-bold">
            {t(`AccountConversionSuccess__ContactMethod--${contactMethod}`)}
          </span>
        </div>
      </div>

      <Btn onClick={onContinue}>{t('Button__Continue')}</Btn>
    </AccountConversionPage>
  );
}
