import { clsx } from 'clsx';
import FlyoutMenu from 'components/shared/FlyoutMenu';
import PublisherAvatar from 'components/shared/PublisherAvatar';
import routes from 'helpers/routes';
import { useBodyClassName, useCurrentSuggester, useIntl } from 'hooks';
import useIsScrolled from 'hooks/useIsScrolled';
import logoSocialie from 'images/logo-socialie-sq.svg';
import React, { Fragment } from 'react';
import { Link, Route } from 'react-router-dom';
import Publisher from 'types/Publisher';
import { GlobalNavLinks } from '../GlobalNav';
import signOut from 'helpers/auth/signOut';
import {
  IconGear,
  IconGearOutline,
  IconSignOut,
  IconHeadsetOutline,
  IconHeadsetFilled,
} from 'icons';

interface Props {
  publisher: Publisher;
  links: GlobalNavLinks;
  isContactModalOpen: boolean;
  showContactLink: boolean;
  onClickContact: () => void;
}

export default function GlobalNavDesktop(props: Props) {
  const {
    publisher,
    showContactLink,
    isContactModalOpen,
    onClickContact,
  } = props;
  const { t } = useIntl();
  const isScrolled = useIsScrolled(24);
  useBodyClassName('pt-12');
  const suggester = useCurrentSuggester();
  const links: GlobalNavLinks = [
    ...props.links,
    {
      name: 'Settings',
      href: routes.settings.root,
      icon: IconGearOutline,
      iconActive: IconGear,
    },
  ];

  return (
    <div
      className={clsx(
        'fixed top-0 left-0 right-0 z-50 bg-white border-0 border-b border-solid border-grey8 h-10 transition-all',
        isScrolled ? 'transform -translate-y-4 pt-3' : 'pt-2'
      )}
    >
      <div
        className={clsx(
          'flex items-center justify-between relative px-2 transition-all mx-auto max-w-[1600px]',
          isScrolled && 'transform translate-y-1'
        )}
      >
        <div
          className={clsx('origin-left transition-all', {
            'scale-75': isScrolled,
          })}
        >
          {suggester?.logoUrl ? (
            <img
              src={suggester?.logoUrl}
              className="w-6 h-6 rounded-full border-default"
              alt={suggester?.accountName}
            />
          ) : (
            <div className="rounded-full border-default w-6 h-6 p-0.5">
              <img
                src={logoSocialie}
                alt="Socialie Logo"
                className="w-full h-full"
              />
            </div>
          )}
        </div>

        {links.length > 1 && (
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <div className="relative flex items-center gap-0.25">
              {links.map((link, index) => {
                const isLastItem = index === links.length - 1;
                return (
                  <Fragment key={link.href}>
                    {isLastItem && showContactLink && (
                      <NavItem
                        isActive={isContactModalOpen}
                        icon={IconHeadsetOutline}
                        iconActive={IconHeadsetFilled}
                        isScrolled={isScrolled}
                        onClick={onClickContact}
                        name={t('GlobalNav__Contact')}
                      />
                    )}
                    <Route
                      key={link.href}
                      path={link.href}
                      exact={link.exact}
                      children={({ match }) => {
                        const isActive = !!match;
                        return (
                          <NavItem
                            {...link}
                            isActive={isActive && !isContactModalOpen}
                            isScrolled={isScrolled}
                            name={t(`GlobalNav__${link.name}`)}
                          />
                        );
                      }}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        )}

        <FlyoutMenu
          className="min-w-26"
          placement="bottom-end"
          itemClassName="border-b-default last:border-b-0"
          renderButton={(onClick) => (
            <button
              type="button"
              onClick={onClick}
              className={clsx(
                'flex items-center relative bg-greyBg rounded-full h-6 py-1 pl-6 pr-3 transition-all',
                isScrolled && 'bg-transparent'
              )}
            >
              <span
                className={clsx(
                  'absolute w-4 h-4 mr-2 transition-all',
                  isScrolled ? 'right-0' : 'left-1'
                )}
              >
                <PublisherAvatar
                  theme="dark"
                  src={publisher.image}
                  name={publisher.name}
                />
              </span>

              <span
                className={clsx(
                  'flex-1 text-14 leading-none transition-all origin-right',
                  isScrolled && 'transform scale-x-0 opacity-0'
                )}
              >
                <span className="block text-dark font-semibold">
                  {publisher.name}
                </span>
                <span className="block text-light">
                  {publisher.accountName}
                </span>
              </span>
            </button>
          )}
        >
          <FlyoutMenu.Item icon={IconGearOutline} to={routes.settings.root}>
            {t('GlobalNav__Settings')}
          </FlyoutMenu.Item>

          {showContactLink && (
            <FlyoutMenu.Item icon={IconHeadsetOutline} onClick={onClickContact}>
              {t('GlobalNav__Contact')}
            </FlyoutMenu.Item>
          )}

          <FlyoutMenu.Item icon={IconSignOut} onClick={() => signOut()}>
            {t('Button__SignOut')}
          </FlyoutMenu.Item>
        </FlyoutMenu>
      </div>
    </div>
  );
}

interface NavItemProps {
  href?: string;
  onClick?: () => void;
  name: string;
  icon: React.ComponentType<{ className?: string }>;
  iconActive?: React.ComponentType<{ className?: string }>;
  isActive: boolean;
  isScrolled: boolean;
}

const containerClassNames = (isActive: boolean, isScrolled: boolean) =>
  clsx(
    'flex flex-col items-center h-7 overflow-hidden justify-start flex-1 px-2 rounded-lg transition-all',
    isActive
      ? 'bg-greyBg text-dark'
      : 'text-light hover:text-dark hover:bg-grey9/50',
    isScrolled ? 'h-4 pt-0.25' : 'h-7 pt-0.75'
  );

function NavItem(props: NavItemProps) {
  const { name, href, onClick, icon, iconActive, isActive, isScrolled } = props;
  const innards = (
    <>
      {React.createElement(isActive && iconActive ? iconActive : icon, {
        className: 'flex-shrink-0 w-3 h-3 text-dark',
      })}
      <span
        className={clsx(
          'font-semibold text-12 whitespace-nowrap transition-opacity',
          isScrolled && 'opacity-0'
        )}
      >
        {name}
      </span>
    </>
  );

  if (href) {
    return (
      <Link to={href} className={containerClassNames(isActive, isScrolled)}>
        {innards}
      </Link>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={containerClassNames(isActive, isScrolled)}
    >
      {innards}
    </button>
  );
}
