import { post } from './shared';
import ContactMethod from 'types/ContactMethod';
import UserApiPayload from 'types/UserApiPayload';

export const createContactMethod = async (contactMethod: ContactMethod) => {
  const json = await post('publisher/contact_methods', {
    contactMethod,
  });
  return json;
};

export const updateContactMethod = async (
  id: number,
  updates: Partial<ContactMethod>
) => {
  const endpoint = `publisher/contact_methods/${id}`;
  return post(endpoint, { contactMethod: updates }, 'PUT');
};

export const removeContactMethod = async (id: number) => {
  const endpoint = `publisher/contact_methods/${id}`;
  return post(endpoint, {}, 'DELETE');
};

export const purgeContactMethods = () =>
  post<UserApiPayload>('publisher/contact_methods/purge');
