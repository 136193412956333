import React, { useState, useEffect } from 'react';
import { clsx } from 'clsx';
import { IconLoader } from 'icons';

interface Props {
  isLoading?: boolean;
  initialLoad?: boolean;
  delay?: number;
}

export default function Loader({
  isLoading = true,
  initialLoad,
  delay = 500,
}: Props) {
  // Show loader after delay
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timer = window.setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!isLoading || !showLoader) return null;

  return (
    <div
      className={clsx('p-2 text-grey5', { 'pt-8': initialLoad })}
      aria-busy="true"
      aria-live="polite"
    >
      <IconLoader className="mx-auto w-6 h-6" />
    </div>
  );
}
