import { clsx } from 'clsx';
import { IconCaretLeft } from 'icons';
import React, { useEffect, useRef } from 'react';
import Swiper, { Keyboard } from 'swiper';
import PartialPublisherRequest from 'types/PartialPublisherRequest';
import { SuggestionsBrowserSwiperSlide } from './components';

interface Props {
  publisherRequests: PartialPublisherRequest[];
  activeIdx: number;
  onChange: (id: number) => void;
  onLongPress: () => void;
}

Swiper.use([Keyboard]);

export default function SuggestionsBrowserSwiper(props: Props) {
  const { publisherRequests, activeIdx, onChange, onLongPress } = props;
  const swiperContainer = useRef<HTMLDivElement>(null);
  const swiper = useRef<Swiper | null>(null);
  const slideCount = publisherRequests.length;

  useEffect(
    () => {
      if (!swiperContainer.current) return;

      swiper.current = new Swiper(swiperContainer.current, {
        initialSlide: activeIdx,
        keyboard: { enabled: true },
        slidesPerView: 1,
        centeredSlides: true,
        on: {
          slideChange: (s) => {
            // pause videos when navigating through slides
            const videos = swiperContainer.current!.querySelectorAll('video');
            Array.from(videos).forEach((v) => v.pause());

            // set activeIdx
            onChange(s.activeIndex);
          },
        },
      });

      return () => {
        swiper.current?.destroy(true, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slideCount]
  );

  return (
    <div className="swiper-container h-full" ref={swiperContainer}>
      {activeIdx !== publisherRequests.length - 1 && (
        <Arrow direction="next" onClick={() => swiper.current?.slideNext()} />
      )}

      {activeIdx !== 0 && (
        <Arrow direction="prev" onClick={() => swiper.current?.slidePrev()} />
      )}

      <div className="swiper-wrapper h-full">
        {publisherRequests.map((publisherRequest, idx) => {
          return (
            <SuggestionsBrowserSwiperSlide
              key={idx}
              publisherRequest={publisherRequest}
              onLongPress={onLongPress}
              preventLoading={Math.abs(idx - activeIdx) > 1}
            />
          );
        })}
      </div>
    </div>
  );
}

interface ArrowProps {
  direction: 'next' | 'prev';
  onClick: () => void;
}

function Arrow({ direction, onClick }: ArrowProps) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'transition-fast fixed rounded-full bg-grey2 w-4.5 h-4.5 hidden text-light tablet:block hover:text-white',
        {
          'ml-2 left-0': direction === 'prev',
          'mr-2 right-0': direction === 'next',
        }
      )}
      style={{ top: '49%', zIndex: 1999 }}
    >
      <span className="flex items-center justify-center p-1.25 w-full h-full">
        <IconCaretLeft
          className="block w-full h-full"
          style={{
            transform:
              direction === 'next'
                ? 'translateX(1px) rotate(180deg)'
                : 'translateX(-1px)',
          }}
        />
      </span>
    </button>
  );
}
