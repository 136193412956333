import sortBy from 'lodash/sortBy';
import Platform from 'types/Platform';

export default (platforms: Platform[]) => {
  const rank = {
    twitter: 1,
    facebook: 2,
    linkedin: 3,
    instagram: 4,
  };

  const sortedPlatforms = sortBy(platforms, (platform: Platform) => {
    return rank[platform.id];
  });

  return sortBy(sortedPlatforms, (platform: Platform) =>
    platform.authorized ? 0 : 1
  );
};
