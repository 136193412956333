import { Styles as ReactSelectStyles } from 'react-select/lib/styles';

interface Opts {
  theme?: string;
  isInvalid?: boolean;
}

const styles = (opts: Opts): Partial<ReactSelectStyles> => ({
  control: (base, state) => ({
    ...base,
    display: 'flex',
    borderRadius: '4px 0 0 4px',
    height: '44px',
    minHeight: '44px',
    lineHeight: '24px',
    fontSize: '16px',
    color: '#101820',
    backgroundColor: '#fff',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: opts.isInvalid ? '#ff5252' : 'rgba(16,24,32, 0.12)',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      borderColor: opts.isInvalid ? '#ff5252' : 'rgba(16,24,32, 0.12)',
    },
  }),
  menu: (base) => ({
    ...base,
    width: '300px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16)',
    borderColor: 'rgba(16,24,32, 0.12)',
    backgroundColor: '#fff',
    zIndex: 9999,
    textAlign: 'left',
    fontSize: '14px',
  }),
  option: (base) => ({
    ...base,
    color: '#101820',
    backgroundColor: '#fff',
    '&:hover': { backgroundColor: '#f0f2f5' },
  }),
  indicatorSeparator: (base) => ({
    display: 'none',
  }),
});

export default styles;
