import React from 'react';
import Waypoint from 'react-waypoint';

interface State {
  didEnter: boolean;
  isVisible: boolean;
  didLeave: boolean;
}

interface Props {
  render?: (scrollyProps: State) => any;
  children?: (scrollyProps: State) => any;
}

export default class Scrolly extends React.Component<Props, State> {
  state = { didEnter: false, isVisible: false, didLeave: false };

  onEnter = () => {
    this.setState({ didEnter: true, isVisible: true });
  };

  onLeave = () => this.setState({ didLeave: true, isVisible: false });

  render() {
    const renderFunc = this.props.render || this.props.children;
    if (!renderFunc) return null;

    return (
      <Waypoint onEnter={this.onEnter} onLeave={this.onLeave}>
        {renderFunc(this.state)}
      </Waypoint>
    );
  }
}
