import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/ducks';
import { useIntl } from 'hooks';
import { Btn, ManageNotifications } from 'components/shared';
import { AccountConversionPage } from '../components';
import { FormattedMessage } from 'react-intl';

interface Props {
  onContinue: () => void;
}

export default function AccountConversionNotifications(props: Props) {
  const { onContinue } = props;
  const { t } = useIntl();
  const { accountName, canContinue, publisher } = useSelector(
    (state: RootState) => ({
      accountName: state.publisher?.accountName,
      publisher: state.publisher,
      canContinue: Object.values(state.notificationPreferences).some(Boolean),
    })
  );

  return (
    <AccountConversionPage
      heading={t('AccountConversion__NotificationsHeading')}
      subheading={
        <FormattedMessage
          id="AccountConversion__NotificationsSubHeading"
          values={{
            accountName,
            pubName: <strong>{publisher?.name}</strong>,
          }}
        />
      }
    >
      <div className="mb-2.5 text-left">
        <ManageNotifications socialie />
      </div>

      {canContinue && <Btn onClick={onContinue}>{t('Button__Continue')}</Btn>}
    </AccountConversionPage>
  );
}
