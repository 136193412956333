import React from 'react';
import { RootState } from 'redux/ducks';
import socialieLogo from 'icons/socialie.svg';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'redux/ducks/brand';
import { IconLink } from 'icons';

interface Props {
  className?: string;
  sizeClassNames?: string;
}

export default function LinkedBrandLogos({ className, sizeClassNames }: Props) {
  const { brand, suggester } = useSelector((state: RootState) => ({
    suggester: state.suggester,
    brand: getCurrentBrand(state),
  }));

  const imgClassNames = `rounded-logo ${sizeClassNames} mr-1.5 last:mr-0`;

  return (
    <div className={`flex items-center mb-5 ${className}`}>
      {suggester && (
        <>
          <img
            src={brand.logoUrl}
            className={imgClassNames}
            alt={suggester.accountName}
          />

          <IconLink className="block mr-1.5 text-light w-2.5 h-2.5" />
        </>
      )}

      <img
        src={socialieLogo}
        className={`${imgClassNames} p-1`}
        alt="Socialie"
      />
    </div>
  );
}

LinkedBrandLogos.defaultProps = {
  sizeClassNames: 'w-7.5 h-7.5',
};
