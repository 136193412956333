import { athleteDismiss, athleteOptIn } from 'api/athleteOptIn';
import flashMessage from 'helpers/flashMessage';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import { updated as updatedPublisher } from 'redux/ducks/publisher';
import { useAppDispatch } from 'redux/store';
import Publisher from 'types/Publisher';
import { FormattedHTMLMessage } from 'react-intl';
import Btn from './Btn';

interface Props {
  publisher: Publisher;
  onOptIn?: () => void;
  onDismiss?: () => void;
  allowDismiss?: boolean;
}

export default function AthleteOptIn(props: Props) {
  const { t } = useIntl();
  const { publisher, onOptIn, onDismiss, allowDismiss } = props;

  const [isOptingIn, setIsOptingIn] = useState(false);
  const dispatch = useAppDispatch();

  const handleOptIn = async () => {
    setIsOptingIn(true);
    try {
      await athleteOptIn();
      dispatch(updatedPublisher({ ...publisher, athlete: true }));
      onOptIn?.();
    } catch (e) {
      flashMessage('Global__UnexpectedError', { error: true });
    }
  };

  const handleDismiss = () => {
    try {
      athleteDismiss();
    } finally {
      onDismiss?.();
    }
  };

  return (
    <div className="tablet:w-90 tablet:mx-auto tablet:pr-30">
      <div className="text-48 mb-6 leading-none phablet:text-80 text-dark font-semibold">
        <FormattedHTMLMessage id="AthleteOptIn__Heading" />
      </div>

      <div className="mb-5 text-dark tablet:max-w-46">
        {t('AthleteOptIn__Description', {
          suggester: publisher?.accountName,
        })}
      </div>

      <div className="flex flex-col items-start space-y-3">
        <Btn onClick={handleOptIn} variant="inlineBlock" isLoading={isOptingIn}>
          {t('AthleteOptIn__Accept')}
        </Btn>

        {allowDismiss && (
          <Btn color="grey" disabled={isOptingIn} onClick={handleDismiss}>
            {t('AthleteOptIn__Dismiss')}
          </Btn>
        )}
      </div>
    </div>
  );
}
