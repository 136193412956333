import React, { forwardRef } from 'react';
import { clsx } from 'clsx';
import Field from './Field';
import { useState } from 'react';
import InputContainer from './InputContainer';
import { useRef } from 'react';
import mergeRefs from 'react-merge-refs';

interface Props
  extends React.ComponentProps<'input'>,
    Omit<
      React.ComponentProps<typeof InputContainer>,
      'multiline' | 'onClickIcon'
    > {
  label?: React.ReactNode;
  description?: string;
  skipField?: boolean;
}

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    label,
    required,
    name,
    className,
    errors,
    isInvalid,
    description,
    prefix,
    leftIcon,
    rightIcon,
    leftIconClassNames,
    rightIconClassNames,
    onClear,
    skipField,
    theme,
    value,
    type = 'text',
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const renderInput = () => (
    <InputContainer
      value={value}
      errors={errors}
      onClear={onClear}
      theme={theme}
      isFocused={isFocused}
      isInvalid={isInvalid}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      rightIconClassNames={rightIconClassNames}
      leftIconClassNames={leftIconClassNames}
      onClickIcon={() => inputRef.current?.click()}
      prefix={prefix}
      disabled={inputProps.disabled}
      maxLength={inputProps.maxLength}
      isPassword={type === 'password'}
    >
      <input
        ref={mergeRefs([ref, inputRef])}
        value={value}
        type={type}
        {...inputProps}
        className={clsx(
          'appearance-none border-0 w-full h-full font-sans bg-transparent text-dark outline-none focus:outline-none focus-visible:outline-none disabled:opacity-50 placeholder-grey5',
          { 'text-center': theme?.textAlign === 'center' },
          className
        )}
        aria-required={required}
        name={name}
        onFocus={(e) => {
          setIsFocused(true);
          inputProps.onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          inputProps.onBlur?.(e);
        }}
      />
    </InputContainer>
  );

  if (skipField) return renderInput();

  return (
    <Field
      label={label}
      name={name}
      errors={errors}
      description={description}
      required={required}
    >
      {renderInput()}
    </Field>
  );
});

TextInput.displayName = 'TextInput';
export default TextInput;
