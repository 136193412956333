import { OtherAction } from './global';
import { fetchedBrand } from './brand';
import { fetchedContactMethods } from './contactMethods';
import { fetchedIdentities } from './identities';
import { fetchedSuggester } from './suggester';
import { fetchUserSuccess } from './user';
import * as api from 'api/publisher';
import camelize from 'camelize';
import Publisher from 'types/Publisher';
import { push } from 'connected-react-router';
import routes from 'helpers/routes';
import routeFor from 'helpers/routeFor';
import { fetchedNotificationPreferences } from './notificationPreferences';
import { fetchedLegacyPublisher } from './legacyPublisher';
import { detectedReturningUser } from './ui/isReturningUser';
import { identifyFullStoryUser } from 'api/user';
import { isPWADomain, isPWABrowser } from 'client';
import changeSubdomain from 'helpers/changeSubdomain';
import { fetchedFeatures } from './features';

// Action Types
export enum TypeKeys {
  FETCHED = 'publisher/publisher/FETCHED',
  UPDATED = 'publisher/publisher/UPDATED',
  CLEARED = 'publisher/publisher/CLEARED',
  UPDATE = 'publisher/publisher/UPDATE',
  DELETE_SLACK = 'publisher/publisher/DELETE_SLACK',
  DELETE_GETTY = 'publisher/publisher/DELETE_GETTY',
}

export interface FetchedAction {
  type: TypeKeys.FETCHED;
  payload: { publisher: Publisher | null };
}

export interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: {
    publisher: Publisher;
  };
}

export interface ClearedAction {
  type: TypeKeys.CLEARED;
}

type ActionTypes = FetchedAction | UpdatedAction | ClearedAction | OtherAction;

// Reducer

export default (state: Publisher | null = null, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.FETCHED:
    case TypeKeys.UPDATED: {
      const { publisher } = action.payload;
      if (publisher) return publisher;
      return state;
    }

    case TypeKeys.CLEARED: {
      return null;
    }

    default:
      return state;
  }
};

// Actions
export const updated = (values: Partial<Publisher>) => ({
  type: TypeKeys.UPDATED,
  payload: { publisher: values },
});

export const fetchedPublisher = (publisher: Publisher | null) => ({
  type: TypeKeys.FETCHED,
  payload: { publisher },
});

export const clearedPublisher = () => ({
  type: TypeKeys.CLEARED,
});

export const fetchCurrentPublisher = (publisherCode?: string) => async (
  dispatch: Function
) => {
  const json = camelize(await api.fetchCurrentPublisher(publisherCode));

  // If they got a link to publish.socialie but they're on a regular browser,
  // send them to the branded subdomain
  if (publisherCode && isPWADomain && !isPWABrowser && json.suggester) {
    const path = json.requestKey
      ? routeFor(routes.publisherRequests.show, json.requestKey)
      : routeFor(routes.publisherCode, publisherCode);
    changeSubdomain(json.suggester.codeName, path);
    return { isRedirecting: true };
  }

  // when the response for a PublisherCode has a requestKey, route them to it
  if (publisherCode && json.requestKey) {
    dispatch(push(routeFor(routes.publisherRequests.show, json.requestKey)));
    return { isRedirecting: true };
  }

  identifyFullStoryUser(json.user, json.publisher, json.suggester);
  api.identifySentryUser(json.user, json.publisher, json.suggester);
  dispatch(fetchedIdentities(json.identities));

  if (json.suggester) {
    dispatch(fetchedSuggester(json.suggester));
    dispatch(fetchedBrand(json.brand, json.suggester.codeName));
  }

  dispatch(fetchedContactMethods(json.publisher.contactMethods));
  dispatch(fetchUserSuccess(json.user));
  if (json.notificationPreferences) {
    dispatch(fetchedNotificationPreferences(json.notificationPreferences));
  }
  dispatch(fetchedLegacyPublisher(json.legacyPublisher));
  dispatch(fetchedFeatures(json.features || {}));

  // A user might be logged out but have been logged in in the past
  if (json.returningUser) dispatch(detectedReturningUser());

  dispatch(fetchedPublisher(json.publisher));
};

export const updateCurrentPublisher = (values: Partial<Publisher>) => async (
  dispatch: Function,
  getState: Function
) => {
  dispatch({ type: TypeKeys.UPDATE, payload: { values } });
  const publisher = getState().publisher;
  await api.updateCurrentPublisher(publisher.id, values);
  return dispatch(updated({ ...publisher, ...values }));
};
