import { Modal, PublisherSelect } from 'components/shared';
import { useIntl } from 'hooks';
import React from 'react';
import AddAccountModal from './AddAccountModal';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  allowCreate?: boolean;
}

export default function PublisherSelectModal(props: Props) {
  const { isOpen, onRequestClose, allowCreate } = props;
  const { t } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      theme="bottom"
      contentLabel={t('PublisherSelect__Heading')}
      renderHeading={t('PublisherSelect__Heading')}
    >
      <div className="text-left">
        <PublisherSelect />

        {allowCreate && (
          <div className="mt-2.5 text-center">
            <AddAccountModal />
          </div>
        )}
      </div>
    </Modal>
  );
}

PublisherSelectModal.defaultProps = {
  allowCreate: true,
};
