import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/ducks';
import { PublisherListRow } from 'components/shared';
import PublisherAccount from 'types/PublisherAccount';
import changeAccount from 'helpers/auth/changeAccount';
import { IconCaretLeft, IconLoader } from 'icons';

interface Props {
  publishers?: PublisherAccount[];
}

export default function PublisherSelect(props: Props) {
  const [loadingId, setLoadingId] = useState(0);
  const { user, suggester, currentPublisher } = useSelector(
    (state: RootState) => ({
      user: state.user.current,
      suggester: state.suggester,
      currentPublisher: state.publisher,
    })
  );

  if (!user) return null;

  const inGroupPublishers = suggester
    ? user.publishers
        .filter(
          (p) =>
            p.suggesterId === suggester.id ||
            p.parentSuggesterId === suggester.id
        )
        .sort((p) => (p.id === currentPublisher?.id ? -1 : 1))
    : [];

  const outGroupPublishers = suggester
    ? user.publishers.filter(
        (p) =>
          p.suggesterId !== suggester.id && p.parentSuggesterId !== suggester.id
      )
    : [];

  const handleSelect = (publisher: PublisherAccount) => {
    setLoadingId(publisher.id);
    changeAccount({ publisherId: publisher.id, brand: publisher.brand });
  };

  const publishers =
    props.publishers || [inGroupPublishers, outGroupPublishers].flat();

  return (
    <>
      {publishers.map((publisher) => (
        <div
          key={publisher.id}
          className={`cursor-pointer ${!!loadingId ? 'opacity-75' : ''}`}
        >
          <PublisherListRow
            onClick={() => handleSelect(publisher)}
            isCurrentPublisher={publisher.id === currentPublisher?.id}
            publisher={publisher}
            renderActions={
              publisher.id === currentPublisher?.id ? null : (
                <span className="bump-down-1">
                  {loadingId === publisher.id ? (
                    <IconLoader className="w-2 h-2" />
                  ) : (
                    <IconCaretLeft className="w-2 h-2 text-dark transform rotate-180" />
                  )}
                </span>
              )
            }
          />
        </div>
      ))}
    </>
  );
}
