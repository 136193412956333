import { get, post } from './shared';
import NewUserParams from 'types/NewUserParams';
import User from 'types/User';
import UpdateUserParams from 'types/UpdateUserParams';
import Publisher from 'types/Publisher';
import NotificationPreferences from 'types/NotificationPreferences';
import UserApiPayload from 'types/UserApiPayload';
import Suggester from 'types/Suggester';
import LogInApiPayload from 'types/LogInApiPayload';

export const fetchCurrentUser = () =>
  get<{
    user?: User;
    publisher?: Publisher;
    notificationPreferences?: NotificationPreferences;
  }>('publisher/users/current');

export const createUser = (params: NewUserParams) =>
  post<{ errors?: Partial<NewUserParams> } | UserApiPayload>(
    'publisher/users',
    { user: params }
  );

export const updateUser = (params: Partial<UpdateUserParams>) =>
  post<UserApiPayload | { errors?: Partial<UpdateUserParams> }>(
    'publisher/users',
    params,
    'PUT'
  );

export const checkContactValue = (contactValue: string) =>
  post<{ exists?: boolean; valid: boolean }>(
    'publisher/users/validate_contact_method',
    {
      contactValue,
    }
  );

export const logIn = (params: { contactValue: string; password: string }) =>
  post<LogInApiPayload>('publisher/users/log-in', params);

export const acceptEula = async () => {
  const endpoint = `publisher/users/accept-eula`;
  return post(endpoint);
};

export const identifyFullStoryUser = (
  user?: User | null,
  publisher?: Publisher | null,
  suggester?: Suggester | null
) => {
  const FS = window['FS'];
  if (!FS || !FS.identify) return;

  const suggesterData = suggester && {
    suggesterId: suggester.id,
    suggesterName: suggester.accountName,
  };

  if (user) {
    FS.identify(`User-${user.id}`, {
      displayName: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      publisherId: publisher?.id,
      publisherName: publisher?.name,
      ...suggesterData,
    });
  } else if (publisher) {
    FS.identify(`LegacyPublisher-${publisher.id}`, {
      displayName: publisher.name,
      ...suggesterData,
    });
  } else {
    FS.identify(false);
  }
};
