import { get, post } from './shared';
import InstagramGraphAccount from 'types/InstagramGraphAccount';
import camelize from 'camelize';
import Identity from 'types/Identity';

export const getAccounts = async () => {
  const endpoint = 'publisher/instagram-accounts';
  return camelize(await get<InstagramGraphAccount[]>(endpoint));
};

export const selectAccount = async (
  platformUid: InstagramGraphAccount['id']
) => {
  const endpoint = 'publisher/instagram-accounts';
  return camelize(
    await post<Identity | { errors: string }>(endpoint, { platformUid })
  );
};
