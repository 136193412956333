import React, { useEffect, useCallback } from 'react';
import useDidMount from '@rooks/use-did-mount';
import { matchPath, useLocation } from 'react-router-dom';
import {
  AccountConversionUser,
  AccountConversionSecondaryContact,
  AccountConversionNotifications,
  AccountConversionSuccess,
  AccountConversionPossiblePublishers,
} from './pages';
import { Modal } from 'components/shared';
import { useIntl } from 'hooks';
import routes from 'helpers/routes';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  openAccountConversion,
  closeAccountConversion,
  setAccountConversionStep,
} from 'redux/ducks/ui/accountConversion';
import trackCustomEvent from 'helpers/trackCustomEvent';

export default function AccountConversion() {
  const { t } = useIntl();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user, publisher, legacyPublisher, isOpen, step } = useAppSelector(
    (state) => ({
      user: state.user.current,
      legacyPublisher: state.legacyPublisher,
      publisher: state.publisher,
      isOpen: state.ui.accountConversion.isOpen,
      step: state.ui.accountConversion.step,
    })
  );

  const setStep = useCallback(
    (nextStep: typeof step) => dispatch(setAccountConversionStep(nextStep)),
    [dispatch]
  );

  const skippingModal =
    !!matchPath(location.pathname, routes.auth.resetPassword) ||
    location.pathname === routes.auth.facebookPageLogin;

  const isRequired =
    !skippingModal &&
    !!legacyPublisher &&
    (!user || !user.publishers.find((p) => p.id === legacyPublisher.id));

  const isAlreadyConverted =
    user &&
    ((publisher && !legacyPublisher) ||
      (legacyPublisher && publisher && legacyPublisher.id === publisher.id));

  const userIsPresent = !!user;

  useDidMount(() => {
    if (isRequired) {
      trackCustomEvent('conversionStarted');
      dispatch(openAccountConversion());
    }
  });

  // Guard the steps
  useEffect(() => {
    if (!userIsPresent && step !== 'user') {
      setStep('user');
      return;
    }

    if (userIsPresent && !isAlreadyConverted && step !== 'user') {
      setStep('user');
    }
  }, [userIsPresent, isAlreadyConverted, step, setStep]);

  const suppressModal = location.pathname.match(routes.onboarding.root);

  return (
    <Modal
      onRequestClose={
        step === 'possible-publishers'
          ? () => dispatch(closeAccountConversion())
          : undefined
      }
      isOpen={isOpen && !suppressModal}
      contentLabel={t('AccountConversion__IdentifyHeading')}
      contentsClassName="max-w-52"
    >
      {step === 'user' ? (
        <AccountConversionUser onSuccess={() => setStep('secondary-contact')} />
      ) : step === 'secondary-contact' ? (
        <AccountConversionSecondaryContact
          onSuccess={() => setStep('notifications')}
        />
      ) : step === 'notifications' ? (
        <AccountConversionNotifications onContinue={() => setStep('success')} />
      ) : step === 'success' ? (
        <AccountConversionSuccess
          onContinue={
            user?.possiblePublishers.length
              ? () => setStep('possible-publishers')
              : () => dispatch(closeAccountConversion())
          }
        />
      ) : step === 'possible-publishers' ? (
        <AccountConversionPossiblePublishers
          onContinue={() => dispatch(closeAccountConversion())}
        />
      ) : null}
    </Modal>
  );
}
