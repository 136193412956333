import React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';

interface Props {
  values: String[];
}

const ArrayToSentence = ({ values, intl }: Props & InjectedIntlProps) => {
  const and = intl.formatMessage({ id: 'Global__And' });
  let joined: String;

  if (!values.length) return null;
  if (values.length === 1) return <span>{values[0]}</span>;

  if (values.length === 2) {
    joined = `${values[0]} ${and} ${values[1]}`;
  } else {
    const firstTwo = `${values.slice(0, -1).join(', ')},`;
    const last = values[values.length - 1];
    joined = `${firstTwo} ${and} ${last}`;
  }

  return <span>{joined}</span>;
};

export default injectIntl(ArrayToSentence);
