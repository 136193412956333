import FacebookApiPage from 'types/FacebookApiPage';
import { OtherAction } from './global';

export enum TypeKeys {
  PAGES_FETCHED = 'publisher/facebookPages/RETRIEVED',
  PAGE_CREATED = 'publisher/facebookPages/FACEBOOK_PAGE_CREATED',
  PAGE_DELETED = 'publisher/facebookPages/FACEBOOK_PAGE_DELETED',
  NO_PAGES_MODAL_DISMISSED = 'publisher/facebookPages/NO_PAGES_MODAL_DISMISSED',
}

export interface PagesFetchedAction {
  type: TypeKeys.PAGES_FETCHED;
  payload: {
    pages: FacebookApiPage[];
  };
}

export interface NoPagesModalAction {
  type: TypeKeys.NO_PAGES_MODAL_DISMISSED;
}

type ActionTypes = PagesFetchedAction | NoPagesModalAction | OtherAction;

export interface State {
  pages?: FacebookApiPage[];
  showNoPagesModal?: boolean;
}

// reducer
export default (state: State = {}, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.PAGES_FETCHED:
      const { pages } = action.payload;
      const showNoPagesModal = pages && pages.length === 0;
      return { pages, showNoPagesModal };

    case TypeKeys.NO_PAGES_MODAL_DISMISSED:
      return { ...state, showNoPagesModal: false };

    default:
      return state;
  }
};

export const dismissNoPagesModal = () => (dispatch: Function) => {
  return dispatch({ type: TypeKeys.NO_PAGES_MODAL_DISMISSED });
};
