import { get, post } from './shared';
import { UpdateRequestFields } from 'redux/ducks/publisherRequests';
import SuggestionsFilter from 'types/SuggestionsFilter';
import queryString from 'query-string';
import PartialPublisherRequest from 'types/PartialPublisherRequest';
import PartialPublisherRequestMetadata from 'types/PartialPublisherRequestMetadata';

export const fetchPublisherRequest = async (
  key: string,
  authToken?: string
) => {
  let endpoint = `publisher/requests/${key}`;
  if (authToken) {
    endpoint = endpoint + `?auth_token=${authToken}`;
  }
  return get(endpoint);
};

export const fetchAllPublisherRequests = async (
  page: number,
  filter?: SuggestionsFilter
) => {
  const filterParams = queryString.stringify(filter, {
    arrayFormat: 'bracket',
  });
  return get<{
    requests: PartialPublisherRequest[];
    metadata: PartialPublisherRequestMetadata;
  }>(`publisher/requests?page=${page}&${filterParams}`);
};

export const updatePublisherRequest = async (
  key: string,
  updates: UpdateRequestFields
) => {
  const endpoint = `publisher/requests/${key}`;
  return post(endpoint, updates, 'PUT');
};

export const resendPublisherRequest = async (
  publisherRequestKey: string,
  phone: string,
  type: string = 'sms'
) => {
  const json = await post(
    `publisher/requests/${publisherRequestKey}/send_reminder/${type}`,
    { phone }
  );
  return json;
};

export const fetchSuggestionsFilterData = async () => {
  const endpoint = 'publisher/requests/filter-data';
  return get(endpoint);
};

export const bulkDestroyPublisherRequests = async (keys: string[]) => {
  const endpoint = 'publisher/requests';
  return post(endpoint, { keys }, 'DELETE');
};
