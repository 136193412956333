import { DirectUpload } from '@rails/activestorage';
import { useRef, useState } from 'react';

type UploadStatus = 'WAITING' | 'UPLOADING' | 'SUCCESS' | 'CANCELED' | 'FAILED';
const UPLOAD_URL = `${
  import.meta.env.REACT_APP_API_SERVER_URL
}/publisher/direct_uploads`;

export default function useDirectUpload(callbacks: {
  onUploadStart?: () => void;
  onUploadSuccess?: (signedId: string) => void;
  onUploadFailure?: (error?: any) => void;
  onUploadProgress?: (progress: number) => void;
}) {
  const upload = useRef<DirectUpload | null>(null);
  const xhr = useRef<XMLHttpRequest | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('WAITING');
  const [uploadProgress, setUploadProgress] = useState(0);

  const startUpload = async (file: File) => {
    setUploadStatus('UPLOADING');
    setUploadProgress(0);
    callbacks.onUploadStart?.();
    upload.current = new DirectUpload(file, UPLOAD_URL, {
      directUploadWillCreateBlobWithXHR: (xhr) => {
        xhr.withCredentials = true;
      },
      directUploadWillStoreFileWithXHR: (request) => {
        xhr.current = request;
        request.upload.addEventListener('progress', handleProgress);
      },
    });

    upload.current!.create((error, blob) => {
      if (error) {
        handleError(error);
      } else if (blob) {
        setUploadStatus('SUCCESS');
        callbacks.onUploadSuccess?.(blob.signed_id);
      }
    });
  };

  const cancelUpload = () => {
    setUploadStatus('CANCELED');
    xhr.current?.abort();
  };

  const handleProgress = (event: ProgressEvent) => {
    if (event.lengthComputable) {
      const progress = Math.round((100 * event.loaded) / event.total);
      setUploadProgress(progress);
      callbacks.onUploadProgress?.(progress);
    }
  };

  const handleError = (error: any) => {
    console.error(error);
    setUploadStatus('FAILED');
    callbacks.onUploadFailure?.(error);
  };

  return { startUpload, cancelUpload, uploadProgress, uploadStatus };
}
