import Brand from 'types/Brand';
import { OtherAction } from './global';
import { RootState } from './index';

const host = new URL(window.location.href).hostname;
const matchData =
  host.match(/^(.+)\.(socialie|socialieapps)\.com$/) ||
  host.match(/^(.+)\.lvh\.me$/) ||
  host.match(/^(.+)\.ngrok\.io$/);

let hostname = '';
if (matchData) {
  hostname = matchData[1];
}

const brandDefaults: Brand = { primaryColor: '#282828' };

const houseBrand: Brand = {
  ...brandDefaults,
  primaryColor: '#FC2361',
  logoUrl:
    'https://s3.amazonaws.com/assets.suggest.socialie.com/brands/Socialie/logo-socialie%402x.png',
};

// Action Types
enum TypeKeys {
  FETCHED = 'publisher/brand/FETCHED',
  CHANGE = 'publisher/brand/CHANGE',
}

interface FetchedAction {
  type: TypeKeys.FETCHED;
  payload: {
    brand: Brand;
    codeName: string;
  };
}

interface ChangeAction {
  type: TypeKeys.CHANGE;
  payload: {
    codeName: string;
  };
}

type ActionTypes = FetchedAction | ChangeAction | OtherAction;

// Reducer
export interface State {
  current: string;
  byId: { [codeName: string]: Brand };
}

const initialState = {
  current: hostname,
  byId: { [hostname]: houseBrand },
};

export default (state: State = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.FETCHED:
      const { brand, codeName } = action.payload;

      return {
        ...state,
        current: codeName,
        byId: {
          ...state.byId,
          [codeName]: {
            ...state.byId[codeName],
            ...brand,
            loaded: true,
          },
        },
      };

    case TypeKeys.CHANGE: {
      const { codeName } = action.payload;
      return {
        ...state,
        current: codeName,
      };
    }

    default:
      return state;
  }
};

// Action Creators
export const fetchedBrand = (brand: Brand, codeName: string) => ({
  type: TypeKeys.FETCHED,
  payload: { brand, codeName },
});

export const changeBrand = (codeName: string) => ({
  type: TypeKeys.CHANGE,
  payload: { codeName },
});

// Selectors
export const getCurrentBrand = (state: RootState) =>
  state.brand.byId[state.brand.current];
