import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { allCountryCodes, selectStyles } from '../utils';
import buildCountryCodeOptions, {
  Option,
} from '../utils/buildCountryCodeOptions';
import useIsMobile from 'hooks/useIsMobile';
import CountryCodeFullscreen from './CountryCodeFullscreen';
import { IconCaretDown } from 'icons';

interface Props {
  value: string;
  onChange: (value: string) => void;
  countryCodes: string[];
  countryCodeWhitelist: string[];
  allowWorldwideSms?: boolean;
  isInvalid?: boolean;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

const SingleValue = (props: any) => (
  <div style={{ color: '#101820' }}>{props.data.value}</div>
);

export default function CountryCode(props: Props) {
  const {
    allowWorldwideSms,
    countryCodeWhitelist,
    onChange,
    isInvalid,
    onBlur,
    onFocus,
  } = props;

  const { isMobile } = useIsMobile();
  const sizerRef = useRef<HTMLSpanElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!sizerRef.current) return;
    setWidth(sizerRef.current.offsetWidth);
  }, [inputValue, props.value]);

  const defaultCountryCodes = props.countryCodes.map((c) => {
    const match = allCountryCodes.find((ac) => ac.value === c);

    return {
      value: c,
      label: match ? match.label : c,
    };
  });

  const options = buildCountryCodeOptions(
    defaultCountryCodes,
    countryCodeWhitelist,
    allowWorldwideSms
  );

  if (!options) return null;

  const value =
    props.value ||
    (defaultCountryCodes[0] && defaultCountryCodes[0].value) ||
    '';

  // Get a flat list of all the available options
  let allOptions = options as Option[];
  if (options[0]['options']) {
    allOptions = [...options[0]['options'], ...options[1]['options']];
  }

  const selectedOption =
    allOptions.find((c) => value === c.value) || allOptions[0];

  return (
    <div
      className="phone-input__country-code"
      style={{ width: width ? `${width}px` : 'auto' }}
    >
      {options.length > 1 ? (
        <>
          {isMobile ? (
            <>
              <button
                type="button"
                className="border-default border-r-none rounded h-5.5 rounded-r-none text-dark w-full bg-white"
                onClick={() => setIsFullscreenOpen(true)}
              >
                <span className="px-0.5 h-full flex items-center justify-between">
                  <span className="ml-0.5">{selectedOption.value}</span>
                  <IconCaretDown className="block w-1 h-1 mr-1 text-dark bump-down-2" />
                </span>
              </button>

              <CountryCodeFullscreen
                isOpen={isFullscreenOpen}
                onRequestClose={() => setIsFullscreenOpen(false)}
                options={options}
                onChange={onChange}
                selectedOption={selectedOption}
              />
            </>
          ) : (
            <Select
              value={selectedOption}
              onBlur={onBlur}
              onFocus={onFocus}
              className="phone-input__country-code-select"
              styles={selectStyles({ isInvalid })}
              components={{ SingleValue }}
              options={options}
              isMulti={false}
              onChange={({ value }: any) => onChange(value)}
              placeholder=""
              onInputChange={setInputValue}
            />
          )}
        </>
      ) : (
        <div className="phone-input__default-country-code">
          {(options[0] as any).value}
        </div>
      )}

      {allOptions.length > 1 && (
        <span className="phone-input__country-code-sizer" ref={sizerRef}>
          {inputValue || selectedOption.value}
        </span>
      )}
    </div>
  );
}
