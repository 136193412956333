import Platform from 'types/Platform';
import PlatformId from 'types/PlatformId';
import { OtherAction } from './global';
import { FetchedAction, TypeKeys as PRTypeKeys } from './publisherRequests';
import byPlatformId from 'helpers/byPlatformId';
import sortPlatforms from 'helpers/sortPlatforms';
import * as platformMentionApi from 'api/platformMention';

// Action Types
enum TypeKeys {
  MENTION_QUERY = 'platform/mention/QUERY',
}

export interface DraftEntity {
  id: string;
  name: string;
  image: string;
  twitter_name?: string;
  twitter_verified?: boolean;
}

export const PLATFORMS: PlatformId[] = [
  'twitter',
  'instagram',
  'facebook',
  'linkedin',
];

type ActionTypes = FetchedAction | OtherAction;

export interface State {
  [key: string]: { [key: string]: Platform };
}

export default (state: State = {}, action: ActionTypes): State => {
  switch (action.type) {
    case PRTypeKeys.FETCHED: {
      const { requestKey, actionable } = action.payload;
      if (actionable) {
        const platforms = sortPlatforms(actionable.platforms);

        return {
          ...state,
          [requestKey]: byPlatformId(platforms, 'id'),
        };
      }
      return state;
    }

    default:
      return state;
  }
};

// Action creators

export const queryPlatformMention = (
  queryString: string,
  platformId: PlatformId
) => async (dispatch: Function, getState: () => {}) => {
  const dispatcher = (data: any[]) => ({
    type: TypeKeys.MENTION_QUERY,
    payload: data,
  });

  let data = [];
  if (platformId === 'twitter') {
    data = await platformMentionApi.queryTwitterMention(queryString);
  }
  if (platformId === 'facebook') {
    data = await platformMentionApi.queryFacebookMention(queryString);
  }
  return dispatch(dispatcher(data));
};

// Selectors
export const getPlatformsByRequestKey = (
  state: { platforms: State },
  requestKey: string
): { [key: string]: Platform } => state.platforms[requestKey];
