import React from 'react';
import { platformIcons } from 'icons';

interface Props {
  platformId: string;
  branded?: boolean;
  error?: boolean;
  unconnected?: boolean;
  success?: boolean;
  circled?: boolean;
}

const PlatformIcon = React.forwardRef(function PlatformIcon(props: Props, ref) {
  const platform = props.platformId.startsWith('instagram')
    ? 'instagram'
    : props.platformId;

  const Icon = platformIcons[platform];
  const iconSize = props.circled ? '70%' : '100%';

  if (!Icon) {
    throw new Error(`PlatformIcon: unknown platform ${platform}`);
  }

  const colorPrefix = props.circled ? 'bg' : 'text';
  let color = 'white';
  if (props.branded) color = platform;
  if (props.success) color = 'teal';
  if (props.error) color = 'error';
  if (props.unconnected) color = 'lightIcon';
  const classNames = [`${colorPrefix}-${color}`];
  if (props.circled) classNames.push('text-white');

  /*
    purgecss:
    bg-white text-white bg-teal text-teal bg-error text-error bg-facebook
    text-facebook bg-instagram text-instagram bg-twitter text-twitter
    bg-linkedin text-linkedin text-lightIcon bg-lightIcon
  */

  return (
    <div
      className={`flex items-center justify-center rounded-full w-full h-full ${classNames.join(
        ' '
      )}`}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Icon style={{ width: iconSize, height: iconSize }} />
    </div>
  );
});

PlatformIcon.defaultProps = {
  circled: true,
};

export default PlatformIcon;
