import React from 'react';
import UpdateUserTwitterAccount from './UpdateUserTwitterAccount';
import TwitterSearchResult from 'types/TwitterSearchResult';
import { IconRemove } from 'icons';

interface Props {
  result: TwitterSearchResult;
  onRemove: () => void;
}

export default function UpdateUserTwitterSelectedResult(props: Props) {
  const { result, onRemove } = props;

  return (
    <>
      <div className="p-2 rounded border-default bg-white">
        <div className="flex items-center">
          <UpdateUserTwitterAccount account={result} />
          <div className="ml-auto">
            <button
              onClick={onRemove}
              className="flex items-center justify-center w-2.5 h-2.5 rounded-full text-darkGreyBg hover:text-light"
            >
              <IconRemove className="block w-full h-full" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
