import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'redux/store';
import * as api from 'api/downloads';
import { RootState } from '.';

const { reducer, actions } = createSlice({
  initialState: [] as string[],
  name: 'batchDownloads',
  reducers: {
    addUuid: (state, action: PayloadAction<string>) => {
      return [...state, action.payload];
    },
    removeUuid: (state, action: PayloadAction<string>) => {
      return state.filter((uuid) => uuid !== action.payload);
    },
  },
});

export default reducer;

export const addBatchDownload = (uuid: string) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  dispatch(actions.addUuid(uuid));

  // Poll for the download URL in case the socket event doesn't fire
  const timer = window.setInterval(async () => {
    const uuids = getState().batchDownloads;

    if (!uuids.includes(uuid)) {
      clearInterval(timer);
      return;
    }

    try {
      const data = await api.getDownloadUrl(uuid);
      dispatch(downloadZip({ url: data.url, uuid }));
    } catch (e) {
      // Endpoint can return 404, don't need to do anything
    }
  }, 10000);
};

export const downloadZip = (data: { url: string; uuid?: string }) => (
  dispatch: AppDispatch
) => {
  window.location.href = data.url;
  if (data.uuid) dispatch(actions.removeUuid(data.uuid));
};
