import PhoneNumber from 'awesome-phonenumber';

export type Styles =
  | 'international'
  | 'e164'
  | 'national'
  | 'rfc3966'
  | 'significant';

export default (phone: string, style: Styles = 'international') => {
  const pn = new PhoneNumber(phone);
  if (!pn.isValid()) return phone;
  return pn.getNumber(style);
};
