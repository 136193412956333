import camelize from 'camelize';
import { OtherAction } from 'redux/ducks/global';
import { RootState } from 'redux/ducks';
import PlatformId from 'types/PlatformId';
import * as api from 'api/onboarding';

interface Flags {
  isFetching?: boolean;
  enableCommunicationPreferences?: boolean;
  platformBlacklist?: PlatformId[];
  enableLanguageSelection?: boolean;
  enableWhatsApp?: boolean;
  message?: string;
  didSignUp?: boolean;
}

export enum TypeKeys {
  FETCH_FLAGS = 'publisher/onboarding/FETCH_FLAGS',
  FETCHED_FLAGS = 'publisher/onboarding/FETCHED_FLAGS',
  SET_FLAGS = 'publisher/onboarding/SET_FLAGS',
}

export interface FetchFlagsAction {
  type: TypeKeys.FETCH_FLAGS;
}

export interface FetchedFlagsAction {
  type: TypeKeys.FETCHED_FLAGS;
  payload: Flags;
}

export interface SetFlagsAction {
  type: TypeKeys.SET_FLAGS;
  payload: Partial<Flags>;
}

type ActionTypes =
  | FetchFlagsAction
  | FetchedFlagsAction
  | SetFlagsAction
  | OtherAction;

// Reducer
export type State = Flags;

export default (state: State = {}, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.FETCH_FLAGS:
      return { isFetching: true };

    case TypeKeys.FETCHED_FLAGS:
      return { isFetching: false, ...action.payload };

    case TypeKeys.SET_FLAGS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

// Action Creators
const updateFlags = (flags: Flags) => ({
  type: TypeKeys.FETCHED_FLAGS,
  payload: flags,
});

export const fetchFlags = () => async (
  dispatch: Function,
  getState: () => RootState
) => {
  dispatch({ type: TypeKeys.FETCH_FLAGS });
  const flags = await api.fetchFlags();
  console.log({ flags });
  dispatch(updateFlags(camelize(flags)));
};

export const setFlags = (flags: Partial<Flags>) => ({
  type: TypeKeys.SET_FLAGS,
  payload: flags,
});
