import React, { useState, createElement } from 'react';
import { clsx } from 'clsx';
import ClickOutside from 'react-onclickout';
import Tooltip from './Tooltip';
import hasClass from 'helpers/hasClass';
import noPropagate from 'helpers/noPropagate';
import { TippyProps } from '@tippyjs/react';
import { IconCaretRight, IconCheck } from 'icons';
import { EnhancedNavLink } from '.';

interface Props {
  children: React.ReactNode;
  placement?: TippyProps['placement'];
  className?: string;
  itemClassName?: string;
  containerClassName?: string;
  renderHeading?: React.ReactNode;
  theme?: string;
  renderButton: (onClick: any, isOpen: boolean) => TippyProps['children'];
  onOpen?: () => void;
  onOpened?: () => void;
}

const FlyoutMenuContext = React.createContext({ onClose: () => {} });

export default function FlyoutMenu(props: Props) {
  const { children, renderHeading, renderButton, placement, className } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen((isOpen) => !isOpen);

  const close = () => setIsOpen(false);

  const clickOut = (e: any) => {
    if (isOpen && !hasClass(e.target, 'tippy-box')) {
      close();
    }
  };

  return (
    <ClickOutside onClickOut={clickOut}>
      <Tooltip
        placement={placement || 'bottom-start'}
        arrow={false}
        visible={isOpen}
        className={className || ''}
        theme="FlyoutMenu"
        interactive
        appendTo={document.body}
        offset={[0, 4]}
        duration={[200, 0]}
        delay={0}
        animation="FlyoutMenu"
        content={
          <div>
            {renderHeading}
            <FlyoutMenuContext.Provider value={{ onClose: close }}>
              {children}
            </FlyoutMenuContext.Provider>
          </div>
        }
      >
        {renderButton(noPropagate(toggleOpen), isOpen)}
      </Tooltip>
    </ClickOutside>
  );
}

FlyoutMenu.Item = FlyoutMenuItem;

interface FlyoutMenuItemProps {
  children: React.ReactNode;
  icon?: typeof IconCaretRight;
  className?: string;
  isGlobalNavItem?: boolean;
  showArrow?: boolean;
  to?: string;
  isActive?: boolean;
  onClick?: () => void;
}

function FlyoutMenuItem(props: FlyoutMenuItemProps) {
  const {
    children,
    icon,
    className,
    onClick,
    to,
    isActive,
    isGlobalNavItem = false,
    showArrow = true,
  } = props;
  const { onClose } = React.useContext(FlyoutMenuContext);

  const handleClick = () => {
    onClick?.();
    onClose();
  };

  const renderInnards = (isActive?: boolean) => {
    const ArrowIcon = isActive ? IconCheck : IconCaretRight;
    return (
      <span
        className={clsx(
          'flex items-center w-full',
          !isActive && 'group-hover:opacity-[0.65]'
        )}
      >
        {icon &&
          createElement(icon, {
            className: `w-2 h-2 ${
              isGlobalNavItem
                ? `${isActive ? 'text-grey5' : 'text-white'} mr-1`
                : 'text-dark mr-0.5'
            }`,
          })}
        <span
          className={clsx(
            'bump-up-1',
            isActive && `${isGlobalNavItem ? 'text-grey5' : ''} font-bold`
          )}
        >
          {children}
        </span>
        {showArrow && (
          <ArrowIcon
            className={`ml-auto w-1.5 h-1.5 ${
              isGlobalNavItem ? 'text-white' : 'text-dark'
            }`}
          />
        )}
      </span>
    );
  };

  const sharedProps = {
    className: clsx(
      `group block py-1.5 pr-1 w-full ${
        isGlobalNavItem ? 'text-white' : 'text-dark border-b-default'
      } last:border-b-0`,
      className
    ),
    onClick: handleClick,
  };

  // Render as a Link if they passed a `to` prop
  if (to) {
    return (
      <EnhancedNavLink to={to} {...sharedProps} isGlobalNavItem>
        {renderInnards}
      </EnhancedNavLink>
    );
  }

  return (
    <button type="button" {...sharedProps}>
      {renderInnards(isActive)}
    </button>
  );
}
