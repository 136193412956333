import React from 'react';
import { useAppSelector } from 'redux/store';
import { getCurrentBrand } from 'redux/ducks/brand';
import PublisherAvatar from './PublisherAvatar';

interface Props {
  className?: string;
  theme?: React.ComponentProps<typeof PublisherAvatar>['theme'];
  logoClassName?: string;
  publisher?: { image?: string; name?: string };
  brand?: { logoUrl?: string };
}

export default function PublisherAvatarWithLogo(props: Props) {
  const { currentPublisher, currentBrand } = useAppSelector((state) => ({
    currentPublisher: state.publisher,
    currentBrand: getCurrentBrand(state),
  }));

  const publisher = props.publisher || currentPublisher;
  const brand = props.brand || currentBrand;

  if (!publisher || !brand) return null;

  return (
    <div className="relative w-full h-full" style={{ padding: '10%' }}>
      <PublisherAvatar
        src={publisher.image}
        name={publisher.name}
        theme={props.theme}
      />

      <img
        src={brand.logoUrl}
        alt="Logo"
        className="absolute right-0 rounded-full border-default bottom-0 bg-white"
        style={{ width: '45%', height: '45%' }}
      />
    </div>
  );
}
