import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';

import fr from 'react-intl/locale-data/fr';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import enFallback from 'locales/en.json';
import { RootState } from 'redux/ducks';

addLocaleData(fr);
addLocaleData(en);
addLocaleData(es);
addLocaleData(pt);

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    messages: { ...enFallback, ...state.locales.messages },
    locale: state.locales.lang,
    key: state.locales.lang,
  };
};

export default connect(mapStateToProps)(IntlProvider);
