import cookie from 'react-cookies';
import { OtherAction } from 'redux/ducks/global';
import * as api from 'api/onboarding';

export enum TypeKeys {
  UPDATE_INVITE_CODE = 'publisher/onboarding/UPDATE_INVITE_CODE',
  CHECK_INVITE_CODE = 'publisher/onboarding/CHECK_INVITE_CODE',
  ACCEPT_INVITE_CODE = 'publisher/onboarding/ACCEPT_INVITE_CODE',
  DECLINE_INVITE_CODE = 'publisher/onboarding/DECLINE_INVITE_CODE',
}

export interface UpdateInviteCodeAction {
  type: TypeKeys.UPDATE_INVITE_CODE;
  payload: { inviteCode: string };
}

export interface CheckInviteCodeAction {
  type: TypeKeys.CHECK_INVITE_CODE;
}

export interface AcceptInviteCodeAction {
  type: TypeKeys.ACCEPT_INVITE_CODE;
}

export interface DeclineInviteCodeAction {
  type: TypeKeys.DECLINE_INVITE_CODE;
  payload: { errorMessage: string };
}

type ActionTypes =
  | UpdateInviteCodeAction
  | CheckInviteCodeAction
  | AcceptInviteCodeAction
  | DeclineInviteCodeAction
  | OtherAction;

// Reducer
export interface State {
  value: string;
  isValid: boolean;
  errorMessage?: string;
  isChecking: boolean;
}

const initialValue = cookie.load('onboarding.inviteCode.value') || '';
const initialState = {
  value: initialValue,
  isChecking: !!initialValue,
  isValid: false,
};

export default (state: State = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.UPDATE_INVITE_CODE:
      return { ...state, value: action.payload.inviteCode };

    case TypeKeys.CHECK_INVITE_CODE:
      return { ...state, isChecking: true };

    case TypeKeys.DECLINE_INVITE_CODE:
      return {
        ...state,
        isChecking: false,
        isValid: false,
        errorMessage: action.payload.errorMessage,
      };

    case TypeKeys.ACCEPT_INVITE_CODE:
      return {
        ...state,
        isValid: true,
        isChecking: false,
        errorMessage: undefined,
      };

    default:
      return state;
  }
};

// Action Creators
const acceptInviteCode = () => ({ type: TypeKeys.ACCEPT_INVITE_CODE });

const declineInviteCode = (errorMessage: string) => ({
  type: TypeKeys.DECLINE_INVITE_CODE,
  payload: { errorMessage },
});

export const updateInviteCode = (inviteCode: string) => ({
  type: TypeKeys.UPDATE_INVITE_CODE,
  payload: { inviteCode },
});

// TODO: Internationalize
export const checkInviteCode = () => async (
  dispatch: Function,
  getState: Function
) => {
  dispatch({ type: TypeKeys.CHECK_INVITE_CODE });
  const state = getState();
  const inviteCode = state.onboarding.inviteCode.value.replace(/\s/g, '');

  if (inviteCode === '') {
    return dispatch(declineInviteCode('InviteCodeError__Missing'));
  }

  try {
    const response = await api.checkInviteCode(inviteCode);
    if (response.valid) {
      cookie.save('onboarding.inviteCode.value', inviteCode, { path: '/' });
      dispatch(acceptInviteCode());
    } else {
      dispatch(declineInviteCode('InviteCodeError__Invalid'));
    }
  } catch (error) {
    dispatch(declineInviteCode('InviteCodeError__Server'));
  }
};
