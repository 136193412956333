import React from 'react';
import { useIntl } from 'hooks';
import TwitterSearchResult from 'types/TwitterSearchResult';
import UpdateUserTwitterAccount from './UpdateUserTwitterAccount';
import Btn from 'components/shared/Btn';

interface Props {
  searchResults: TwitterSearchResult[];
  noResults?: boolean;
  isSaving?: boolean;
  onSave: (socialAccount: TwitterSearchResult) => void;
}

export default function UpdateUserTwitterSearchResults(props: Props) {
  const { searchResults, noResults, onSave, isSaving } = props;
  const { t } = useIntl();

  return (
    <div className="mb-2">
      {noResults ? (
        <>
          <div className="text-dark font-bold leading-tight">
            {t('UpdateUserTwitter__NoResults')}
          </div>
        </>
      ) : (
        searchResults.map((searchResult) => (
          <SearchResultRow
            key={searchResult.id}
            searchResult={searchResult}
            onSave={() => onSave(searchResult)}
            isSaving={isSaving}
          />
        ))
      )}
    </div>
  );
}

interface SearchResultRowProps {
  searchResult: TwitterSearchResult;
  isSaving?: boolean;
  onSave: () => void;
}

function SearchResultRow(props: SearchResultRowProps) {
  const { searchResult, onSave, isSaving } = props;
  const { t } = useIntl();
  return (
    <div className="p-2 rounded border-default bg-white">
      <div className="flex items-center">
        <UpdateUserTwitterAccount account={searchResult} />
        <div className="ml-2 w-10 pr-1">
          <Btn size="sm" onClick={onSave} isLoading={isSaving}>
            {t('Button__Select')}
          </Btn>
        </div>
      </div>
    </div>
  );
}
