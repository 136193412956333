import React from 'react';
import { useAppSelector } from 'redux/store';
import { Spinner } from 'components/shared';
import { useIntl } from 'hooks';

export default function BatchDownloadSpinner() {
  const isActive = useAppSelector((state) => !!state.batchDownloads.length);
  const { t } = useIntl();

  if (!isActive) return null;

  return (
    <div className="fixed bottom-0 left-0 p-3 z-50">
      <div className="flex items-center p-2 pr-4 rounded-lg bg-grey2 shadow-lg">
        <div className="w-3 h-3 mr-2">
          <Spinner stroke={4} fgColor="#45b4b4" bgColor="#5C636D" />
        </div>
        <span className="text-white font-bold bump-up-1">
          {t('MediaDownload__Loading')}
        </span>
      </div>
    </div>
  );
}
