import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'api/user';
import camelize from 'camelize';
import { Modal, PhoneInput } from 'components/shared';
import isValidContactValue from 'helpers/isValidContactValue';
import { useCurrentUser, useIntl } from 'hooks';
import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { addNotificationPreference } from 'redux/ducks/notificationPreferences';
import { logInUser } from 'redux/ducks/user';
import NotificationPreferences from 'types/NotificationPreferences';
import Form from './Form';

interface Props {
  field: 'phone' | 'email';
  isOpen: boolean;
  onRequestClose: () => void;
  notificationType?: keyof NotificationPreferences;
}

const initialState = {
  value: '',
  validationMsg: '',
  isLoading: false,
  isError: false,
};

const { reducer, actions } = createSlice({
  name: 'AddContactMethodReducer',
  initialState,
  reducers: {
    saveStart: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.validationMsg = '';
    },
    saveInvalid: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.validationMsg = action.payload;
    },
    saveError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    saveSuccess: (state) => {
      state.value = '';
      state.isLoading = false;
    },
    setValue: (state, action: PayloadAction<string>) => {
      state.validationMsg = '';
      state.value = action.payload;
    },
  },
});

export default function EditContactMethodModal(props: Props) {
  const { field, notificationType, isOpen, onRequestClose } = props;
  const user = useCurrentUser();
  const currentValue = user?.[field] || '';
  const action = currentValue ? 'Edit' : 'Add';

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    value: user?.[field] || '',
  });
  const reduxDispatch = useDispatch();
  const { value, isLoading, isError, validationMsg } = state;
  const { t } = useIntl();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(actions.saveStart());
    if (
      !isValidContactValue(value, field as 'email' | 'phone') &&
      !!value.length
    ) {
      dispatch(actions.saveInvalid(t(`Validation__InvalidFormat--${field}`)));
      return;
    }

    try {
      const params = { [field]: !!value.length ? value : null };
      const json = camelize(await api.updateUser(params));
      if ('user' in json) {
        dispatch(actions.saveSuccess());
        reduxDispatch(logInUser(json));
        if (notificationType && !!value.length) {
          reduxDispatch(addNotificationPreference(notificationType));
        }

        onRequestClose();
      } else if (json.errors && json.errors[field]) {
        if (json.errors[field]?.includes('Validation__Required')) {
          dispatch(
            actions.saveInvalid(
              t('Settings__ManageContactInfo--ValidationRequired', {
                protocol: field,
              })
            )
          );
        } else {
          dispatch(actions.saveInvalid(t(json.errors[field as string])));
        }
      } else {
        // something weird happened
        dispatch(actions.saveError());
      }
    } catch (e) {
      console.error(e);
      dispatch(actions.saveError());
    }
  };

  const handleChange = (value: string) => dispatch(actions.setValue(value));

  return (
    <Modal
      isOpen={isOpen}
      theme="bottom"
      onRequestClose={onRequestClose}
      contentLabel={t(`EditContactMethodModal__${action}Heading--${field}`)}
      renderHeading={t(`EditContactMethodModal__${action}Heading--${field}`)}
      onCancel={onRequestClose}
      onConfirm={handleSubmit}
      isConfirmLoading={isLoading}
      confirmText={t('Button__Save')}
    >
      <>
        <div className="subheading mb-1.5">
          {t(
            `EditContactMethodModal__${action}SubHeading--${
              notificationType || field
            }`
          )}{' '}
          {t(`EditContactMethodModal__${action}Login`)}
        </div>

        <form onSubmit={handleSubmit}>
          {isError && (
            <div className="text-error text-14 mb-1.5">
              {t('Global__UnexpectedError')}
            </div>
          )}

          <div className="mb-1.5">
            {field === 'email' ? (
              <Form.TextInput
                value={value}
                type="email"
                onChange={(e) => handleChange(e.target.value)}
                autoFocus
                onClear={action === 'Edit' ? () => handleChange('') : undefined}
                isInvalid={!!validationMsg}
                placeholder={t(`EditContactMethodModal__Placeholder--${field}`)}
                skipField
              />
            ) : (
              <PhoneInput
                forceWorldwide
                value={value}
                onChange={handleChange}
                isClearable={action === 'Edit'}
                isInvalid={!!validationMsg}
              />
            )}

            {!!validationMsg && (
              <div className="text-error text-14 text-left">
                {validationMsg}
              </div>
            )}
          </div>
        </form>
      </>
    </Modal>
  );
}
