import React, { useReducer } from 'react';
import { useIntl } from 'hooks';
import isValidContactValue from 'helpers/isValidContactValue';
import NewUserParams from 'types/NewUserParams';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createUser } from 'api/user';
import camelize from 'camelize';
import { useDispatch } from 'react-redux';
import { logInUser } from 'redux/ducks/user';
import { FormattedMessage } from 'react-intl';
import trackCustomEvent from 'helpers/trackCustomEvent';
import Form from 'components/shared/Form';
import Btn from 'components/shared/Btn';

interface Props {
  contactValue: string;
  setContactValue: (contactValue: string) => void;
  onSuccess: () => void;
}

const initialValues: NewUserParams = {
  contactValue: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
};

const initialErrors = {
  contactValue: '' as string | string[],
  firstName: '' as string | string[],
  lastName: '' as string | string[],
  password: '' as string | string[],
  passwordConfirmation: '' as string | string[],
};

const initialState = {
  values: initialValues,
  errors: initialErrors,
  isCreating: false,
  isError: false,
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'UserSignUpReducer',
  reducers: {
    createUserStart: (state) => {
      state.isError = false;
      state.isCreating = true;
    },
    createUserSuccess: (state) => {
      state.isCreating = false;
    },
    createUserError: (state) => {
      state.isCreating = false;
      state.isError = true;
    },
    setValue: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.values[action.payload.name] = action.payload.value;
    },
    setErrors: (state, action: PayloadAction<typeof initialErrors>) => {
      state.errors = action.payload;
    },
  },
});

export default function UserSignUp(props: Props) {
  const { contactValue, setContactValue, onSuccess } = props;
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    values: { ...initialState.values, contactValue },
  });
  const { values, errors, isCreating, isError } = state;

  const { t } = useIntl();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    dispatch(actions.setValue({ name, value }));

    // Keep the upper state in sync in case the user goes back
    if (name === 'contactValue') setContactValue(value);
  };

  const handleError = (error: string) => {
    console.error(error);
    dispatch(actions.createUserError());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Run client-side validation before submitting to server
    const sanitizedValues: NewUserParams = {
      ...values,
      contactValue: values.contactValue.trim(),
    };
    const nextErrors = { ...initialValues, ...validateValues(sanitizedValues) };
    dispatch(actions.setErrors(nextErrors));

    // Return early if client-side validation failed
    if (Object.values(nextErrors).some(Boolean)) return;

    dispatch(actions.createUserStart());

    try {
      // Send the request, check for errors, and save the user in redux
      const json = camelize(await createUser(sanitizedValues));

      // Dispatch success regardless of validation, success means 200
      dispatch(actions.createUserSuccess());

      if ('errors' in json && json.errors) {
        dispatch(actions.setErrors({ ...errors, ...json.errors }));

        // Track if a user tries to sign up with a taken contact value
        const errorToTrack = 'Validation__ContactValueAlreadyExists';

        if (
          json.errors.contactValue &&
          (json.errors.contactValue === errorToTrack ||
            json.errors.contactValue[0] === errorToTrack)
        ) {
          trackCustomEvent('contactValueExists', {
            context: 'SignUp',
            value: sanitizedValues.contactValue,
          });
        }
      } else if ('user' in json) {
        reduxDispatch(logInUser(json));
        onSuccess();
      } else {
        handleError('Response did not have user or errors');
      }
    } catch (e) {
      handleError(e);
    }
  };

  const fields = [
    'contactValue',
    ['firstName', 'lastName'],
    'password',
    'passwordConfirmation',
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        {fields.map((field) =>
          Array.isArray(field) ? (
            <div
              key={field.join('-')}
              className="flex items-center space-x-1.5"
            >
              {field.map((subfield) => (
                <div className="flex-1" key={subfield}>
                  <Field
                    key={subfield}
                    name={subfield}
                    value={values[subfield]}
                    errors={errors[subfield]}
                    handleChange={handleChange}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Field
              key={field}
              name={field}
              value={values[field]}
              errors={errors[field]}
              handleChange={handleChange}
            />
          )
        )}

        <Btn
          className="mb-1.5"
          type="submit"
          isLoading={isCreating}
          disabled={isCreating || Object.values(values).some((v) => !v)}
        >
          {t('Onboarding__SignUpSubmit')}
        </Btn>
      </form>

      {isError && (
        <div className="text-error text-14 text-center">
          {t('Global__UnexpectedError')}
        </div>
      )}

      <div className="text-center text-light text-14">
        <FormattedMessage
          id="Login__EULA"
          values={{
            eulaLink: (
              <a
                href="http://socialie.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-socialiePink hover:underline"
              >
                <FormattedMessage id="EULA__EULA" />
              </a>
            ),
            privacyPolicyLink: (
              <a
                href="http://socialie.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-socialiePink hover:underline"
              >
                <FormattedMessage id="Global__PrivacyPolicy" />
              </a>
            ),
          }}
        />
      </div>
    </>
  );
}

interface FieldProps {
  name: string;
  value: string;
  handleChange: React.ComponentProps<typeof Form.TextInput>['onChange'];
  errors?: string;
}

function Field({ name, value, handleChange, errors }: FieldProps) {
  const { t } = useIntl();
  return (
    <div className="mb-1.5">
      <Form.TextInput
        value={value}
        name={name}
        onChange={handleChange}
        type={name.match(/password/) ? 'password' : 'text'}
        placeholder={t(`Onboarding__SignUpPlaceholder--${name}`)}
        skipField
        isInvalid={!!errors}
      />

      {!!errors && <div className="text-error text-14">{t(errors)}</div>}
    </div>
  );
}

function validateValues(values: NewUserParams) {
  const errors: NewUserParams = {
    contactValue: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
  };

  if (!isValidContactValue(values.contactValue)) {
    errors.contactValue = 'Onboarding__SignUpErrors--contactValueInvalid';
  }

  if (!values.firstName) {
    errors.firstName = 'Onboarding__SignUpErrors--firstNameMissing';
  }

  if (!values.lastName) {
    errors.lastName = 'Onboarding__SignUpErrors--lastNameMissing';
  }

  if (values.password.length < 8) {
    errors.password = 'Onboarding__SignUpErrors--passwordTooShort';
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation =
      'Onboarding__SignUpErrors--passwordConfirmationWrong';
  }

  return errors;
}
