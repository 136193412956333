import { styled } from 'nativewind';
import {
  View,
  Image,
  TouchableOpacity,
  Pressable,
  Text,
  TextInput,
  SectionList,
  ScrollView,
} from 'react-native';

const StyledView = styled(View);
const StyledImage = styled(Image);
const StyledTouchableOpacity = styled(TouchableOpacity);
const StyledPressable = styled(Pressable);
const StyledText = styled(Text);
const StyledTextInput = styled(TextInput);
const StyledSectionList = styled(SectionList);
const StyledScrollView = styled(ScrollView, {
  props: { contentContainerStyle: true },
});

export {
  StyledView as View,
  StyledImage as Image,
  StyledTouchableOpacity as TouchableOpacity,
  StyledPressable as Pressable,
  StyledText as Text,
  StyledTextInput as TextInput,
  StyledSectionList as SectionList,
  StyledScrollView as ScrollView,
};
