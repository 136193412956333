import { Modal } from 'components/shared';
import { useIntl } from 'hooks';
import { IconZoomIn, IconZoomOut } from 'icons';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onCrop: (dataUrl: string) => void;
  src?: string | null;
  contentType?: string | null;
  aspectRatio: number;
  borderRadius: number;
}

export default function FileInputCropModal(props: Props) {
  const editorRef = useRef<AvatarEditor>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    isOpen,
    onRequestClose,
    src,
    onCrop,
    aspectRatio,
    borderRadius,
    contentType,
  } = props;
  const { t } = useIntl();
  const [geometry, setGeometry] = useState({
    position: { x: 0.5, y: 0.5 },
    currentScale: 1.2,
  });

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setWidth(wrapperRef.current?.offsetWidth || 0);
      }, 100);
    }
  }, [isOpen]);

  const handleCrop = () => {
    if (!editorRef.current) return;
    const canvas = editorRef.current.getImage();
    onCrop(canvas.toDataURL(contentType || 'image/jpeg', 0.8));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('form.fileInput.cropModal.heading')}
      contentLabel={t('form.fileInput.cropModal.heading')}
      onCancel={onRequestClose}
      onConfirm={handleCrop}
      confirmText={t('form.fileInput.cropModal.apply')}
    >
      <div ref={wrapperRef}>
        {src && !!width && (
          <>
            <div className="relative tablet:mb-2">
              <AvatarEditor
                ref={editorRef as any}
                image={src}
                onPositionChange={(position) =>
                  setGeometry({ ...geometry, position })
                }
                width={width - 48}
                height={(width - 48) / aspectRatio}
                borderRadius={borderRadius}
                border={24}
                position={geometry.position}
                disableBoundaryChecks={false}
                color={[255, 255, 255, 0.5]} // RGBA
                scale={geometry.currentScale}
              />

              <div
                style={{ borderRadius: `${borderRadius}px` }}
                className="absolute top-2.5 left-2.5 bottom-2.5 right-2.5 border-4 border-solid border-brightBlue pointer-events-none"
              />
            </div>

            <div className="hidden items-center justify-center px-12 mb-2 tablet:flex">
              <IconZoomOut className="w-2.5 h-2.5 text-light" />
              <input
                type="range"
                className="range-input flex-1 mx-1"
                min="1"
                max="5"
                step={0.1}
                value={geometry.currentScale}
                onChange={(e) => {
                  const currentScale = e.target.valueAsNumber;
                  setGeometry((geometry) => ({ ...geometry, currentScale }));
                }}
              />
              <IconZoomIn className="w-2.5 h-2.5 text-light" />
            </div>

            <div className="flex items-center justify-center px-12 space-x-0.5 mb-2 tablet:hidden">
              <button
                type="button"
                className="bg-greyBg rounded-full w-5 h-5 p-0.75 text-dark disabled:opacity-50"
                disabled={geometry.currentScale === 1}
                onClick={() =>
                  setGeometry((g) => ({
                    ...g,
                    currentScale: g.currentScale - 0.1,
                  }))
                }
              >
                <IconZoomOut className="block w-full h-full" />
              </button>

              <button
                type="button"
                className="bg-greyBg rounded-full w-5 h-5 p-0.75 text-dark disabled:opacity-50"
                disabled={geometry.currentScale >= 4.9}
                onClick={() =>
                  setGeometry((g) => ({
                    ...g,
                    currentScale: g.currentScale + 0.1,
                  }))
                }
              >
                <IconZoomIn className="block w-full h-full" />
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
