import React, { Suspense, lazy } from 'react';
import ClickOutside from 'react-onclickout';
import { Loader, Tooltip } from 'components/shared';
import { IconEmoji, IconEmojiOn } from 'icons';
import hasClass from 'helpers/hasClass';
import 'emoji-mart/css/emoji-mart.css';

const Picker = lazy(() =>
  import('emoji-mart').then((module) => ({ default: module.Picker }))
);

interface Props {
  onSelect: (emoji: any) => void;
  placement?: React.ComponentProps<typeof Tooltip>['placement'];
}

interface State {
  isOpen: boolean;
}

export default class EmojiPicker extends React.Component<Props, State> {
  state = { isOpen: false };

  clickOut = (e: any) => {
    if (this.state.isOpen && !hasClass(e.target, 'tippy-box')) {
      this.setState({ isOpen: false });
    }
  };

  onSelect = (emoji: any) => {
    this.setState({ isOpen: false });
    this.props.onSelect(emoji);
  };

  toggleOpen = () => this.setState((state) => ({ isOpen: !state.isOpen }));

  render() {
    return (
      <ClickOutside onClickOut={this.clickOut}>
        <Tooltip
          interactive
          placement={this.props.placement || 'bottom'}
          visible={this.state.isOpen}
          content={
            <div className="emoji-picker__wrapper">
              <Suspense fallback={<Loader isLoading />}>
                {this.state.isOpen && (
                  <Picker
                    native
                    onSelect={this.onSelect}
                    custom={[]}
                    style={{ width: '100%' }}
                    darkMode={false}
                  />
                )}
              </Suspense>
            </div>
          }
        >
          <button
            className="emoji-picker__toggle group"
            tabIndex={-1}
            onClick={this.toggleOpen}
          >
            {this.state.isOpen ? (
              <IconEmojiOn className="block text-dark" />
            ) : (
              <>
                <IconEmoji className="block group-hover:hidden" />
                <IconEmojiOn className="hidden group-hover:block" />
              </>
            )}
          </button>
        </Tooltip>
      </ClickOutside>
    );
  }
}
