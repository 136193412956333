import React from 'react';
import { clsx } from 'clsx';
import Errors from '../Errors';
import useDirectUpload from 'hooks/useDirectUpload';
import { IconPlaceholder, IconRemove } from 'icons';
import { useRef, useState } from 'react';
import { FileInputCropModal } from './components';
import { createElement } from 'react';
import { useIntl } from 'hooks';
import { IconCloudArrowUp } from 'icons/solid';
import Btn from 'components/shared/Btn';

interface Props {
  extensions: string[];
  attachment?: {
    src?: string | null;
    contentType?: string | null;
    name?: string | null;
  };
  aspectRatio?: number;
  borderRadius?: number;
  errors?: string[];
  placeholderIcon?: typeof IconPlaceholder;
  thumbnailSize?: 'large' | 'small';
  onChange: (signedId: string | null) => void;
  onSelectFile?: (dataUrl: string | null) => void;
  onUploadStart?: () => void;
  onUploadSuccess?: (signedId: string) => void;
  onUploadFailure?: () => void;
}

export default function FileInput(props: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useIntl();
  const {
    extensions,
    attachment,
    aspectRatio,
    borderRadius = 8,
    thumbnailSize = 'large',
    onChange,
    errors,
    onSelectFile,
    placeholderIcon = IconCloudArrowUp,
    ...callbacks
  } = props;
  const [currentAttachment, setCurrentAttachment] = useState(
    attachment || {
      src: null,
      contentType: '',
      name: '',
    }
  );
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const {
    startUpload,
    cancelUpload,
    uploadProgress,
    uploadStatus,
  } = useDirectUpload({
    ...callbacks,
    onUploadSuccess: (signedId) => {
      onChange(signedId);
      callbacks.onUploadSuccess?.(signedId);
    },
  });

  const handleSelectFile = (file?: File | null) => {
    if (!file) {
      onSelectFile?.(null);
      return;
    }

    const src = URL.createObjectURL(file);
    onSelectFile?.(src);
    setCurrentAttachment({
      src,
      name: file.name,
      contentType: file.type,
    });

    if (aspectRatio) {
      setIsCropModalOpen(true);
    } else {
      startUpload(file);
    }
  };

  const handleCrop = async (dataUrl: string) => {
    setCurrentAttachment((currentAttachment) => ({
      ...currentAttachment,
      src: dataUrl,
    }));
    onSelectFile?.(dataUrl);
    setIsCropModalOpen(false);
    const data = await fetch(dataUrl);
    const blob = await data.blob();
    const file = blobToFile(blob, currentAttachment.name || '');
    startUpload(file);
  };

  const handleRemove = () => {
    if (uploadStatus === 'UPLOADING') cancelUpload();
    setCurrentAttachment({ src: null, name: '', contentType: '' });
    onChange(null);
    onSelectFile?.(null);
  };

  const handleCancelCrop = () => {
    setCurrentAttachment({ src: null, name: '', contentType: '' });
    onSelectFile?.(null);
    setIsCropModalOpen(false);
  };

  const thumbnailClassNames = clsx('block w-full h-full', {
    'object-cover': !!aspectRatio,
    'object-contain': !aspectRatio,
  });

  return (
    <>
      <div className="flex items-center flex-row-reverse gap-1">
        <div
          role={!currentAttachment.src ? 'button' : undefined}
          onClick={
            !currentAttachment.src
              ? () => fileInputRef.current?.click()
              : undefined
          }
          style={{ borderRadius: `${borderRadius}px` }}
          className={clsx(
            'group flex-shrink-0 flex items-center justify-center overflow-hidden',
            {
              'bg-grey8 cursor-pointer': !currentAttachment.src,
              'bg-grey2': !!currentAttachment.src,
              'w-12 h-12': thumbnailSize === 'large',
              'w-7 h-7': thumbnailSize === 'small',
            }
          )}
        >
          {currentAttachment.src ? (
            <>
              {currentAttachment.contentType?.match(/video/) ? (
                <video
                  src={currentAttachment.src}
                  className={thumbnailClassNames}
                />
              ) : (
                <img
                  src={currentAttachment.src}
                  className={thumbnailClassNames}
                  alt=""
                />
              )}
            </>
          ) : (
            createElement(placeholderIcon, {
              className: clsx('text-lightText group-hover:text-bodyText', {
                'w-9 h-9': thumbnailSize === 'large',
                'w-4 h-4': thumbnailSize === 'small',
              }),
            })
          )}
        </div>
        <div>
          {/* Button */}
          {!currentAttachment.src ? (
            <>
              <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => handleSelectFile(e.target.files?.[0])}
                className="absolute"
                accept={extensions.map((e) => `.${e}`).join(',')}
                style={{ left: '-9999px' }}
              />
            </>
          ) : ['WAITING', 'FAILED', 'SUCCESS'].includes(uploadStatus) ? (
            <button
              type="button"
              className="w-3 h-3 text-dark"
              onClick={handleRemove}
              aria-label={t('form.fileInput.remove')}
            >
              <IconRemove className="block w-full h-full" />
            </button>
          ) : uploadStatus === 'UPLOADING' ? (
            <Btn
              type="button"
              size="sm"
              color="grey"
              variant={['inlineBlock', 'rounded']}
              leftIcon={IconRemove}
            >
              {t('form.fileInput.uploading')}... {uploadProgress}%
            </Btn>
          ) : null}

          {/* Messaging */}
          {!!errors?.length ? (
            <Errors errors={errors} />
          ) : uploadStatus === 'FAILED' ? (
            <Errors errors={t('form.fileInput.uploadFailed')} />
          ) : null}
        </div>
      </div>

      {!!aspectRatio && (
        <FileInputCropModal
          isOpen={isCropModalOpen}
          onRequestClose={handleCancelCrop}
          onCrop={handleCrop}
          src={currentAttachment.src}
          contentType={currentAttachment.contentType}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )}
    </>
  );
}

function blobToFile(theBlob: Blob, fileName: string) {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
}
