import { clsx } from 'clsx';
import { Btn } from 'components/shared';
import getPublisherRequestUrl from 'helpers/getPublisherRequestUrl';
import { useIntl } from 'hooks';
import { IconTextOnlyFancy } from 'icons';
import React from 'react';
import PartialPublisherRequest from 'types/PartialPublisherRequest';

interface Props {
  publisherRequest: PartialPublisherRequest;
}

export default function SuggestionsBrowserSwiperTextOnly(props: Props) {
  const { publisherRequest } = props;
  const { t } = useIntl();

  return (
    <div className="text-center">
      <div
        className={clsx('inline-block', {
          'pl-2': publisherRequest.actionableType === 'Suggestion',
        })}
      >
        <IconTextOnlyFancy className="w-16 h-16 text-light" />
      </div>

      <div className="mb-2.5">
        <h3 className="h3 text-white mb-1.5">
          {t(`NoMediaRequest__Heading--${publisherRequest.actionableType}`)}
        </h3>

        <div className="text-18 text-light">
          {t(
            publisherRequest.actionableType === 'Suggestion'
              ? 'NoMediaRequest__SubHeading--Suggestion'
              : `NoMediaRequest__SubHeading--${publisherRequest.actionableType}--${publisherRequest.mediaType}`
          )}
        </div>
      </div>

      <Btn
        to={getPublisherRequestUrl(publisherRequest)}
        className="mx-auto w-14"
      >
        {t('SuggestionsBrowserSwiperTextOnly__Button')}
      </Btn>
    </div>
  );
}
