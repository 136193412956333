import { createMagicLink } from 'api/magicLinks';
import flashMessage from 'helpers/flashMessage';
import formatContactValue from 'helpers/formatContactValue';
import isValidContactValue from 'helpers/isValidContactValue';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import { Modal } from '.';
import Form from './Form';
import Btn from './Btn';

interface Props {
  initialValue: string;
  onRequestClose: () => void;
}

export default function MagicLinkModal(props: Props) {
  const { initialValue, onRequestClose } = props;
  const [value, setValue] = useState(initialValue);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useIntl();

  const handleSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'] = async (
    e
  ) => {
    e.preventDefault();

    if (!isValidContactValue(value)) {
      setIsInvalid(true);
      return;
    }

    setIsInvalid(false);
    setIsLoading(true);

    try {
      await createMagicLink(value);
      flashMessage(
        t('MagicLinkModal__Success', { value: formatContactValue(value) }),
        {
          props: { skipTranslation: true },
        }
      );
      onRequestClose();
    } catch (e) {
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      theme="bottom"
      contentsClassName="max-w-54"
      renderHeading={t('MagicLinkModal__Heading')}
      contentLabel={t('MagicLinkModal__Heading')}
    >
      <div className="text-16 mb-2.5">{t('MagicLinkModal__SubHeading')}</div>
      <form onSubmit={handleSubmit}>
        <div className="mb-1.5 text-left">
          <Form.TextInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={t('Onboarding__LogInPlaceholder--contactValue')}
            autoFocus
            skipField
          />
        </div>

        {isInvalid && (
          <div className="mb-1.5 text-error text-14">
            {t('Global__InvalidContactValue')}
          </div>
        )}

        <Btn type="submit" isLoading={isLoading} disabled={!value || isLoading}>
          {t('Button__Submit')}
        </Btn>
      </form>
    </Modal>
  );
}
