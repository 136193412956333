import React, { useState, useReducer } from 'react';
import { clsx } from 'clsx';
import { createSlice } from '@reduxjs/toolkit';
import TwitterSearchResult from 'types/TwitterSearchResult';
import { useDispatch } from 'react-redux';
import camelize from 'camelize';
import * as api from 'api/user';
import { updatedUser } from 'redux/ducks/user';
import { addNotificationPreference } from 'redux/ducks/notificationPreferences';
import { useIntl } from 'hooks';
import {
  UpdateUserTwitterSelectedResult,
  UpdateUserTwitterSearch,
} from './components';
import Btn from '../Btn';

const initialState = {
  value: '',
  isSaving: false,
  isError: false,
};

interface Props {
  onSuccess?: () => void;
  saveOnSelect?: boolean;
  autoFocus?: boolean;
}

const { reducer, actions } = createSlice({
  name: 'AddTwitterReducer',
  initialState,
  reducers: {
    saveStart: (state) => {
      state.isSaving = true;
      state.isError = false;
    },
    saveError: (state) => {
      state.isSaving = false;
      state.isError = true;
    },
    saveSuccess: (state) => {
      state.value = '';
      state.isSaving = false;
    },
  },
});

export default function UpdateUserTwitter(props: Props) {
  const { onSuccess, saveOnSelect, autoFocus } = props;
  const { t } = useIntl();
  const [
    selectedResult,
    setSelectedResult,
  ] = useState<TwitterSearchResult | null>(null);

  const [{ isError, isSaving }, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  const handleSelect = (result: TwitterSearchResult) => {
    setSelectedResult(result);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedResult) return;
    handleSave(selectedResult);
  };

  const handleSave = async (result: TwitterSearchResult) => {
    dispatch(actions.saveStart());

    try {
      const params = {
        twitterUsername: result.username,
        twitterUserId: result.id,
      };
      const json = camelize(await api.updateUser(params));
      if ('user' in json) {
        dispatch(actions.saveSuccess());
        reduxDispatch(updatedUser(params));
        reduxDispatch(addNotificationPreference('twitter_dm'));
        onSuccess?.();
      } else {
        // something weird happened
        dispatch(actions.saveError());
      }
    } catch (e) {
      console.error(e);
      dispatch(actions.saveError());
    }
  };

  return (
    <>
      <div className={clsx({ 'mb-1.5': !saveOnSelect })}>
        {selectedResult ? (
          <UpdateUserTwitterSelectedResult
            result={selectedResult}
            onRemove={() => setSelectedResult(null)}
          />
        ) : (
          <UpdateUserTwitterSearch
            autoFocus={autoFocus}
            onSelect={saveOnSelect ? handleSave : handleSelect}
            isSaving={isSaving}
          />
        )}
      </div>

      {isError && (
        <div className="text-error text-14 mt-1.5">
          {t('Global__UnexpectedError')}
        </div>
      )}

      {!saveOnSelect && (
        <Btn
          className="mt-1.5 mb-2"
          onClick={handleSubmit}
          disabled={!selectedResult || isSaving}
        >
          {t('Button__Save')}
        </Btn>
      )}
    </>
  );
}
