import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'api/instagram';
import camelize from 'camelize';
import { useIntl } from 'hooks';
import { IconCaretRight, IconLoader } from 'icons';
import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { selectInstagramAccount } from 'redux/ducks/identities';
import { getIsLoading } from 'redux/ducks/ui/loading';
import { useAppSelector } from 'redux/store';
import InstagramGraphAccount from 'types/InstagramGraphAccount';
import Btn from './Btn';
import Modal from './modals/Modal';

interface Props {
  onRequestClose: () => void;
}

const initialState = {
  isLoading: false,
  accounts: [] as InstagramGraphAccount[],
  isError: false,
};

const { reducer, actions } = createSlice({
  name: 'InstagramAccountSelectorModalReducer',
  initialState: initialState,
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action: PayloadAction<InstagramGraphAccount[]>) => {
      state.isLoading = false;
      state.accounts = action.payload;
    },
    fetchError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export default function InstagramAccountSelectorModal(props: Props) {
  const { onRequestClose } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, accounts, isError } = state;
  const { t } = useIntl();
  const reduxDispatch = useDispatch();
  const identity = useAppSelector((state) => state.identities['instagram']);
  const incomplete = identity.incomplete;
  const selectingId = useAppSelector((state) => {
    const key = 'publisher/identities/selectInstagramAccount';
    let ids = accounts.map((a) => a.id);
    return ids.find((id) => getIsLoading(state, key, id));
  });

  useEffect(() => {
    const fetchPages = async () => {
      dispatch(actions.fetchStart());
      try {
        const data = camelize(await api.getAccounts());
        dispatch(actions.fetchSuccess(data));
      } catch (e) {
        console.error(e);
        dispatch(actions.fetchError());
      }
    };
    fetchPages();
  }, []);

  useEffect(() => {
    if (!incomplete) onRequestClose();
  }, [incomplete, onRequestClose]);

  const selectPage = (account: InstagramGraphAccount) => {
    reduxDispatch(selectInstagramAccount(account.id));
  };

  return (
    <Modal
      isOpen={true}
      theme="bottom"
      onRequestClose={onRequestClose}
      contentLabel={t('InstagramAccountSelector__Header')}
      renderHeading={t('InstagramAccountSelector__Header')}
    >
      {isLoading ? (
        <IconLoader />
      ) : isError ? (
        <>
          <div className="mb-3 text-light">{t('Global__UnexpectedError')}</div>
          <Btn color="darkGrey" onClick={onRequestClose}>
            {t('Button__Close')}
          </Btn>
        </>
      ) : (
        <>
          {accounts.map((account) => (
            <button
              key={account.id}
              className="block w-full mb-1.5 border-default rounded  last:mb-0"
              onClick={() => selectPage(account)}
              disabled={!!selectingId}
            >
              <span className="flex items-center overflow-hidden p-2">
                <img
                  src={account.profilePictureUrl}
                  className="rounded-full mr-2 w-4 h-4"
                  alt="Avatar"
                />

                <span className="text-dark flex-1 truncate text-left">
                  @{account.username}
                </span>

                <span className="ml-auto text-lightIcon bump-down-1">
                  {selectingId === account.id ? (
                    <IconLoader className="w-2 h-2" />
                  ) : (
                    <IconCaretRight className="w-2 h-2" />
                  )}
                </span>
              </span>
            </button>
          ))}
        </>
      )}
    </Modal>
  );
}
