import { OtherAction } from '../global';
import includes from 'lodash/includes';
import routes from 'helpers/routes';

export type State = string;

enum ActionTypes {
  CHANGE = 'publisher/ui/lastIndexPath/CHANGE',
  LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
}

interface ChangeAction {
  type: ActionTypes.CHANGE;
  payload: { path: string };
}

interface LocationChangeAction {
  type: ActionTypes.LOCATION_CHANGE;
  payload: {
    location: { pathname: string };
  };
}

type Action = ChangeAction | LocationChangeAction | OtherAction;

// Reducer
const initialState = '/inbox';

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionTypes.CHANGE:
      return action.payload.path;

    // Handle route changes so we don't have to do this in a bunch of
    // separate componentDidMount methods
    case ActionTypes.LOCATION_CHANGE:
      const { pathname } = action.payload.location;
      const { inbox, archived } = routes.feeds;
      if (pathname.match(/^\/albums.*/)) return pathname;
      if (includes([inbox, archived], pathname)) return inbox;
      return state;
    default:
      return state;
  }
};
