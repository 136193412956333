import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

const params = queryString.parse(window.location.search);

type Steps =
  | 'user'
  | 'secondary-contact'
  | 'notifications'
  | 'success'
  | 'possible-publishers';

const { reducer, actions } = createSlice({
  name: 'ui/accountConversion',
  initialState: { isOpen: !!params.ac, step: (params.ac || 'user') as Steps },
  reducers: {
    openAccountConversion: (
      state,
      action: PayloadAction<Steps | undefined>
    ) => {
      state.isOpen = true;
      state.step = action.payload || 'user';
    },
    closeAccountConversion: state => {
      state.isOpen = false;
    },
    setAccountConversionStep: (state, action: PayloadAction<Steps>) => {
      state.step = action.payload;
    },
  },
});

export default reducer;

export const {
  openAccountConversion,
  closeAccountConversion,
  setAccountConversionStep,
} = actions;
