import TwitterSearchResult from 'types/TwitterSearchResult';
import { get } from './shared';

export const queryTwitterMention = async (
  queryString: string,
  type?: string
) => {
  let endpoint = `publisher/q/twitter?q=${queryString}`;
  if (type) endpoint = `${endpoint}&type=${type}`;
  return await get<TwitterSearchResult[]>(endpoint);
};

export const queryFacebookMention = async (queryString: string) => {
  return await get(`publisher/q/facebook?q=${queryString}`);
};

export const queryCanTwitterDm = async (username: string) => {
  return await get<{ canDm: boolean }>(
    `publisher/query/can_dm?username=${username}`
  );
};
