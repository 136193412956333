import { OtherAction } from './global';
import { FetchedAction, TypeKeys as PRTypeKeys } from './publisherRequests';
import PlatformId from 'types/PlatformId';
import Draft from 'types/Draft';

// Action Types
export enum TypeKeys {
  UPDATE = 'publisher/drafts/UPDATE',
}

interface UpdateAction {
  type: TypeKeys.UPDATE;
  payload: {
    updates: any;
    platformId: PlatformId;
    requestKey: string;
  };
}

type ActionTypes = UpdateAction | FetchedAction | OtherAction;

// Localstorage
const restoreDraftsFromLocalStorage = () => {
  const storageEngine = window.sessionStorage || window.localStorage;
  if (!storageEngine) return {};
  try {
    const serializedDrafts = storageEngine.getItem('savedDrafts') || '{}';
    return JSON.parse(serializedDrafts);
  } catch (error) {}
};

const persistDraftsToLocalStorage = (
  drafts: { [platformId: string]: Draft },
  requestKey: string
) => {
  const storageEngine = window.sessionStorage || window.localStorage;
  if (!storageEngine) return;
  try {
    const serializedDrafts = JSON.stringify({ [requestKey]: drafts });
    storageEngine.setItem('savedDrafts', serializedDrafts);
  } catch (error) {}
};

// Reducer
// (Keyed on requestKey -> platformId)
export interface State {
  [key: string]: { [key: string]: Draft };
}

const initialState = restoreDraftsFromLocalStorage();
export default (state: State = initialState, action: ActionTypes) => {
  switch (action.type) {
    case PRTypeKeys.FETCHED: {
      const { requestKey, actionable } = action.payload;
      if (actionable && actionable.platforms) {
        const { platforms } = actionable;
        const draftsByPlatformId = {};
        platforms
          .filter((p) => p.drafts && p.drafts.length)
          .forEach((p) => {
            draftsByPlatformId[p.id] = {
              ...p.drafts[0],

              // HACK: Insert a space at the end of the caption if it ends with a
              // mention so that the mention search doesn't spin up as soon as
              // you focus the field
              text: p.drafts[0].text.replace(/(@\S+$)/, '$1 '),
            };
          });
        return {
          ...state,
          [requestKey]: { ...draftsByPlatformId, ...state[requestKey] },
        };
      }
      return state;
    }

    case TypeKeys.UPDATE:
      const { requestKey, platformId, updates } = action.payload;
      return {
        ...state,
        [requestKey]: {
          ...state[requestKey],
          [platformId]: { ...state[requestKey][platformId], ...updates },
        },
      };
    default:
      return state;
  }
};

// Action Creators
export const updateDraft = (
  updates: any,
  requestKey: string,
  platformId: PlatformId
) => (dispatch: Function, getState: Function) => {
  dispatch({
    type: TypeKeys.UPDATE,
    payload: { requestKey, platformId, updates },
  });
  persistDraftsToLocalStorage(getState().drafts[requestKey], requestKey);
};
// Selectors
export const getDraftsByRequestKey = (
  state: any,
  requestKey: string
): { [key: string]: Draft } => state.drafts[requestKey] || {};
