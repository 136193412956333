import React from 'react';

interface Props {
  stroke: number;
  fgColor: string;
  bgColor: string;
}

export default function Spinner(props: Props) {
  const { stroke, fgColor, bgColor } = props;

  return (
    <div
      className="relative rounded-full border-solid w-full h-full animate-spin"
      style={{
        borderColor: bgColor,
        borderTopColor: fgColor,
        borderWidth: `${stroke}px`,
        transform: 'translateZ(0)',
        transformOrigin: '50% 50%',
      }}
    />
  );
}
