import React, { useState } from 'react';
import { Link, NavLinkProps } from 'react-router-dom';
import { EnhancedNavLink, FlyoutMenu, Modal } from 'components/shared';
import { IconMenuFancy } from 'icons';
import MainTopBarScrollingSubGroup from './MainTopBarScrollingSubGroup';
import { useIntl } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';

type MenuItem = { path: string; title: string; exact?: boolean };

interface Props {
  menuItems: MenuItem[];
}

export default function SubNav({ menuItems }: Props) {
  const { t } = useIntl();
  const { isMobile } = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderButton = (
    onClick: React.DOMAttributes<HTMLButtonElement>['onClick']
  ) => (
    <button
      className="w-3 h-3 text-dark"
      onClick={onClick}
      aria-label={t('MainTopBar__SubNavButton')}
    >
      <IconMenuFancy className="w-full h-full" />
    </button>
  );

  return (
    <MainTopBarScrollingSubGroup
      renderOverflowButton={
        isMobile ? (
          <>
            {renderButton(() => setIsModalOpen(true))}
            <SubNavModal
              menuItems={menuItems}
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
            />
          </>
        ) : (
          <>
            <FlyoutMenu placement="bottom-end" renderButton={renderButton}>
              {menuItems.map((menuItem) => (
                <FlyoutMenu.Item key={menuItem.path} to={menuItem.path}>
                  {menuItem.title}
                </FlyoutMenu.Item>
              ))}
            </FlyoutMenu>
          </>
        )
      }
    >
      {menuItems.map((menuItem) => (
        <SubNavLink
          key={menuItem.path}
          to={menuItem.path}
          exact={menuItem.exact}
          isActive={(_, location) => {
            return location.pathname.includes(menuItem.path);
          }}
        >
          {menuItem.title}
        </SubNavLink>
      ))}
    </MainTopBarScrollingSubGroup>
  );
}

SubNav.Modal = SubNavModal;

function SubNavLink(props: NavLinkProps) {
  return (
    <EnhancedNavLink
      {...props}
      exact
      className="flex items-center text-14 flex-shrink-0 rounded-full px-2 h-4 border border-solid"
      activeClassName="bg-primary text-white border-primary"
      inactiveClassName="border-grey1 text-dark"
    >
      <span className="bump-up-1">{props.children}</span>
    </EnhancedNavLink>
  );
}

interface SubNavModalProps {
  menuItems: MenuItem[];
  isOpen: boolean;
  onRequestClose: () => void;
}

function SubNavModal({ menuItems, isOpen, onRequestClose }: SubNavModalProps) {
  const { t } = useIntl();

  return (
    <Modal
      theme="bottom"
      isOpen={isOpen}
      renderHeading={t(`SubNav__MenuHeading`)}
      onRequestClose={onRequestClose}
      contentLabel={t(`SubNav__MenuHeading`)}
    >
      <div className="text-left">
        {menuItems.map((menuItem) => (
          <Link
            key={menuItem.path}
            className="text-dark block py-1 text-18"
            to={menuItem.path}
            onClick={onRequestClose}
          >
            {menuItem.title}
          </Link>
        ))}
      </div>
    </Modal>
  );
}
