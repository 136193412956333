import { configureStore, Middleware, Action } from '@reduxjs/toolkit';
import createReducer, { RootState } from './ducks';
import { fileUploadMiddleware } from './middleware/fileUpload';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { TypeKeys as FileUploadTypeKeys } from './ducks/fileUploads';

export const history = createBrowserHistory();

const rootReducer = createReducer(history);

export default function configureAppStore(
  preloadedState: Partial<RootState> = {}
) {
  return configureStore({
    preloadedState: preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      routerMiddleware(history) as Middleware,
      fileUploadMiddleware as Middleware<
        (
          action: Action<
            | FileUploadTypeKeys.ADDED
            | FileUploadTypeKeys.UPLOADED
            | FileUploadTypeKeys.CANCELED
          >
        ) => number,
        RootState
      >,
      ...getDefaultMiddleware(),
    ],
  });
}
