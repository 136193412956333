import { flashMessage } from 'redux-flash';
import { AppDispatch } from 'redux/store';

export default function rejectThunkApiError(
  dispatch: AppDispatch,
  rejectWithValue: (value: any) => any,
  e: any
) {
  console.error(e);
  dispatch(flashMessage('Global__UnexpectedError', { isError: true }));
  return rejectWithValue({ error: e.message });
}
