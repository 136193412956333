import * as api from 'api/contentRequestSubmissions';
import ContentRequestSubmission from 'types/ContentRequestSubmission';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import camelize from 'camelize';
import rejectThunkApiError from 'helpers/rejectThunkApiError';
import { RootState } from '.';
import ContentRequestSubmissionMetadata from 'types/ContentRequestSubmissionMetadata';
import PublisherRequest from 'types/PublisherRequest';
import { addUpload } from './fileUploads';
import { getPublisherRequestById } from './publisherRequests';

export interface State {
  byId: { [key: number]: ContentRequestSubmission };
  byDate: { [date: string]: string[] };
  keys: string[];
  isCreating: boolean;
  metadata?: ContentRequestSubmissionMetadata;
}

const initialState: State = {
  byId: {},
  byDate: {},
  keys: [],
  isCreating: false,
};

export const createContentRequestSubmission = createAsyncThunk(
  'contentRequestSubmissions/create',
  async (
    {
      files,
      request,
      comments,
      flipMedia,
    }: {
      request: PublisherRequest;
      files: File[] | FileList;
      comments?: string;
      flipMedia?: boolean;
    },
    { rejectWithValue, dispatch }: { rejectWithValue: any; dispatch: any }
  ) => {
    try {
      const contentRequestSubmission = camelize(
        await api.create(request.requestKey, comments)
      ) as ContentRequestSubmission;

      dispatch(
        addUpload({
          requestKey: request.requestKey,
          contentRequestSubmissionId: contentRequestSubmission.id,
          file: files[0],
          previewUrl: URL.createObjectURL(files[0]),
          flipMedia,
        })
      );

      return contentRequestSubmission;
    } catch (e) {
      console.error(e);
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const updateContentRequestSubmission = createAsyncThunk(
  'contentRequestSubmissions/update',
  async (
    {
      id,
      updates,
    }: {
      id: number;
      updates: Partial<ContentRequestSubmission>;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const requestKey = getRequestKeyFromContentRequestSubmissionId(state, id);
      if (!requestKey) return;

      const data = camelize(await api.update(id, requestKey, updates));

      return data;
    } catch (e) {
      console.error(e);
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

export const fetchContentRequestSubmissions = createAsyncThunk(
  'contentRequestSubmissions/fetch',
  async (
    { page, requestId }: { page: number; requestId?: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      return camelize(
        await api.fetchContentRequestSubmissions(page, requestId)
      );
    } catch (e) {
      console.error(e);
      return rejectThunkApiError(dispatch, rejectWithValue, e);
    }
  }
);

const { reducer } = createSlice({
  name: 'contentRequestSubmissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      createContentRequestSubmission.fulfilled,
      (state, action) => {
        state.byId[action.payload.id] = action.payload;
      }
    );
    builder.addCase(
      updateContentRequestSubmission.fulfilled,
      (state, action) => {
        state.byId[action.payload.id] = action.payload;
      }
    );
    builder.addCase(
      fetchContentRequestSubmissions.fulfilled,
      (state, action) => {
        const { contentRequestSubmissions, metadata } = action.payload;

        const newKeys = contentRequestSubmissions.map(
          (submission: ContentRequestSubmission) => submission.id
        );
        const page = action.meta.arg.page;

        return {
          ...state,
          metadata,
          keys: page === 1 ? newKeys : [...state.keys, ...newKeys],
          byId: {
            ...state.byId,
            ...storeSubmissionsbyId(contentRequestSubmissions),
          },
          byDate: contentRequestSubmissions.reduce(
            (
              result: any,
              contentRequestSubmission: ContentRequestSubmission
            ) => {
              const date = new Date(
                contentRequestSubmission.createdAt
              ).setHours(0, 0, 0, 0);

              return {
                ...result,
                [date]: [...(result[date] || []), contentRequestSubmission.id],
              };
            },
            page === 1 ? {} : state.byDate
          ),
        };
      }
    );
  },
});

export default reducer;

export const getContentRequestSubmissions = (state: RootState) => {
  const contentRequestSubmissions = state.contentRequestSubmissions.keys.map(
    (key: string) => {
      return state.contentRequestSubmissions.byId[key];
    }
  );

  return contentRequestSubmissions;
};

export const getContentRequestSubmissionsByDate = (
  state: RootState,
  date: string
) => {
  const submissionsIds = state.contentRequestSubmissions.byDate[date];
  if (!submissionsIds) return [];
  const contentRequestSubmissions = submissionsIds.map(
    (submissionId) => state.contentRequestSubmissions.byId[submissionId]
  );

  return contentRequestSubmissions;
};

export const getMetadata = (state: RootState) =>
  state.contentRequestSubmissions.metadata ||
  ({} as ContentRequestSubmissionMetadata);

export const getContentRequestSubmissionById = (state: RootState, id: number) =>
  state.contentRequestSubmissions.byId[id];

const getRequestKeyFromContentRequestSubmissionId = (
  state: RootState,
  id: number
) => {
  const contentRequestSubmission = getContentRequestSubmissionById(state, id);
  if (!contentRequestSubmission) return;
  const request = getPublisherRequestById(
    state,
    contentRequestSubmission.requestId
  );
  return request && request.requestKey;
};

const storeSubmissionsbyId = (
  contentRequestSubmissions: ContentRequestSubmission[]
): { [id: string]: ContentRequestSubmission } => {
  return contentRequestSubmissions.reduce(
    (result, submission) => ({
      ...result,
      [submission.id]: submission,
    }),
    {}
  );
};
