import React, { useState } from 'react';
import { MultiStepAuthIdentify, MultiStepAuthSignUp } from './components';
import SignInForm from '../SignInForm/SignInForm';

interface Props {
  renderIdentify: (form: React.ReactNode) => React.ReactNode;
  renderSignUp: (form: React.ReactNode, goBack: () => void) => React.ReactNode;
  renderLogIn: (
    form: React.ReactNode,
    isMfa: boolean,
    goBack: () => void
  ) => React.ReactNode;
  onSignUp: () => void;
  onSignIn: () => void;
  hideAlreadyHaveAccount?: boolean;
}

export default function MultiStepAuth(props: Props) {
  const {
    renderIdentify,
    renderLogIn,
    renderSignUp,
    onSignUp,
    onSignIn,
    hideAlreadyHaveAccount,
  } = props;
  const [contactValue, setContactValue] = useState('');
  const [screen, setScreen] = useState<'identify' | 'sign-up' | 'log-in'>(
    'identify'
  );

  const identifyForm = (
    <MultiStepAuthIdentify
      contactValue={contactValue}
      setContactValue={setContactValue}
      goToLogIn={() => setScreen('log-in')}
      goToSignUp={() => setScreen('sign-up')}
      hideAlreadyHaveAccount={hideAlreadyHaveAccount}
    />
  );

  const signUpForm = (
    <MultiStepAuthSignUp
      contactValue={contactValue}
      setContactValue={setContactValue}
      onSuccess={onSignUp}
    />
  );

  if (screen === 'identify') {
    return <>{renderIdentify(identifyForm)}</>;
  }

  if (screen === 'sign-up') {
    return <>{renderSignUp(signUpForm, () => setScreen('identify'))}</>;
  }

  if (screen === 'log-in') {
    return (
      <SignInForm
        onSuccess={onSignIn}
        contactValue={contactValue}
        renderSignIn={form =>
          renderLogIn(form, false, () => setScreen('identify'))
        }
        renderMfa={form => renderLogIn(form, true, () => setScreen('identify'))}
      />
    );
  }

  throw new Error(`[MultiStepAuth] Invalid value for screen: ${screen}`);
}
