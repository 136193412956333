import { post } from './shared';
import UserApiPayload from 'types/UserApiPayload';

export function verifyPassword(password: string) {
  return post<{ valid: boolean; error?: string }>(
    'publisher/mfa/verify-password',
    { password }
  );
}

export function registerAuthyUser(mfaPhone: string) {
  return post<{ success: boolean; errors?: string }>('publisher/mfa/register', {
    mfaPhone,
  });
}

export function enableMultiFactorAuth(mfaProtocol: string | null) {
  return post<{
    success: boolean;
    qrCodeImg?: string;
    cellPhone: string;
    errors?: string;
    mfaRecoveryCodes: string[];
  }>('publisher/mfa/enable', {
    mfaProtocol,
  });
}

export function validateOtp(
  mfaProtocol: string,
  token: string,
  tempToken?: string
) {
  return post<
    | UserApiPayload
    | {
        valid: boolean;
        jwt: string;
        errors?: string;
      }
  >('publisher/mfa/validate', {
    mfaProtocol,
    token,
    tempToken,
  });
}

export function disableMfa() {
  return post<{
    success: boolean;
    errors?: string;
  }>('publisher/mfa/disable');
}

export function getRecoveryCodes(password: string) {
  return post<{
    valid: boolean;
    mfaRecoveryCodes: { id: string; value: string }[];
    errors?: string;
  }>('publisher/mfa/recovery', {
    password,
  });
}

export function recoveryCodeLogIn(recoveryCode: string, tempToken: string) {
  return post<
    | UserApiPayload
    | {
        token: string;
        errors?: string;
      }
  >('publisher/mfa/recovery-code-login', {
    recoveryCode,
    tempToken,
  });
}

export function resendSmsCode(mfaProtocol: string, tempToken: string) {
  return post<{
    success: string;
    errors?: string;
  }>('publisher/mfa/resend-sms', {
    mfaProtocol,
    tempToken,
  });
}
