import { get, post } from './shared';
import PublisherUser from 'types/PublisherUser';
import UpdatePublisherUserParams from 'types/UpdatePublisherUserParams';
import CreatePublisherUserParams from 'types/CreatePublisherUserParams';

export const listPublisherUsers = () =>
  get<PublisherUser[]>('publisher/publisher-users');

export const removePublisherUser = (id: number) =>
  post(`publisher/publisher-users/${id}`, {}, 'DELETE');

export const updatePublisherUser = (
  id: number,
  updates: UpdatePublisherUserParams
) => post(`publisher/publisher-users/${id}`, updates, 'PUT');

export const createPublisherUser = (values: CreatePublisherUserParams) =>
  post<{ errors: string } | PublisherUser>(`publisher/publisher-users`, values);
