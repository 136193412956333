import { changeAccount } from 'api/accounts';
import { fetchPublisherCodeUrl } from 'api/publisher';
import { isPWADomain } from 'client';
import changeSubdomain from 'helpers/changeSubdomain';

interface Account {
  publisherId: number;
  brand: string;
  expiresAt?: number;
}

export default async (account: Account) => {
  await changeAccount(account.publisherId);
  const now = new Date().getTime();

  if (isPWADomain) {
    window.location.reload();
    return;
  }

  if (account.expiresAt && now >= account.expiresAt) {
    const code = await fetchPublisherCodeUrl(account.publisherId);
    window.location.href = code.url;
    return;
  }

  changeSubdomain(account.brand);
};
