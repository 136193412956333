// TODO: Fix all the tslint errors
/* tslint:disable */

import * as React from 'react';
import uuidV4 from 'uuid4';

interface Props {
  multipleFiles?: boolean;
  handleFiles: Function;
  fileTypes?: string | string[];
  base64?: boolean;
  elementId?: string;
  children: React.ReactNode;
}

interface State {
  elementId: string;
}
export default class ReactFileReader extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    fileTypes: 'image/*',
  };

  state = {
    elementId: this.props.elementId || uuidV4(),
  };

  clickInput = () => {
    const element = document.getElementById(
      this.state.elementId
    ) as HTMLInputElement;
    if (!element) return;
    element.value = '';
    element.click();
  };

  handleFiles = (event: any) => {
    if (this.props.base64) {
      this.convertFilesToBase64(event.target.files);
    } else {
      this.props.handleFiles(event.target.files);
    }
  };

  convertFilesToBase64 = (files: any) => {
    const ef = files;

    if (this.props.multipleFiles) {
      let files: { base64: string[]; fileList: any[] } = {
        base64: [],
        fileList: ef,
      };

      for (var i = 0, len = ef.length; i < len; i++) {
        let reader = new FileReader();
        let f = ef[i];

        reader.onloadend = e => {
          files.base64 = [...files.base64, reader.result as string];

          if (files.base64.length === ef.length) {
            this.props.handleFiles(files);
          }
        };

        reader.readAsDataURL(f);
      }
    } else {
      let files = { base64: '', fileList: ef };
      let f = ef[0];
      let reader = new FileReader();

      reader.onloadend = e => {
        files.base64 = reader.result as string;
        this.props.handleFiles(files);
      };

      reader.readAsDataURL(f);
    }
  };

  render() {
    return (
      <div className="react-file-reader">
        <input
          type="file"
          onChange={this.handleFiles}
          accept={
            Array.isArray(this.props.fileTypes)
              ? this.props.fileTypes.join(',')
              : this.props.fileTypes
          }
          className="react-file-reader-input"
          id={this.state.elementId}
          multiple={this.props.multipleFiles}
          style={{ position: 'absolute', left: '-9999px' }}
        />

        <div className="react-file-reader-button" onClick={this.clickInput}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
