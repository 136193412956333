import React from 'react';
import formatBytes from 'helpers/formatBytes';

interface Props {
  title: string;
  dimensions: [number, number];
  fileSize?: number;
}

export default function SaveMediaFileDetails(props: Props) {
  const { title, dimensions, fileSize } = props;
  return (
    <>
      <span className="block mb-0.25 text-18 text-dark leading-18 font-bold transition-fast unselectable">
        {title}
      </span>

      <span className="block text-14 transition-fast unselectable">
        {dimensions[0] && dimensions[1] && (
          <span>
            {dimensions[0]}x{dimensions[1]}{' '}
          </span>
        )}

        {fileSize && (
          <span className="unselectable">({formatBytes(fileSize)})</span>
        )}
      </span>
    </>
  );
}
