import React, { useState } from 'react';
import Field from './Field';
import InputContainer from './InputContainer';
import TextAreaAutosize from 'react-autosize-textarea';
import { useRef } from 'react';

interface Props extends React.ComponentProps<'textarea'> {
  label?: React.ReactNode;
  name?: string;
  errors?: string | string[] | null;
  description?: string;
  onClear?: () => void;
  autoSize?: boolean;
  rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export default function TextArea(props: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {
    label,
    required,
    name,
    errors,
    description,
    leftIcon,
    rightIcon,
    onClear,
    autoSize,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
    ...textareaProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const component = autoSize ? TextAreaAutosize : 'textarea';

  return (
    <Field
      label={label}
      required={required}
      name={name}
      errors={errors}
      description={description}
    >
      <InputContainer
        value={textareaProps.value}
        errors={errors}
        onClear={onClear}
        isFocused={isFocused}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        maxLength={textareaProps.maxLength}
        onClickIcon={() => textareaRef.current?.click()}
        multiline
      >
        {React.createElement(component, {
          className:
            'w-full appearance-none border-0 bg-transparent font-sans text-dark outline-none focus:outline-none focus-visible:outline-none',
          name,
          ref: textareaRef,
          'aria-required': required,
          onFocus: () => setIsFocused(true),
          onBlur: () => setIsFocused(false),
          ...textareaProps,
        })}
      </InputContainer>
    </Field>
  );
}
