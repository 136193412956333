import { isAndroidApp } from 'client';
import { z } from 'zod';
import queryString from 'query-string';

export const headers = {
  'X-Requested-With': 'XMLHttpRequest',
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Api-Version': '2',
  'X-Socialie-Publisher-App-Version': '1',
};

if (isAndroidApp) headers['X-Socialie-App-Version'] = 'Android PWA';

export const defaultOptions: RequestInit = { credentials: 'include', headers };

const handleResponse = async (
  response: Response,
  options = { reject422: false }
) => {
  if (response.ok || (response.status === 422 && !options.reject422)) {
    const textResponse = await response.text();
    return JSON.parse(textResponse || '{}');
  }

  if (response.status === 404) {
    return Promise.reject(response.status);
  }

  return Promise.reject('HTTP error: ' + response.status);
};

const handleError = (error: Error) => {
  return Promise.reject(error);
};

export const API_SERVER_URL =
  import.meta.env.REACT_APP_API_SERVER_URL || 'https://pdev.socialie.com';

export const OAUTH_REDIRECT_URL =
  import.meta.env.REACT_APP_OAUTH_REDIRECT_URL || API_SERVER_URL;

export const SOCKET_SERVER_URL =
  import.meta.env.REACT_APP_SOCKET_SERVER_URL || API_SERVER_URL;

/**
 *
 * @param {string} endpoint a relative url for the api server (no leading slash)
 * @returns {Promise<never | Response>}
 */
export const get = <T extends any>(endpoint: string): Promise<T> => {
  const options = { ...defaultOptions, method: 'GET' };

  return fetch(`${API_SERVER_URL}/${endpoint}`, options)
    .then(handleResponse)
    .catch(handleError);
};

/*
  Get function to support the use of React Query and
  Zod (TypeScript-first schema declaration and validation library) which should be used 
  for all new API requests going forwards.
*/
export const reactQueryGet = <T extends z.Schema>(
  url: string,
  { params, responseSchema }: { params?: object; responseSchema?: T } = {}
): Promise<z.infer<T>> => {
  const options = {
    ...defaultOptions,
    responseSchema,
    method: 'GET',
  };

  let endpoint = url.replace(/^\//, ''); // trim leading slashes

  if (params) {
    endpoint += '?' + queryString.stringify(params, { arrayFormat: 'bracket' });
  }

  return fetch(`${API_SERVER_URL}/${endpoint}`, options)
    .then(handleResponse)
    .catch(handleError);
};

/**
 *
 * @param {string} endpoint a relative url for the api server (no leading slash)
 * @param {{}} body
 * @param {string} method
 * @returns {Promise<never | Response>}
 */
export const post = <T extends any>(
  endpoint: string,
  body = {},
  method = 'POST',
  responseHandlerOptions = { reject422: false }
): Promise<T> => {
  const options = {
    ...defaultOptions,
    method,
    body: JSON.stringify(body),
  };

  return fetch(`${API_SERVER_URL}/${endpoint}`, options)
    .then((res) => handleResponse(res, responseHandlerOptions))
    .catch(handleError);
};
