import { post, get } from './shared';
import UploadedAttachment from 'types/UploadedAttachment';
import AttachmentSchema from 'types/Attachment';

export interface SignedS3RequestInit {
  requestType: string;
  contentType: string;
  queryParams: string;
  md5Digest?: string;
  mediaId?: string;
}

export const createAttachment = async (
  attachment: UploadedAttachment,
  socketId?: string
): Promise<AttachmentSchema> => {
  return await post('publisher/attachments', {
    attachment: { ...attachment, socketId },
  });
};

interface PresignedUrlResponse {
  url: string;
  media_id: string;
}

export const createNativeAppPresignedUrl = async (
  mimeType: string
): Promise<PresignedUrlResponse> => {
  const response = await post<PresignedUrlResponse>(
    'publisher/attachments/native_app_presigned_url',
    {
      contentType: mimeType,
    }
  );
  return response;
};

export const createSavedMediaEvent = async (
  requestKey: string,
  attachmentId: number
) => {
  return await post(`publisher/requests/${requestKey}/saved_media`, {
    attachmentId,
  });
};

export const signedS3Request = async (options: SignedS3RequestInit) => {
  return await post(`publisher/attachments/signed_s3_request`, options);
};

const handleS3Response = async (response: Response) => {
  if (response.ok) {
    const text = await response.text();
    return new DOMParser().parseFromString(text, 'text/xml');
  }
  return Promise.reject(`HTTP Error: ${response.status}`);
};

export const initiateMultipartUpload = async (url: string, headers: any) => {
  const options: RequestInit = {
    headers,
    method: 'POST',
  };
  return await fetch(url, options).then((response) =>
    handleS3Response(response)
  );
};

export const completeMultipartUpload = async (
  url: string,
  headers: any,
  body: string
) => {
  const options: RequestInit = {
    headers,
    body,
    method: 'POST',
  };
  return await fetch(url, options);
};

export const deleteAttachment = (id: number, publisherId: number) => {
  const url = `publisher/attachments/${id}`;
  const body = { attachment: { publisherId } };
  return post(url, body, 'DELETE');
};

export const checkStatus = (id: number) => {
  const endpoint = `publisher/attachments/${id}/check_status`;
  return get(endpoint);
};
