import React from 'react';
import { IconVerified } from 'icons';
import TwitterSearchResult from 'types/TwitterSearchResult';

interface Props {
  account: TwitterSearchResult;
}

export default function UpdateUserTwitterAccount({ account }: Props) {
  return (
    <div className="flex-1 flex items-center">
      <div className="mr-1.5 flex-shrink-0 w-6 h-6">
        <img
          src={account.image}
          alt={account.username}
          className="w-full h-full object-cover rounded-full"
        />
      </div>

      <div className="flex-1 text-left leading-none">
        <div className="flex items-center mb-0.5 font-bold">
          <a
            className="text-dark hover:underline"
            href={`https://twitter.com/${account.username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            @{account.username}
          </a>

          {account.verified && (
            <IconVerified className="flex-shrink-0 ml-0.5 bump-down-1 text-socialieBlue w-2 h-2" />
          )}
        </div>

        <div className="text-light">{account.screenName}</div>
      </div>
    </div>
  );
}
