import React, { useState } from 'react';
import { ForgotPasswordModal, Btn } from '../../shared';
import { useIntl } from 'hooks';
import routes from 'helpers/routes';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';

export default function InvalidResetPasswordToken() {
  const { t } = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <div className="mb-4">
        <h3 className="h3">{t('ResetPassword__ExpiredToken--Heading')}</h3>

        <div className="subheading">
          <FormattedHTMLMessage id="ResetPassword__ExpiredToken--Desc" />
        </div>
      </div>
      <Btn className="mb-2" onClick={() => setIsModalOpen(true)}>
        {t('ResetPassword__ExpiredToken--RequestLink')}
      </Btn>
      <Link
        className="text-socialiePink hover:underline"
        to={routes.auth.login.root}
      >
        {t('ResetPassword__ExpiredToken--ReturnToSignIn')}
      </Link>
      <ForgotPasswordModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
