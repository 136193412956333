import React, { useEffect, useRef, useState } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import bem from 'helpers/bem';
import { CountryCode } from './components';
import { IconAsterisk, IconRemove } from 'icons';

export interface Props {
  value: string;
  countryCode: string;
  onChange: (value: string) => void;
  onChangeCountryCode: (value: string) => void;
  loading?: boolean;
  countryCodes: string[];
  countryCodeWhitelist: string[];
  allowWorldwideSms?: boolean;
  isInvalid?: boolean;
  name?: string;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
}

export default function PhoneInput(props: Props) {
  const {
    value,
    countryCode,
    countryCodes,
    allowWorldwideSms,
    countryCodeWhitelist,
    isInvalid,
    name,
    onFocus,
    onBlur,
    placeholder,
    required,
    isClearable,
  } = props;

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const prevFormattedPhoneNumber = useRef('');
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const selectionRef = useRef<{ start?: number | null; end?: number | null }>(
    {}
  );
  const [isFocused, setIsFocused] = useState(false);

  const handleClearInput = () => {
    props.onChange('');
    phoneInputRef.current?.focus();
  };

  const onChangePhoneNumber = (val: string) => {
    selectionRef.current = {
      start: phoneInputRef.current?.selectionStart,
      end: phoneInputRef.current?.selectionStart,
    };
    props.onChange(val);
    if (!props.countryCode) {
      props.onChangeCountryCode(props.countryCodes[0]);
    }
  };

  const onChangeCountryCode = (val: string) => {
    props.onChangeCountryCode(val);
    phoneInputRef.current?.focus();
  };

  const handleFocus = (e: any) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent) => {
    selectionRef.current = {};
    setIsFocused(false);
    onBlur?.(e);
  };

  useEffect(() => {
    const regionCode = PhoneNumber.getRegionCodeForCountryCode(
      Number(countryCode)
    );

    const ayt = PhoneNumber.getAsYouType(regionCode);
    ayt.reset(value);
    setFormattedPhoneNumber(ayt.number());
  }, [value, countryCode]);

  // after formatted number updates, reset the cursor position
  useEffect(() => {
    if (
      phoneInputRef.current &&
      selectionRef.current.start != null &&
      selectionRef.current.end != null
    ) {
      const relevantPortion = formattedPhoneNumber.substr(
        0,
        selectionRef.current.start + 1
      );
      const prevRelevantPortion = prevFormattedPhoneNumber.current.substr(
        0,
        selectionRef.current.start + 1
      );
      const nnChars = relevantPortion.replace(/[0-9]+/g, '');
      const prevNnChars = prevRelevantPortion.replace(/[0-9]+/g, '');
      const newNnCharCount = nnChars.length - prevNnChars.length;
      const newStart = Math.max(0, selectionRef.current.start + newNnCharCount);
      const newEnd = Math.max(0, selectionRef.current.end + newNnCharCount);
      phoneInputRef.current.selectionStart = newStart;
      phoneInputRef.current.selectionEnd = newEnd;
    }
    prevFormattedPhoneNumber.current = formattedPhoneNumber;
  }, [formattedPhoneNumber, value.length]);

  return (
    <div
      className={bem('phone-input', {
        isInvalid,
        isFocused,
        isFilled: true,
      })}
    >
      {isClearable && !!formattedPhoneNumber && (
        <div onClick={handleClearInput} className="phone-input__icon-clear">
          <IconRemove />
        </div>
      )}

      <CountryCode
        value={countryCode}
        onChange={onChangeCountryCode}
        countryCodes={countryCodes}
        allowWorldwideSms={allowWorldwideSms}
        countryCodeWhitelist={countryCodeWhitelist}
        isInvalid={isInvalid}
        onBlur={handleBlur}
        onFocus={onFocus}
      />

      <input
        ref={phoneInputRef}
        className="phone-input__phone-number"
        name={name}
        value={formattedPhoneNumber}
        onChange={(e) =>
          onChangePhoneNumber(e.target.value.replace(/\D+/g, ''))
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />

      {required && (
        <div
          onClick={() => phoneInputRef.current?.focus()}
          className="phone-input__required"
        >
          <IconAsterisk />
        </div>
      )}
    </div>
  );
}
