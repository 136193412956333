import React, { useState } from 'react';
import { useIntl } from 'hooks';
import { MultiStepAuth, ConfirmModal, Btn } from 'components/shared';
import { AccountConversionPage } from '../components';
import signOut from 'helpers/auth/signOut';
import { useAppSelector, useAppDispatch } from 'redux/store';
import { getCurrentUser, logInUser } from 'redux/ducks/user';
import camelize from 'camelize';
import * as api from 'api/publisher';
import flashMessage from 'helpers/flashMessage';

interface Props {
  onSuccess: () => void;
}
export default function AccountConversionUser({ onSuccess }: Props) {
  const { user, legacyPublisher, publisher } = useAppSelector((state) => ({
    user: getCurrentUser(state),
    legacyPublisher: state.legacyPublisher!,
    publisher: state.publisher!,
  }));

  const dispatch = useAppDispatch();
  const [isClaiming, setIsClaiming] = useState(false);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);
  const { t } = useIntl();

  const claimLegacyPublisher = async () => {
    setIsClaiming(true);
    try {
      const data = camelize(await api.claimLegacyPublisher());
      dispatch(logInUser(data));
      setIsClaiming(false);
      onSuccess();
    } catch (e) {
      setIsClaiming(false);
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
    }
  };

  // Show this when they come back from logging in as a PossiblePublisher
  if (user && legacyPublisher && legacyPublisher.id !== publisher.id) {
    return (
      <AccountConversionPage
        heading={t('AccountConversion__RepeatHeading', {
          name: legacyPublisher?.name,
        })}
        subheading={t('AccountConversion__RepeatSubHeading')}
      >
        <Btn onClick={claimLegacyPublisher} disabled={isClaiming}>
          {t('Button__Continue')}
        </Btn>
      </AccountConversionPage>
    );
  }

  // Show this after a regular login
  return (
    <MultiStepAuth
      onSignIn={onSuccess}
      onSignUp={onSuccess}
      hideAlreadyHaveAccount
      renderIdentify={(form) => (
        <AccountConversionPage
          heading={t('AccountConversion__IdentifyHeading')}
        >
          <>
            <div className="mb-2 subheading">
              {t('AccountConversion__IdentifySubHeading')}
            </div>
            <div className="mb-2 subheading">
              {t('AccountConversion__IdentifySubSubHeading')}
            </div>
            {form}

            <div className="mt-2 text-14">
              <button
                className="text-light text-14 hover:underline"
                onClick={() => setIsSignOutModalOpen(true)}
              >
                {t('Button__SignOut')}
              </button>
            </div>

            <ConfirmModal
              isOpen={isSignOutModalOpen}
              onRequestClose={() => setIsSignOutModalOpen(false)}
              onConfirm={() => signOut()}
              heading="Button__SignOut"
            >
              {t('Global__ConfirmSignOut')}
            </ConfirmModal>
          </>
        </AccountConversionPage>
      )}
      renderSignUp={(form, onClickBack) => (
        <AccountConversionPage
          onClickBack={onClickBack}
          heading={t('AccountConversion__SignUpHeading')}
          subheading={t('AccountConversion__SignUpSubHeading')}
        >
          {form}
        </AccountConversionPage>
      )}
      renderLogIn={(form, isMfa, onClickBack) => (
        <AccountConversionPage
          onClickBack={onClickBack}
          heading={t('AccountConversion__LogInHeading')}
          subheading={
            isMfa ? undefined : t('AccountConversion__LogInSubHeading')
          }
        >
          {form}
        </AccountConversionPage>
      )}
    />
  );
}
