import byPlatformId from 'helpers/byPlatformId';
import assemblePost from 'helpers/assemblePost';
import { State as SocketState } from './socket';
import { OtherAction } from './global';
import { getDraftsByRequestKey } from './drafts';
import {
  TypeKeys as PRTypeKeys,
  FetchedAction as PRFetchedAction,
} from './publisherRequests';
import * as api from 'api/posts';
import Post from 'types/Post';
import PlatformId from 'types/PlatformId';

// Action Types
export enum TypeKeys {
  CREATE = 'publisher/posts/CREATE',
  CREATED = 'publisher/posts/CREATED',
  CREATE_FAILED = 'publisher/posts/CREATE_FAILED',
  REPOST = 'publish/posts/REPOST',
  UPDATE = 'publish/posts/UPDATE',
  UPDATED = 'publish/posts/UPDATED',
  UPDATE_FAILED = 'publisher/posts/UPDATE_FAILED',
  CANCEL = 'publisher/posts/CANCEL',
}

export interface CreateAction {
  type: TypeKeys.CREATE;
  payload: { post: Post; requestKey: string };
}

export interface CreatedAction {
  type: TypeKeys.CREATED;
  payload: { post: Post; requestKey: string };
}

export interface CreateFailedAction {
  type: TypeKeys.CREATE_FAILED;
  payload: { post: Post; requestKey: string; httpError: number };
}

export interface RepostAction {
  type: TypeKeys.REPOST;
  payload: { post: Post; requestKey: string };
}

export interface UpdateAction {
  type: TypeKeys.UPDATE;
  payload: { post: Post; requestKey: string };
}

export interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: { post: Post; requestKey: string };
}

export interface UpdateFailedAction {
  type: TypeKeys.UPDATE_FAILED;
  payload: { post: Post; requestKey: string };
}

export interface CancelAction {
  type: TypeKeys.CANCEL;
  payload: { requestKey: string; platformId: PlatformId };
}

type ActionTypes =
  | CreateAction
  | CreatedAction
  | CreateFailedAction
  | RepostAction
  | UpdateAction
  | UpdatedAction
  | CancelAction
  | PRFetchedAction
  | OtherAction;

// Reducer
// Keyed by requestKey and grouped by platformId, eg.
// { A0Z_6Yn5kCa9CDxn: { twitter: { ...}, facebook: {...} }
export interface State {
  [key: string]: { [key: string]: Post };
}

export default (state: State = {}, action: ActionTypes) => {
  switch (action.type) {
    case PRTypeKeys.FETCHED: {
      const { posts, requestKey } = action.payload;
      return {
        ...state,
        [requestKey]: byPlatformId(posts, 'platform'),
      };
    }

    case TypeKeys.CREATE:
    case TypeKeys.CREATED: {
      const { post, requestKey } = action.payload;
      return {
        ...state,
        [requestKey]: { ...state[requestKey], [post.platform]: post },
      };
    }

    case TypeKeys.UPDATE:
    case TypeKeys.UPDATED: {
      const { post, requestKey } = action.payload;
      const updatedPost = {
        ...state[requestKey][post.platform],
        ...post,
      };

      return {
        ...state,
        [requestKey]: { ...state[requestKey], [post.platform]: updatedPost },
      };
    }

    case TypeKeys.REPOST: {
      const { post, requestKey } = action.payload;
      const updatedPost = {
        ...state[requestKey][post.platform],
        status: 'posting',
        repostAttempted: true,
      };

      return {
        ...state,
        [requestKey]: { ...state[requestKey], [post.platform]: updatedPost },
      };
    }

    case TypeKeys.CREATE_FAILED: {
      const { post, requestKey, httpError } = action.payload;
      const updatedPost = {
        ...state[requestKey][post.platform],
        httpError,
        status: 'failed',
      };

      return {
        ...state,
        [requestKey]: { ...state[requestKey], [post.platform]: updatedPost },
      };
    }

    case TypeKeys.CANCEL: {
      const { requestKey, platformId } = action.payload;
      const updatedPost = {
        ...state[requestKey][platformId],
        status: 'canceled',
      };

      return {
        ...state,
        [requestKey]: { ...state[requestKey], [platformId]: updatedPost },
      };
    }

    default:
      return state;
  }
};

// Action Creators
const created = (post: Post, requestKey: string) => ({
  type: TypeKeys.CREATED,
  payload: { post, requestKey },
});

export const updatedPost = (post: Post, requestKey: string) => ({
  type: TypeKeys.UPDATED,
  payload: { post, requestKey },
});

const createFailed = (
  post: Post,
  requestKey: string,
  httpError: number = 0
) => ({
  type: TypeKeys.CREATE_FAILED,
  payload: { post, requestKey, httpError },
});

const updateFailed = (post: Post, requestKey: string) => ({
  type: TypeKeys.UPDATE_FAILED,
  payload: { post, requestKey },
});

export const create = (requestKey: string, platformId: PlatformId) => async (
  dispatch: Function,
  getState: () => { socket: SocketState }
) => {
  const state = getState();
  const socketId = state.socket.id;
  const post = assemblePost(state, requestKey, platformId);

  // if (!socketId) {
  //   dispatch(createFailed(post, requestKey));
  //   return;
  // }

  dispatch({ type: TypeKeys.CREATE, payload: { requestKey, post } });

  try {
    const json = await api.createPost({ ...post, socketId });
    dispatch(created(json, requestKey));
  } catch (error) {
    dispatch(createFailed(post, requestKey, error));
  }
};

export const repost = (requestKey: string, post: Post) => async (
  dispatch: Function,
  getState: () => any
) => {
  const state = getState();
  const socketId = state.socket.id;

  if (!socketId || !post.id) {
    dispatch(updateFailed(post, requestKey));
    return;
  }

  const drafts = getDraftsByRequestKey(state, requestKey);

  dispatch({
    type: TypeKeys.REPOST,
    payload: {
      requestKey,
      post: {
        ...post,
        text: drafts[post.platform].text,
        mentions: drafts[post.platform].mentions,
      },
    },
  });

  const json = await api.repost(post, socketId);
  dispatch(updatedPost({ ...json, status: 'posting' }, requestKey));
};

export const update = (requestKey: string, platformId: PlatformId) => async (
  dispatch: Function,
  getState: () => any
) => {
  const state = getState();
  const socketId = state.socket.id;
  const post = getPostsByRequestKey(state, requestKey)[platformId];
  const draft = getDraftsByRequestKey(state, requestKey)[platformId];

  if (!socketId || !post.id) {
    dispatch(updateFailed(post, requestKey));
    return;
  }

  dispatch({
    type: TypeKeys.UPDATE,
    payload: {
      requestKey,
      platformId,
      post: { ...post, text: draft.text, postAt: draft.postAt },
    },
  });

  try {
    const json = await api.updatePost(
      post.id,
      draft.postAt,
      draft.text,
      socketId
    );
    dispatch(updatedPost(json, requestKey));
  } catch (error) {
    dispatch(updateFailed(post, requestKey));
  }
};

export const cancel = (requestKey: string, platformId: PlatformId) => async (
  dispatch: Function,
  getState: () => any
) => {
  const post = getPostsByRequestKey(getState(), requestKey)[platformId];

  if (!post.id) {
    return;
  }

  dispatch({ type: TypeKeys.CANCEL, payload: { requestKey, platformId } });
  api.cancelPost(post.id);
};

// Selectors
export const getPostsByRequestKey = (
  state: { posts: State },
  requestKey: string
) => state.posts[requestKey] || {};
