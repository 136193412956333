import {
  signedS3Request,
  initiateMultipartUpload,
  SignedS3RequestInit,
  completeMultipartUpload,
} from 'api/attachments';
import { FilePart } from 'helpers/upload/FilePart';
import { isVideo } from 'helpers/fileTests';
import UploadedAttachment from 'types/UploadedAttachment';

export const s3FilePartSize = 1024 * 1024 * 6;
export const maxConcurrentUploads = 2;

export const STATUS = {
  PENDING: 'pending',
  UPLOADING: 'uploading',
  COMPLETE: 'upload complete',
  ERROR: 'error',
  CANCELED: 'canceled',
};

export interface UploaderCallbacks {
  onComplete: () => void;
  onProgress: (progress: number) => void;
  onError: (reason: string) => void;
}

export class S3MultipartUploader {
  /**
   * id used by the api server for the Attachment
   */
  mediaId: string | null = null;
  status: string | null = null;
  /**
   * id used by S3 for the multipart upload
   */
  uploadId: string | null = null;
  numParts: number | null = null;
  partsToUpload: FilePart[] = [];
  uploadsInProgress: FilePart[] = [];
  completedParts: FilePart[] = [];

  private uploaded = 0;
  get bytesUploaded(): number {
    return this.uploaded;
  }

  get totalBytes(): number {
    return this.file.size;
  }

  get attachmentType(): 'Photo' | 'Video' {
    return isVideo(this.file) ? 'Video' : 'Photo';
  }

  get contentType(): string {
    return this.file.type;
  }

  get canUploadPart(): boolean {
    const { uploadsInProgress, partsToUpload } = this;
    return (
      uploadsInProgress.length < maxConcurrentUploads &&
      partsToUpload.length > 0
    );
  }

  get filename(): string {
    return this.file.name;
  }

  constructor(public file: File, public callbacks: UploaderCallbacks) {
    this.status = STATUS.PENDING;
  }

  updateBytesLoaded(additionalBytes: number) {
    this.uploaded += additionalBytes;
    const progress = Math.round((100 * this.bytesUploaded) / this.totalBytes);
    this.callbacks.onProgress(progress);
  }

  async upload() {
    this.status = STATUS.UPLOADING;
    const options: SignedS3RequestInit = {
      requestType: 'initiate',
      contentType: this.file.type,
      queryParams: 'uploads',
    };

    try {
      const json = await signedS3Request(options);
      this.mediaId = json.media_id;
      await this.initiateUpload(json.url, json.headers);
    } catch (e) {
      this.callbacks.onError('error initiating upload');
    }
  }

  cancelUpload() {
    const { uploadsInProgress } = this;
    this.status = STATUS.CANCELED;
    uploadsInProgress.forEach(filePart => {
      filePart.cancelUpload();
    });
  }

  async initiateUpload(url: string, headers: any): Promise<any> {
    const xml = await initiateMultipartUpload(url, headers);
    this.uploadId = xml.getElementsByTagName('UploadId')[0]
      .textContent as string;

    this.makeParts();
    this.enqueueFileParts();
  }

  makeParts() {
    this.numParts = Math.ceil(this.file.size / s3FilePartSize) || 1;
    for (let partNumber = 1; partNumber <= this.numParts; partNumber += 1) {
      this.partsToUpload.push(new FilePart(this, partNumber, STATUS.PENDING));
    }
  }

  /**
   * Fills the in progress array w/up to maxConcurrentUploads number of FileParts
   */
  enqueueFileParts() {
    const { status, partsToUpload, uploadsInProgress } = this;
    if (status === STATUS.CANCELED) {
      return;
    }
    while (this.canUploadPart) {
      const filePart = partsToUpload.shift();
      if (filePart) {
        // console.log(`S3MultipartUploader: enqueueing FilePart ${filePart.partNumber}`);
        filePart
          .upload()
          .then(result => this.onFilePartUploaded(result, filePart))
          .catch(reason => this.onFilePartFailed(reason, filePart));
        uploadsInProgress.push(filePart);
      }
    }
  }

  onFilePartUploaded(result: any, filePart: FilePart) {
    const { uploadsInProgress, partsToUpload, completedParts } = this;
    const index = uploadsInProgress.indexOf(filePart);
    if (index >= 0) {
      // console.log(`S3MultipartUploader: uploaded FilePart ${filePart.partNumber}`);
      const removed = uploadsInProgress.splice(index, 1);
      completedParts.push(removed[0]);
    }

    if (partsToUpload.length > 0) {
      this.enqueueFileParts();
    } else if (uploadsInProgress.length === 0) {
      this.completeUpload();
    }
  }

  onFilePartFailed(reason: any, filePart: FilePart) {
    const { status, callbacks } = this;
    if (status === STATUS.CANCELED) {
      return;
    }
    callbacks.onError(`File part ${filePart.partNumber} failed: ${reason}`);
  }

  async completeUpload() {
    if (!this.mediaId) return;

    const options: SignedS3RequestInit = {
      requestType: 'complete',
      contentType: this.file.type,
      queryParams: `uploadId=${this.uploadId}`,
      mediaId: this.mediaId,
    };
    const json = await signedS3Request(options);
    const payload = this.completePayload();
    completeMultipartUpload(json.url, json.headers, payload)
      .then(response => {
        if (response.ok) {
          this.status = STATUS.COMPLETE;
          this.callbacks.onComplete();
        } else {
          this.onCompleteUploadError(response);
        }
      })
      .catch(response => this.onCompleteUploadError(response));
  }

  completePayload(): string {
    const { completedParts } = this;
    completedParts.sort((a, b) => a.partNumber - b.partNumber);
    let completeDoc = '<CompleteMultipartUpload>';
    completedParts.forEach(filePart => {
      completeDoc += `<Part><PartNumber>${filePart.partNumber}</PartNumber>`;
      completeDoc += `<ETag>${filePart.eTag}</ETag></Part>`;
    });
    completeDoc += '</CompleteMultipartUpload>';
    return completeDoc;
  }

  onCompleteUploadError(response: Response) {
    this.status = STATUS.ERROR;
    this.callbacks.onError(response.statusText);
  }

  attachmentData(): UploadedAttachment {
    const { mediaId, file, attachmentType } = this;
    return {
      type: attachmentType,
      media: `{"id": "${mediaId}"}`,
      mediaFilename: file.name,
      mediaSize: file.size,
      mediaContentType: file.type,
    };
  }
}
