import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { clsx } from 'clsx';
import hasClass from 'helpers/hasClass';
import useIsMobile from 'hooks/useIsMobile';
import { IconX } from 'icons';
import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { useIntl } from 'hooks';
import Btn, { BtnButtonProps } from '../Btn';
interface Props {
  isOpen: boolean;
  theme?: 'bottom';
  onRequestClose?: () => void;
  contentLabel: string;
  renderHeading?: React.ReactNode;
  children: any;
  contentsClassName?: string;
  hasCloseButton?: boolean;
  renderFooter?: React.ReactNode;
  onCancel?: BtnButtonProps['onClick'];
  onConfirm?: BtnButtonProps['onClick'];
  isConfirmLoading?: BtnButtonProps['isLoading'];
  confirmBtnProps?: BtnButtonProps;
  cancelBtnProps?: BtnButtonProps;
  cancelText?: BtnButtonProps['children'];
  confirmText?: BtnButtonProps['children'];
  renderCancelButton?: false | JSX.Element;
}

export default function Modal(props: Props) {
  const {
    theme,
    isOpen,
    contentLabel,
    onRequestClose,
    children,
    contentsClassName,
    ...innardsProps
  } = props;
  const className = theme ? `modal modal--${theme}` : 'modal';
  const overlayClassName = theme
    ? `modal-overlay modal-overlay--${theme}`
    : 'modal-overlay';

  return (
    <ReactModal
      appElement={document.body}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose}
      closeTimeoutMS={500}
      className={{
        base: className,
        afterOpen: 'modal--after-open',
        beforeClose: 'modal--before-close',
      }}
      overlayClassName={{
        base: overlayClassName,
        afterOpen: 'modal-overlay--after-open',
        beforeClose: 'modal-overlay--before-close',
      }}
    >
      <ModalInnards
        onRequestClose={onRequestClose}
        className={contentsClassName}
        theme={theme}
        {...innardsProps}
      >
        {children}
      </ModalInnards>
    </ReactModal>
  );
}

type ModalInnardsProps = Pick<
  Props,
  | 'theme'
  | 'renderHeading'
  | 'onRequestClose'
  | 'hasCloseButton'
  | 'renderFooter'
  | 'onCancel'
  | 'onConfirm'
  | 'isConfirmLoading'
  | 'confirmBtnProps'
  | 'cancelBtnProps'
  | 'cancelText'
  | 'confirmText'
> & {
  children: React.ReactNode;
  className?: string;
};

function ModalInnards(props: ModalInnardsProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { t } = useIntl();
  const { isMobile } = useIsMobile();
  const {
    onRequestClose,
    renderHeading,
    hasCloseButton = true,
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
    isConfirmLoading,
    confirmBtnProps,
    cancelBtnProps,
    renderFooter,
    children,
    className,
    theme,
  } = props;
  const isBottom = theme === 'bottom';

  useEffect(() => {
    const currentEl = contentRef.current;
    if (currentEl)
      disableBodyScroll(currentEl, {
        allowTouchMove: (initialEl) =>
          hasClass(initialEl, 'body-scroll-lock-ignore'),
      });

    return () => {
      if (currentEl) enableBodyScroll(currentEl);
    };
  }, [isMobile]);

  const hasFooter = renderFooter || onConfirm || onCancel;

  return (
    <div
      className="modal__closer"
      ref={isMobile && isBottom ? undefined : contentRef}
      onClick={onRequestClose}
    >
      <div className="modal__scroller">
        <div
          className={clsx('modal__contents', className)}
          ref={isMobile && isBottom ? contentRef : undefined}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={clsx('flex items-start w-full text-left', {
              'sticky z-10 pt-2 top-0 left-0 pb-0.5 phablet:static phablet:pt-0 phablet:pb-0':
                theme === 'bottom',
              'bg-white': renderHeading,
            })}
          >
            {renderHeading && (
              <div className="h4 w-full text-left pt-3">
                <span className="bump-up-1">{renderHeading}</span>
              </div>
            )}

            {hasCloseButton && onRequestClose && (
              <div className="ml-auto">
                <button
                  className="w-3 h-3 text-dark bg-white rounded-full hover:text-bodyText"
                  onClick={onRequestClose}
                >
                  <IconX className="block w-full h-full" />
                </button>
              </div>
            )}
          </div>

          <div
            className={clsx({
              'pt-2.5': !!renderHeading,
              'text-left': theme === 'bottom',
              'pb-2.5 phablet:pb-0': theme === 'bottom' && !hasFooter,
              'pb-12': hasFooter,
            })}
          >
            {children}
          </div>

          {hasFooter && (
            <footer
              className={clsx(
                'bottom-0 left-0 right-0 z-50 flex items-start justify-center gap-x-1 px-2 pt-2 border-t border-solid border-grey8 bg-white',
                theme === 'bottom'
                  ? 'fixed pb-[max(env(safe-area-inset-bottom),_16px)] phablet:absolute phablet:rounded-b-lg phablet:pb-2'
                  : 'absolute rounded-b-lg pb-2'
              )}
            >
              {renderFooter ? (
                renderFooter
              ) : (
                <>
                  {onCancel && (
                    <div>
                      <Btn
                        color="grey"
                        variant="inlineBlock"
                        onClick={onCancel}
                        className="min-w-17.5"
                        {...cancelBtnProps}
                      >
                        {cancelText || t('Global__Cancel')}
                      </Btn>
                    </div>
                  )}
                  {onConfirm && (
                    <div className={clsx(onCancel && 'ml-1')}>
                      <Btn
                        variant="inlineBlock"
                        onClick={onConfirm}
                        className="min-w-17.5"
                        isLoading={isConfirmLoading}
                        {...confirmBtnProps}
                      >
                        {confirmText || t('Global__Continue')}
                      </Btn>
                    </div>
                  )}
                </>
              )}
            </footer>
          )}
        </div>
      </div>
    </div>
  );
}
