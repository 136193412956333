import { OtherAction } from './global';
import { TypeKeys } from './publisherRequests';
import camelize from 'camelize';
import * as api from 'api/accounts';
import Account from 'types/Account';
import Suggesters from 'types/Suggesters';

export enum OptionsType {
  ACCOUNT = 'accountOptions',
  FEED = 'feedOptions',
}

export interface FetchedAction {
  type: TypeKeys.ACCOUNTS_FETCHED;
  payload: {
    accounts: Account[];
    suggesters: Suggesters;
  };
}

type ActionTypes = FetchedAction | OtherAction;

export interface State {
  accounts?: Account[];
  suggesters?: Suggesters;
}

export default (state: State = {}, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.ACCOUNTS_FETCHED:
      const { accounts, suggesters } = action.payload;
      return { ...state, accounts, suggesters };

    default:
      return state;
  }
};

export const fetchAccounts = () => async (dispatch: Function) => {
  const json: any = await api.getAccounts();
  return dispatch({
    type: TypeKeys.ACCOUNTS_FETCHED,
    payload: camelize(json),
    source: 'xhr',
  });
};
