// When favoriting, we pass the attachment that we want to fav but also the
// request or album that it belongs to. This is so that the API can easily

import Album from 'types/Album';
import Attachment from 'types/Attachment';
import PublisherRequest from 'types/PublisherRequest';
import { post } from './shared';

const END_POINT = 'publisher/favorite-attachments';

// determine if the user has access to the attachment.
export function addFavoriteAttachment(
  attachmentId: Attachment['id'],
  {
    requestKey,
    albumId,
  }: {
    requestKey?: PublisherRequest['requestKey'];
    albumId?: Album['id'];
  }
) {
  return post(END_POINT, { attachmentId, requestKey, albumId });
}

export function removeFavoriteAttachment(attachmentId: Attachment['id']) {
  const endpoint = `${END_POINT}/${attachmentId}`;
  return post(endpoint, {}, 'DELETE');
}
