import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkContactValue } from 'api/user';
import camelize from 'camelize';
import Form from 'components/shared/Form';
import isValidContactValue from 'helpers/isValidContactValue';
import { useIntl } from 'hooks';
import React, { useReducer } from 'react';
import Btn from 'components/shared/Btn';

interface Props {
  contactValue: string;
  setContactValue: (contactValue: string) => void;
  goToSignUp: () => void;
  goToLogIn: () => void;
  hideAlreadyHaveAccount?: boolean;
}

const initialState = {
  isValid: true,
  isChecking: false,
  isError: false,
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'IdentifyReducer',
  reducers: {
    checkValueStart: (state) => {
      state.isError = false;
      state.isValid = true;
      state.isChecking = true;
    },
    checkValueSuccess: (state, action: PayloadAction<boolean>) => {
      state.isChecking = false;
      state.isValid = action.payload;
    },
    checkValueFailure: (state) => {
      state.isChecking = false;
      state.isError = true;
    },
  },
});

export default function MultiStepAuthIdentify(props: Props) {
  const {
    contactValue,
    setContactValue,
    goToSignUp,
    goToLogIn,
    hideAlreadyHaveAccount,
  } = props;
  const { t } = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isValid, isChecking, isError } = state;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(actions.checkValueStart());

    const sanitizedValue = contactValue.trim();

    if (!isValidContactValue(sanitizedValue)) {
      dispatch(actions.checkValueSuccess(false));
      return;
    }

    try {
      const json = camelize(await checkContactValue(sanitizedValue));
      dispatch(actions.checkValueSuccess(json.valid));

      if (!json.valid) return;

      if (json.exists) {
        goToLogIn();
      } else {
        goToSignUp();
      }
    } catch (e) {
      console.error(e);
      dispatch(actions.checkValueFailure());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-1.5">
        <Form.TextInput
          value={contactValue}
          onChange={(e) => setContactValue(e.target.value)}
          placeholder={t('Onboarding__IdentifyPlaceholder')}
          isInvalid={!isValid}
          skipField
        />

        {(!isValid || isError) && (
          <div className="mb-1.5 text-error text-14">
            {t(
              isError
                ? 'Onboarding__IdentifyError'
                : 'Global__InvalidContactValue'
            )}
          </div>
        )}
      </div>

      <Btn
        className="mb-2"
        type="submit"
        isLoading={isChecking}
        disabled={!contactValue || isChecking}
      >
        {t('Button__Continue')}
      </Btn>

      {!hideAlreadyHaveAccount && (
        <div className="text-14 text-center">
          <span className="text-light">{t('Global__AlreadyHaveAccount')} </span>
          <button
            className="text-socialiePink hover:underline"
            onClick={goToLogIn}
          >
            {t('Button__SignIn')}
          </button>
        </div>
      )}
    </form>
  );
}
