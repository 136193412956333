import prependWebUrl from './prependWebUrl';

export default function changeSubdomain(subdomain: string, path = '') {
  const isDevEnv = process.env.NODE_ENV === 'development';
  const protocol = isDevEnv ? 'http://' : 'https://';
  const port = isDevEnv ? '3000' : '';
  const domain = import.meta.env.REACT_APP_DOMAIN;
  const url = prependWebUrl(`${protocol}${subdomain}.${domain}:${port}${path}`);
  window.location.href = url;
}
