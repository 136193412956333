import React from 'react';

interface Props {
  label?: React.ReactNode;
  children: React.ReactNode;
}

export default function FilterSection(props: Props) {
  const { label, children } = props;

  return (
    <div className="mb-2 pb-2 border-b-light text-left last:mb-0 last:border-b-0 last:pb-0">
      {label && <div className="text-dark font-semibold mb-1">{label}</div>}
      {children}
    </div>
  );
}

FilterSection.ToggleRow = function FilterSectionToggleRow({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="w-full overflow-auto invisible-scrollbar">
      <div className="flex items-center tablet:flex-wrap">{children}</div>
    </div>
  );
};
