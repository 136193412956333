export default function trackCustomEvent(name: string, properties?: any) {
  const smartlook = window['smartlook'];
  const FS = window['FS'];
  if (smartlook) {
    smartlook('track', name, properties);
  } else if (FS && FS.event) {
    FS.event(name, properties);
  } else {
    console.log('[trackCustomEvent debug] would track', name, properties);
  }
}
