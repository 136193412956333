import * as api from 'api/passwords';
import { updatePassword } from 'api/passwords';
import camelize from 'camelize';
import { clsx } from 'clsx';
import flashMessage from 'helpers/flashMessage';
import routes from 'helpers/routes';
import { useBodyClassName, useIntl } from 'hooks';
import socialieLogo from 'icons/socialie.svg';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { RootState } from 'redux/ducks';
import { getCurrentBrand } from 'redux/ducks/brand';
import { logInUser } from 'redux/ducks/user';
import {
  Form,
  LoadingPage,
  LocaleSwitcher,
  ScrollToTopOnMount,
  UnknownError,
  UtilBar,
  Btn,
} from '../shared';
import {
  SignInFormRecoveryCode,
  SignInFormValidateOtp,
} from '../shared/SignInForm/components';
import { InvalidResetPasswordToken } from './components';

const MIN_PASSWORD_LENGTH = 8;

type Props = RouteComponentProps<{ token: string }>;

export default function ResetPassword(props: Props) {
  const { t } = useIntl();
  const { match } = props;
  const { token } = match.params;

  useBodyClassName('bg-socialie-watermark-grey');

  const { brand, user, suggester } = useSelector((state: RootState) => ({
    brand: getCurrentBrand(state),
    user: state.user.current,
    suggester: state.suggester,
  }));

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const [mfaValues, setMfaValues] = useState({
    tempToken: '',
    mfaProtocol: '',
    mfaPhone: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [isUsingRecoveryCode, setIsUsingRecoveryCode] = useState(false);
  const [isValidToken, setIsValidToken] = useState(true);

  useEffect(() => {
    async function fetchResetPasswordStatus() {
      setIsLoading(true);
      try {
        const data = camelize(await api.isResetPasswordTokenValid(token));
        setIsLoading(false);
        setIsValidToken(data);
      } catch (e) {
        console.warn(e);
        setIsLoading(false);
        flashMessage('Global__UnexpectedError', { isError: true });
      }
    }
    fetchResetPasswordStatus();
  }, [token]);

  if (!brand) {
    return (
      <UnknownError
        headingId="UnknownError__404Heading"
        subHeadingId="UnknownError__404SubHeading"
      />
    );
  }

  if (user) {
    return <Redirect to={routes.home} />;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (values.password.length < MIN_PASSWORD_LENGTH) {
      setValidationMsg(t('ResetPassword__Validation--tooShort'));
      return;
    }

    if (values.password !== values.passwordConfirmation) {
      setValidationMsg(t('ResetPassword__Validation--notMatched'));
      return;
    }

    setValidationMsg('');
    setIsLoading(true);
    const { password } = values;

    try {
      const data = camelize(await updatePassword(password, token));
      setIsLoading(false);

      if ('user' in data) {
        dispatch(logInUser(data));
      } else if ('tempToken' in data) {
        setMfaValues(data);
        setIsLoading(false);
      } else {
        setValidationMsg(t('ResetPassword__Validation--serverError'));
      }
    } catch (e) {
      console.error(e);
      flashMessage('Global__UnexpectedError', { isError: true });
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const mfaForm = isUsingRecoveryCode ? (
    <SignInFormRecoveryCode
      {...mfaValues}
      onSetIsUsingRecoveryCode={(isUsing: boolean) =>
        setIsUsingRecoveryCode(isUsing)
      }
    />
  ) : (
    <SignInFormValidateOtp
      {...mfaValues}
      onSetIsUsingRecoveryCode={(isUsing: boolean) =>
        setIsUsingRecoveryCode(isUsing)
      }
    />
  );

  if (isLoading) return <LoadingPage />;

  return (
    <div className="p-2 pt-9.5 text-center laptop:flex-v-center laptop:min-h-full laptop:pb-9.5">
      <ScrollToTopOnMount />
      <UtilBar>
        <LocaleSwitcher />
      </UtilBar>

      <div className="laptop:w-48 mx-auto">
        <img
          src={suggester ? brand.logoUrl : socialieLogo}
          className={clsx('rounded-logo mx-auto w-7.5 h-7.5 mb-3.5', {
            'p-2': !suggester,
          })}
          alt={suggester?.accountName || 'Socialie Logo'}
        />
        {!isValidToken ? (
          <InvalidResetPasswordToken />
        ) : (
          <div>
            {mfaValues.mfaProtocol ? (
              <div className="mb-4">
                <h3 className="h3">{t('FeedLogin__Heading')}</h3>
                {mfaForm}
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <h3 className="h3">{t('ResetPassword__Heading')}</h3>

                  <div className="subheading">
                    <FormattedHTMLMessage id="ResetPassword__SubHeading" />
                  </div>
                </div>

                {!validationMsg && (
                  <div className="mb-1.5 text-error text-14 leading-tight">
                    {validationMsg}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  {['password', 'passwordConfirmation'].map((field) => (
                    <div className="mb-1.5" key={field}>
                      <Form.TextInput
                        name={field}
                        onChange={handleChange}
                        value={values[field]}
                        type="password"
                        placeholder={t(`ResetPassword__Placeholder--${field}`)}
                        skipField
                      />
                    </div>
                  ))}

                  <Btn
                    className="mb-2"
                    type="submit"
                    isLoading={isLoading}
                    disabled={
                      isLoading ||
                      !values.password ||
                      !values.passwordConfirmation
                    }
                  >
                    {t('Button__Continue')}
                  </Btn>

                  <div className="text-14 text-center">
                    <span className="text-light">
                      {t('Global__AlreadyHaveAccount')}{' '}
                    </span>
                    <Link
                      className="text-socialiePink hover:underline"
                      to={routes.auth.login.root}
                    >
                      {t('Button__SignIn')}
                    </Link>
                  </div>
                </form>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
