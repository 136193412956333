import * as fbApi from 'api/facebook';
import * as api from 'api/identities';
import * as igApi from 'api/instagram';
import camelize from 'camelize';
import { flashMessage } from 'redux-flash';
import byPlatformId from 'helpers/byPlatformId';
import { updated } from './publisher';
import Identity from 'types/Identity';
import FacebookApiPage from 'types/FacebookApiPage';
import FacebookPage from 'types/FacebookPage';
import FacebookPageResult from 'types/FacebookPageResult';
import { TypeKeys as PageTypeKeys } from './facebookPages';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import PlatformId from 'types/PlatformId';
import { AppDispatch } from 'redux/store';
import { RootState } from '.';

// Thunks
export const fetchFacebookPages = () => async (dispatch: Function) => {
  const dispatcher = (json: FacebookPageResult) => ({
    type: PageTypeKeys.PAGES_FETCHED,
    payload: json,
  });
  const json: FacebookPageResult = camelize(await fbApi.getPages());
  return dispatch(dispatcher(json));
};

export const deleteIdentity = (platform: PlatformId) => async (
  dispatch: Function
) => {
  try {
    const json = camelize(await api.unlinkIdentity(platform));

    if (!json.success) {
      return dispatch(
        flashMessage('Global__UnexpectedError', { isError: true })
      );
    }

    const platformKey = platform.startsWith('instagram')
      ? 'instagram'
      : platform;

    return dispatch(actions.deletedIdentity(platformKey));
  } catch (e) {
    console.error(e);
    return dispatch(flashMessage('Global__UnexpectedError', { isError: true }));
  }
};

export const createFacebookPage = (page: FacebookApiPage) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const facebookPage = await fbApi.createPage(page);
  const publisher = getState().publisher;
  const updates = { name: facebookPage.name, image: facebookPage.image };
  dispatch(updated({ ...publisher, ...updates }));
  return dispatch(actions.createdFacebookPage({ facebookPage }));
};

export const deleteFacebookPage = () => async (dispatch: Function) => {
  return dispatch(dispatch(actions.deletedFacebookPage()));
};

export const selectInstagramAccount = createAsyncThunk(
  'publisher/identities/selectInstagramAccount',
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await igApi.selectAccount(id);
      if ('errors' in data) throw new Error(data.errors);
      return data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e);
    }
  }
);

// Reducer
const { reducer, actions } = createSlice({
  initialState: {} as { [key: string]: Identity },
  name: 'publisher/identities',
  reducers: {
    fetchedIdentities: (state, action: PayloadAction<Identity[]>) => {
      return byPlatformId(
        action.payload || [],
        'platform',
        (inc) => inc.grantedModerationPermission
      );
    },
    deletedIdentity: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    createdFacebookPage: (
      state,
      action: PayloadAction<{ facebookPage: FacebookPage }>
    ) => {
      if (!state.facebook) return;
      state.facebook.incomplete = false;
      state.facebook.facebookPage = action.payload.facebookPage;
    },
    deletedFacebookPage: (state) => {
      if (!state.facebook) return;
      state.facebook.incomplete = true;
      state.facebook.facebookPage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectInstagramAccount.fulfilled, (state, action) => {
      state.instagram = action.payload;
    });
  },
});

export default reducer;

export const { fetchedIdentities } = actions;
