import React from 'react';
import { IconEmail, IconSms, IconWhatsApp, IconTwitter } from 'icons';
import NotificationPreferences from 'types/NotificationPreferences';

interface Props extends React.ComponentProps<'svg'> {
  type: keyof NotificationPreferences;
}

const icons = {
  email: IconEmail,
  sms: IconSms,
  whats_app: IconWhatsApp,
  twitter_dm: IconTwitter,
};

export default function OnboardingNotificationIcon({ type, ...props }: Props) {
  const Icon = icons[type];
  if (!Icon) {
    throw new Error(
      `[OnboardingNotificationIcon] No icon specified for ${type}`
    );
  }

  return <Icon {...props} />;
}
