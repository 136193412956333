import PartialPublisherRequest from 'types/PartialPublisherRequest';
import isUnread from './isUnread';

export default (
  publisherRequest: PartialPublisherRequest,
  additionalState?: any
) => {
  const { status, requestKey } = publisherRequest;
  let pathname = `/${requestKey}`;
  let search = '';

  if (isUnread(status)) {
    search += '?opened=1';
  }

  return { pathname, search, state: { fromInbox: true, ...additionalState } };
};
