import { resetPassword } from 'api/passwords';
import Modal from 'components/shared/modals/Modal';
import flashMessage from 'helpers/flashMessage';
import isValidContactValue from 'helpers/isValidContactValue';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import Form from '../Form';
import Btn from '../Btn';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function ForgotPasswordModal(props: Props) {
  const { isOpen, onRequestClose } = props;
  const { t } = useIntl();
  const [value, setValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidContactValue(value)) {
      setIsInvalid(true);
      return;
    }

    setIsInvalid(false);
    setIsLoading(true);

    try {
      await resetPassword(value);
      flashMessage('ForgotPassword__Success', { timeout: false });
      setIsLoading(false);
      onRequestClose();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      flashMessage('Global__UnexpectedError', { isError: true });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t('ForgotPassword__Heading')}
      theme="bottom"
      contentsClassName="max-w-54"
      renderHeading={t('ForgotPassword__Heading')}
    >
      <div className="text-16 mb-2.5">{t('ForgotPassword__SubHeading')}</div>

      <form onSubmit={handleSubmit} className="mb-3">
        <div className="mb-1.5 text-left">
          <Form.TextInput
            skipField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={t('Onboarding__LogInPlaceholder--contactValue')}
            autoFocus
            autoComplete="forgot-password-contact-value"
          />

          {isInvalid && (
            <div className="mb-1.5 text-error text-14">
              {t('Global__InvalidContactValue')}
            </div>
          )}
        </div>

        <Btn type="submit" isLoading={isLoading} disabled={!value || isLoading}>
          {t('Button__Submit')}
        </Btn>
      </form>
    </Modal>
  );
}
